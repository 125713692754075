import configLevels from './configLevels';

export default ({level, type}) => {
  for (let el of configLevels) {
    if (
      type === el.type &&
      (level >= el.min || level <= el.min) &&
      level <= el.max
    ) {
      return el;
    }
  }
};

export default (that, index) => {
  let menu = that.state.sidebar_menus[index];

  menu.show = !menu.show;

  that.state.sidebar_menus[index] = menu;

  that.setState({
    sidebar_menus: that.state.sidebar_menus,
  });
};

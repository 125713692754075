import React from "react";
import "../styles.css";
import { Input } from "../../Input";
import moment from "moment";
import { Button } from "../../../../app/components/Button";
import toastMessage from "../../../../app/utils/toastMessage";

class DateFilter extends React.Component {
  state = {
    fromDate: moment(),
    toDate: moment(),
    error: {},
  };

  componentDidMount() {
    this.initiateDate();
  }

  initiateDate() {
    const { fromDate, toDate } = this.props;

    if (fromDate && toDate) {
      return this.setState({ fromDate, toDate });
    }

    this.setState({
      fromDate: moment()
        .subtract(7, "d")
        .format(this.props.format || ""),
      toDate: moment().format(this.props.format || ""),
    });
  }

  onChangeText(name, e) {
    let { error } = this.state;

    delete error[name];

    this.setState({
      error,
      [name]: e.target.value,
    });
  }

  validateForm() {
    let { error, start_date, end_date } = this.state;

    if (start_date === "") {
      error.start_date = "Start date is required";
    }

    if (end_date === "") {
      error.end_date = "End date is required";
    }

    this.setState({
      error,
    });
  }

  handleApply(e) {
    e.preventDefault();
    const { fromDate, toDate } = this.state;
    if (fromDate && toDate && this.props.getDateRange) {
      this.props.getDateRange({
        fromDate,
        toDate,
      });
    } else {
      toastMessage("error", "Wrong dates, please try again");
    }
  }

  handleResetDate() {
    this.initiateDate();
  }

  render() {
    return (
      <form>
        <div className="card cdropdown-container">
          <div className="card-body">
            <Input
              label="Start Date:"
              required
              type={this.props.datetime ? "datetime-local" : "date"}
              value={this.state.fromDate}
              error={this.state.error.fromDate}
              onChange={(e) => this.onChangeText("fromDate", e)}
            />
            {this.props.isOneDayAllowed ? (
              <Input
                label="End Date:"
                required
                type={"time"}
                value={this.state.toDate}
                error={this.state.error.toDate}
                onChange={(e) => this.onChangeText("toDate", e)}
              />
            ) : (
              <Input
                label="End Date:"
                required
                type={this.props.datetime ? "datetime-local" : "date"}
                value={this.state.toDate}
                error={this.state.error.toDate}
                onChange={(e) => this.onChangeText("toDate", e)}
              />
            )}
            <div className="d-flex gap-3">
              <Button
                className="btn-default w-100 btn-lg"
                withOpacity
                text="Reset"
                onPress={this.handleResetDate.bind(this)}
              />
              <Button
                className="btn-primary w-100 btn-lg"
                withOpacity
                text="Apply"
                onPress={this.handleApply.bind(this)}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default DateFilter;

import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CardCount } from "../../../app/components/CardCount";
import { ENDPOINT } from "../../../app/constants/api";
import { getStorage } from "../../../app/utils/storage";
import toastMessage from "../../../app/utils/toastMessage";
import { LoadingSpinner } from "../LoadingSpinner";
import { Doughnut, Line } from "react-chartjs-2";
import { colors } from "../../../app/constants/strings";
import { numberWithCommas } from "../../../app/utils/formatCurrency";
import filtersData from "../../../app/utils/filtersData";

const options = {
  legend: {
    position: "bottom",
    display: false,
  },
  maintainAspectRatio: false,
  responsive: true,
};

class Analytics extends React.Component {
  state = {
    user: {},
    total_active_Parks: 0,
    total_collections: 0,
    total_number_vehicles: 0,
    route: "",
    total_park_collected: 0,
    chart: {
      park_collections: [],
      park_tickets: [],
      park_revenues: [],
      monthly_rent: [],
      number_collections: [],
      average: [],
      weekly_trends: [],
      daily_park_level: [],
      park_id_type: [],
      rent_revenues: [],
      service_fee_revenues: [],
      monthly_service_fee_revenues: [],
      weekly_parking_trends: [],
      daily_parking_trends: [],
      daily_parking_trends: [],
    },
    chart_collections: [],
    chart_number_collections: [],
    total_vehicles: 0,
    total_tickets: 0,
    total_devices: 0,
  };

  componentDidMount = async () => {
    const user = await getStorage();

    await this.setState({ user });

    this.getData(true);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  };

  getData = async (isLoading) => {
    this.getVehicles(true);
    this.getDevices(true);
    this.getTickets(true);
    this.getParkingRevenues(isLoading);
    this.getServiceFeeRevenues(isLoading);
    this.getMonthlyServiceFeeRevenues(isLoading);
    this.getRentRevenues(isLoading);
    this.getMonthlyRentRevenues(isLoading);
    this.getWeeklyParkingRevenues(isLoading);
  };

  getVehicles(isLoadingVehicle) {
    const { user } = this.state;

    const { filters } = this.props;

    let params = {
      status: "vehicle-active",
      filters,
      parkFilterType: "reference_park_id",
    };

    let request_body = filtersData(params);

    request_body.tag = "count";

    this.setState({
      isLoadingVehicle,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_user_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoadingVehicle: false,
          total_vehicles: data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingVehicle: false });
      });
  }

  getTickets(isLoadingTickets) {
    const { user } = this.state;
    const { filters } = this.props;

    let params = {
      filters,
      parkFilterType: "park_id",
      vehicleFilterType: "ticket_user_ids",
    };

    let request_body = filtersData(params);

    request_body.tag = "count";

    this.setState({
      isLoadingTickets,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoadingTickets: false,
          total_tickets: data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingTickets: false });
      });
  }

  getDevices(isLoadingDevice) {
    const { user } = this.state;
    const { filters } = this.props;

    let request_body = {
      filters,
      parkFilterType: "park_id",
    };

    request_body.tag = "count";

    this.setState({
      isLoadingDevice,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_device_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoadingDevice: false,
          total_devices: data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingDevice: false });
      });
  }

  handlePressCount(route) {
    this.setState({ route });
  }

  //NEW
  getParkingRevenues(isLoadingParking) {
    const { user } = this.state;
    const { filters } = this.props;

    let params = {
      filters,
      parkFilterType: "park_id",
      vehicleFilterType: "ticket_user_ids",
    };

    let request_body = filtersData(params);

    request_body.tag = "user";

    this.setState({
      isLoadingParking,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options)
      .then((res) => {
        console.log({ parking: res.data });

        this.setState({
          isLoadingParking: false,
        });

        this.formatChartPerUser(res.data, "park_revenues");
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingParking: false });
      });
  }

  getWeeklyParkingRevenues(isLoadingWeeklyParking) {
    const { user } = this.state;
    const { filters } = this.props;

    let params = {
      filters,
      parkFilterType: "park_id",
      vehicleFilterType: "ticket_user_ids",
    };

    let request_body = filtersData(params);

    request_body.tag = "trend";
    request_body.order = "daily";

    this.setState({
      isLoadingWeeklyParking,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options)
      .then((res) => {
        this.setState({
          isLoadingWeeklyParking: false,
        });

        this.formatChartPerDate(res.data, "daily_parking_trends");
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingWeeklyParking: false });
      });
  }

  getServiceFeeRevenues(isLoadingService) {
    const { user } = this.state;

    this.setState({
      isLoadingService,
    });

    const { filters } = this.props;

    let copyFilters = { ...filters };

    delete copyFilters.start_date;
    delete copyFilters.end_date;

    let params = {
      category: "servicefee",
      filters: copyFilters,
      parkFilterType: "reference_park_id",
    };

    let request_body = filtersData(params);

    request_body.tag = "user";

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_service_payment_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options)
      .then((res) => {
        this.setState({
          isLoadingService: false,
        });

        this.formatChartPerUser(res.data, "service_fee_revenues");
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingService: false });
      });
  }

  getRentRevenues(isLoadingRent) {
    const { user } = this.state;
    const { filters } = this.props;

    let copyFilters = { ...filters };

    delete copyFilters.start_date;
    delete copyFilters.end_date;

    let params = {
      category: "rent",
      filters: copyFilters,
      parkFilterType: "reference_park_id",
    };

    let request_body = filtersData(params);

    request_body.tag = "user";

    this.setState({
      isLoadingRent,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_service_payment_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options)
      .then((res) => {
        this.setState({
          isLoadingRent: false,
        });

        this.formatChartPerUser(res.data, "rent_revenues");
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingRent: false });
      });
  }

  getMonthlyRentRevenues(isLoadingMonthlyRent) {
    const { user } = this.state;
    const { filters } = this.props;

    let copyFilters = { ...filters };

    delete copyFilters.month;
    delete copyFilters.year;

    let params = {
      category: "rent",
      filters: copyFilters,
      parkFilterType: "reference_park_id",
    };

    let request_body = filtersData(params);

    request_body.tag = "trend";
    request_body.order = "monthly";

    this.setState({
      isLoadingMonthlyRent,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_service_payment_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options)
      .then((res) => {
        this.setState({
          isLoadingMonthlyRent: false,
        });

        this.formatChartPerDate(res.data, "monthly_rent");
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingMonthlyRent: false });
      });
  }

  getMonthlyServiceFeeRevenues(isLoadingMonthlyService) {
    const { user } = this.state;

    this.setState({
      isLoadingMonthlyService,
    });

    const { filters } = this.props;

    let copyFilters = { ...filters };

    delete copyFilters.start_date;
    delete copyFilters.end_date;

    let params = {
      category: "servicefee",
      filters: copyFilters,
      parkFilterType: "reference_park_id",
    };

    let request_body = filtersData(params);

    request_body.tag = "trend";
    request_body.order = "monthly";

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_service_payment_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options)
      .then((res) => {
        this.setState({
          isLoadingMonthlyService: false,
        });

        this.formatChartPerDate(res.data, "monthly_service_fee_revenues");
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingMonthlyService: false });
      });
  }

  formatChartPerUser(data, key_field) {
    let group_by_data = {};

    for (let el of data) {
      const email = el?.account?.email;

      if (!group_by_data[email]) {
        group_by_data[email] = {};
      }

      group_by_data[email] = el;
    }

    const labels = Object.keys(group_by_data);
    let chart_data = [];

    for (let el of Object.keys(group_by_data)) {
      chart_data.push(group_by_data[el].total_revenues);
    }

    let customLabels = labels.map(
      (label, index) =>
        `${label}: RWF ${numberWithCommas(
          Math.round(group_by_data[label].total_revenues)
        )} `
    );

    this.state.chart[key_field] = {
      labels: customLabels,
      datasets: [
        {
          data: chart_data,
          backgroundColor: colors,
        },
      ],
    };

    this.setState({
      chart: this.state.chart,
    });
  }

  formatChartPerDate(data, key_field) {
    let group_by_data = {};

    for (let el of data) {
      const id = el?._id;

      if (!group_by_data[id]) {
        group_by_data[id] = {};
      }

      group_by_data[id] = el;
    }

    const labels = Object.keys(group_by_data);
    let chart_data = [];

    for (let el of Object.keys(group_by_data)) {
      chart_data.push(group_by_data[el].total_revenues);
    }

    this.state.chart[key_field] = {
      labels,
      datasets: [
        {
          label: `${
            key_field.includes("weekly")
              ? "Weekly"
              : key_field.includes("daily")
              ? "Daily"
              : "Monthly"
          } - RWF`,
          data: chart_data,
          backgroundColor: "rgba(5, 198, 5,0.2)",
          borderColor: "#05c605",
          barPercentage: 0.5,
        },
      ],
    };

    this.setState({
      chart: this.state.chart,
    });
  }

  //END

  returnChartList(data) {
    return (
      <ul className="chart-list">
        {data &&
          data.map((item, i) => {
            let title = item.split(":");
            return (
              <li key={i}>
                <span className="title">{title[0]}</span>
                <span className="number">{title[1]}</span>
              </li>
            );
          })}
      </ul>
    );
  }

  render() {
    if (this.state.route !== "") {
      return <Redirect to={this.state.route} />;
    }

    return (
      <div>
        <div className="row">
          <div className="col-6 col-md-3">
            <CardCount
              title="Total Number of Issued Tickets"
              total={
                this.state.isLoadingTickets ? "..." : this.state.total_tickets
              }
            />
          </div>
          <div className="col-6 col-md-3">
            <CardCount
              title="Total Number of Vehicles | Devices"
              total_object={[
                {
                  total: this.state.isLoadingDevice
                    ? "..."
                    : this.state.total_vehicles,
                  title: "Vehicles",
                },
                {
                  total: this.state.isLoadingDevice
                    ? "..."
                    : this.state.total_devices,
                  title: "Devices",
                },
              ]}
            />
          </div>
          <div className="col-6 col-md-3">
            <CardCount
              title="Average Tickets Per Device"
              total={
                this.state.isLoadingTickets
                  ? "..."
                  : this.state.total_devices !== 0
                  ? `${Math.round(
                      this.state.total_tickets / this.state.total_devices
                    )}`
                  : "0"
              }
            />
          </div>
          <div className="col-6 col-md-3">
            <CardCount
              title="Average Tickets Per Vehicle"
              total={
                this.state.isLoadingTickets
                  ? "..."
                  : this.state.total_vehicles !== 0
                  ? `${Math.round(
                      this.state.total_tickets / this.state.total_vehicles
                    )}`
                  : "0"
              }
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-md-4">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Parking - Revenues per User</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingParking ? (
                  <LoadingSpinner />
                ) : (
                  <div>
                    <div style={{ height: 180 }}>
                      <Doughnut
                        data={this.state.chart.park_revenues}
                        options={options}
                      />
                    </div>
                    {this.returnChartList(
                      this.state.chart?.park_revenues?.labels
                        ? this.state.chart?.park_revenues?.labels
                        : []
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Rent - Revenues per User </h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingRent ? (
                  <LoadingSpinner />
                ) : (
                  <div>
                    <div style={{ height: 180 }}>
                      <Doughnut
                        data={this.state.chart.rent_revenues}
                        options={options}
                      />
                    </div>
                    {this.returnChartList(
                      this.state.chart?.rent_revenues?.labels
                        ? this.state?.chart?.rent_revenues?.labels
                        : []
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Service Fee - Revenues per User</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingService ? (
                  <LoadingSpinner />
                ) : (
                  <div>
                    <div style={{ height: 180 }}>
                      <Doughnut
                        data={this.state.chart.service_fee_revenues}
                        options={options}
                      />
                    </div>
                    {this.returnChartList(
                      this.state.chart?.service_fee_revenues?.labels
                        ? this.state.chart?.service_fee_revenues?.labels
                        : []
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-md-6">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Monthly Service Fee</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingMonthlyService ? (
                  <LoadingSpinner />
                ) : (
                  <Line
                    data={this.state.chart.monthly_service_fee_revenues}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Monthly House Rent</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingMonthlyRent ? (
                  <LoadingSpinner />
                ) : (
                  <Line
                    data={this.state.chart.monthly_rent}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-md-12">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Daily Parking</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingWeeklyParking ? (
                  <LoadingSpinner />
                ) : (
                  <Line
                    data={this.state.chart.daily_parking_trends}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      bezierCurve: true,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(Analytics);

import React from "react";
// import { Analytics } from "../components/WasteLevels";
import { Home } from "./common";
import { Tabs } from "../components/Tabs";
// import AnalyticsHouseRent from "../components/WasteLevels/AnalyticsHouseRent";

class RevenueScreen extends React.Component {
  // renderLevels() {
  //   return <Analytics />;
  // }

  renderHouseRent() {
    // return <AnalyticsHouseRent />;
  }
  render() {
    return (
      <div>
        <Home date location month year />
        <div>{/* <Analytics /> */}</div>
      </div>
    );
  }
}

export default RevenueScreen;

import React from "react";
import { Drivers } from "../components/Driver";

class DriverScreen extends React.Component {
  render() {
    return (
      <div className="card">
        <Drivers />
      </div>
    );
  }
}

export default DriverScreen;

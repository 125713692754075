import React from "react";
import { getStorage } from "../../../app/utils/storage";
import toastMessage from "../../../app/utils/toastMessage";
import { Button } from "../../../app/components/Button";
import "./styles.css";
import axios from "axios";
import { ENDPOINT } from "../../../app/constants/api";
import Table from "../Table/Table";
import { Modal } from "../Modal";
import { DeviceInfo } from "../Devices";

class VehicleInfo extends React.Component {
  state = {
    isLoading: true,
    data: [],
    selected_device: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState(
      {
        user,
      },
      () => {
        this.getData(true);
      }
    );
  };

  getData(isLoading) {
    const { user } = this.state;

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_motor_vehicle_info",
      data: {
        id: this.props.id ? this.props.id : this.props._id,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    console.log(options);

    axios(options)
      .then((res) => {
        const data = res.data;

        this.setState({
          data,
          isLoading: false,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: "Plate Number",
        key: "number_plate",
      },
      {
        title: "Device ID",
        key: "device_id.device_id",
        type: "link",
        onPress: this.onViewDeviceDetails.bind(this),
      },
      {
        title: "Tag",
        key: "tag",
      },
    ];
    return headers;
  }

  onViewDeviceDetails(device) {
    this.setState({
      showDeviceModal: true,
      modalTitle: device.device_id.device_id + "'s Info",
      selected_device: device.device_id,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    const props = this.props;
    return (
      <div className="card">
        <div className="card-body table-responsive">
          <Table
            data={this.state.data}
            no_bordered
            style={{ marginBottom: 0 }}
            isLoading={this.state.isLoading}
            headers={this.returnTableHeaders()}
          />
        </div>
        <div className="card-footer d-flex align-content-center justify-content-end gap-3">
          <Button
            text="Close"
            className="btn-default btn-lg border"
            onPress={props.handleCloseModal}
          />
        </div>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeviceModal")}
          show={this.state.showDeviceModal}
          title={this.state.modalTitle}
        >
          <DeviceInfo
            {...this.state.selected_device}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeviceModal"
            )}
          />
        </Modal>
      </div>
    );
  }
}

export default VehicleInfo;

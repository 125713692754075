import React from "react";
import { Marker, useMap } from "react-leaflet";
import L from "leaflet";
import VehiclePopup from "./VehiclePopup";

export default function BinMarker(props) {
  const map = useMap();
  return (
    <div>
      <Marker
        icon={L.divIcon({
          className: "leaflet-data-marker",
          html: `<div class='c-marker'>
               <span>${props.marker.number_plate}</span>
              </div>`,
        })}
        position={[props.marker.latitude, props.marker.longitude]}
        eventHandlers={{
          click: (e) => {
            map.flyTo(e.latlng);
          },
        }}
      >
        <VehiclePopup {...props.marker} />
      </Marker>
    </div>
  );
}

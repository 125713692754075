import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

//screens
import ServiceFeeRevenuesPerParkScreen from "./screen/ServiceFeeRevenuesPerParkScreen";
import ServiceFeeRevenuesPerUserScreen from "./screen/ServiceFeeRevenuesPerUserScreen";
import AccountScreen from "./screen/AccountScreen";
import DeviceActivitiesScreen from "./screen/DeviceActivitiesScreen";
import AlertParkLevelScreen from "./screen/AlertParkLevelScreen";
import AlertDeviceStateScreen from "./screen/AlertDeviceStateScreen";
import IncidentWasteBinLevelScreen from "./screen/IncidentWasteBinLevelScreen";
import IncidentDeviceStateScreen from "./screen/IncidentDeviceStateScreen";
import VehicleDeviceScreen from "./screen/VehicleDeviceScreen";
import NotificationScreen from "./screen/NotificationScreen";
import CheckInTicketScreen from "./screen/CheckInTicketScreen";
import CheckOutTicketScreen from "./screen/CheckOutTicketScreen";
import RevenueScreen from "./screen/RevenueScreen";
import TripRevenueScreen from "./screen/TripRevenueScreen";
import FleetMapScreen from "./screen/FleetMapScreen";
import ServiceScreen from "./screen/ServiceScreen";
import VehicleScreen from "./screen/VehicleScreen";
import CompanyScreen from "./screen/CompanyScreen";
import SummaryActivitiesScreen from "./screen/SummaryActivitiesScreen";
import HighestRevenueScreen from "./screen/HighestRevenueScreen";
import CompanyTicketScreen from "./screen/CompanyTicketScreen";
import RevenuesPerUserScreen from "./screen/RevenuesPerUserScreen";
import MoreHighAverageScreen from "./screen/MoreHighAverageScreen";
import DriverScreen from "./screen/DriverScreen";
import TripScreen from "./screen/TripScreen";
import MoreMaxSpeedScreen from "./screen/MoreMaxSpeedScreen";
import TripReportScreen from "./screen/TripReportScreen";
import DriverReportScreen from "./screen/DriverReportScreen";
import VehicleReportScreen from "./screen/VehicleReportScreen";
import TripLogScreen from "./screen/TripLogScreen";

function App({ url = "/dashboard", appName = "fleet_management" }) {
  return (
    <Switch>
      <Route exact path={`${url}/${appName}`} component={FleetMapScreen} />
      <Route
        exact
        path={`${url}/${appName}/home/map_insights`}
        component={FleetMapScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/home/summary_activities`}
        component={SummaryActivitiesScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/device_activities`}
        component={DeviceActivitiesScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/analytics/trip_trends`}
        component={TripRevenueScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/analytics/logs`}
        component={TripLogScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/revenue_trends`}
        component={RevenueScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/validated_tickets`}
        component={CheckOutTicketScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/park_levels`}
        component={AlertParkLevelScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/park_levels/:bin_id`}
        component={AlertParkLevelScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/device_state`}
        component={AlertDeviceStateScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/park_levels`}
        component={IncidentWasteBinLevelScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/device_state`}
        component={IncidentDeviceStateScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/issued_tickets`}
        component={CheckInTicketScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/vehicle_devices`}
        component={VehicleDeviceScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/accounts`}
        component={AccountScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/notifications`}
        component={NotificationScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/notifications/id`}
        component={NotificationScreen}
      />
      <Route exact path={`${url}/${appName}/trips`} component={TripScreen} />
      <Route
        exact
        path={`${url}/${appName}/services`}
        component={ServiceScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/vehicles`}
        component={VehicleScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/companies`}
        component={CompanyScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/highest_revenues`}
        component={HighestRevenueScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/company_ticket`}
        component={CompanyTicketScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/revenues_park_per_user`}
        component={RevenuesPerUserScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/servicefee_revenues_per_user`}
        component={ServiceFeeRevenuesPerUserScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/servicefee_revenues_per_park`}
        component={ServiceFeeRevenuesPerParkScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/more_high_average_data`}
        component={MoreHighAverageScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/more_max_speed`}
        component={MoreMaxSpeedScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/drivers`}
        component={DriverScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/reports/trip_reports`}
        component={TripReportScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/reports/driver_reports`}
        component={DriverReportScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/reports/vehicle_reports`}
        component={VehicleReportScreen}
      />
    </Switch>
  );
}

export default App;

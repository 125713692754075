import React from "react";
import Table from "../../../app/components/Table/Table";
import "./styles.css";
import { getStorage } from "../../../app/utils/storage";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { Modal } from "../../../app/components/Modal";
import { ThingInfo } from "../Thing";
import { connect } from "react-redux";
import {
  onAddNotification,
  onClearCount,
} from "../../../app/action/Notification";
import socket from "../../../app/utils/socketIO";
import exportPDF from "../../../app/utils/exportPDF";
import { CSVLink } from "react-csv";

let copyData = [];

class Schedules extends React.Component {
  state = {
    data: [],
    page: 1,
    limit: 10,
    selected_thing: {},
    user: {},
    totalPageCount: 0,
    csvData: [],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);

    socket.on("schedule", (new_schedule) => {
      const schedules = this.state.data;

      if (new_schedule.status === "pending") {
        this.props.dispatch(
          onAddNotification({
            title:
              new_schedule.thing_id.thing_id +
              " - " +
              new_schedule.thing_id.address.geolocation,
            body: new_schedule.schedule_status,
            isSchedule: true,
            id: new_schedule._id,
            date: new_schedule.schedule_date,
          })
        );
      }

      for (let i = 0; i < schedules.length; i++) {
        if (schedules[i]._id == new_schedule._id) {
          schedules[i] = new_schedule;
        }
      }
      this.setState({
        data: schedules,
      });
    });

    //clear notification counts

    if (this.props.status === "pending") {
      this.props.dispatch(onClearCount("count_pending_schedule"));
    }
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  returnFilters() {
    const { filters } = this.props;

    let request_body = {};

    if (this.props.status !== "pending") {
      request_body.schedule_status = this.props.schedule_status;
    }

    if (filters && filters.locations && filters.locations.length > 0) {
      request_body.sectors = filters.locations;
    }

    if (
      filters &&
      (filters.level_min || filters.level_min === 0) &&
      filters.level_max
    ) {
      request_body.start_level_percentage =
        filters.level_min !== "" ? filters.level_min : undefined;

      request_body.end_level_percentage =
        filters.level_max !== "" ? filters.level_max : undefined;
    }

    if (filters && filters.just_collected) {
      request_body.just_collected = true;
    }

    if (filters && filters.types) {
      request_body.types = filters.types.length > 0 ? filters.types : undefined;
    }

    if (filters && filters.start_date && filters && filters.end_date) {
      request_body.start_date = new Date(filters.start_date).getTime();
      request_body.end_date = new Date(filters.end_date).getTime();
    }

    return request_body;
  }

  getData(isLoading) {
    const { user, page, limit } = this.state;

    this.setState({
      isLoading,
    });

    let request_body = this.returnFilters();

    request_body.status = this.props.status;
    request_body.page = page;
    request_body.limit = limit;

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_schedule_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data, count } = res.data;

        let on_time_data = [],
          late_data = [];

        for (let i = 0; i < data.length; i++) {
          if (data[i].schedule_status === "late") {
            late_data.push(data[i]);
          } else if (data[i].schedule_status === "ontime") {
            on_time_data.push(data[i]);
          }
        }

        const results =
          this.props.schedule_status === "ontime"
            ? on_time_data
            : this.props.schedule_status === "late"
            ? late_data
            : data;

        this.setState({
          data: results,
          isLoading: false,
          totalPageCount: count,
        });

        copyData = results.slice(0);
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleOpenModal(modal, selected_thing) {
    this.setState({
      [modal]: true,
      selected_thing: selected_thing.thing_id ? selected_thing.thing_id : {},
      modalTitle: selected_thing.thing_id
        ? "Thing #" + selected_thing.thing_id.thing_id
        : "",
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({ search_text });

    let array = [];

    for (let i = 0; i < copyData.length; i++) {
      if (
        JSON.stringify(copyData[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copyData[i]);
      }
    }

    this.setState({ data: array });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: "Alert Level",
        key: "level",
        type: "level",
      },
      {
        title: "Served Level",
        key: "served_detection_level",
        type: "level",
      },
      {
        title: "Created At",
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: "Schedule Date",
        key: "schedule_date",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: "Location",
        key: "thing_id.address.geolocation",
      },
      {
        title: "Object ID",
        key: "thing_id.thing_id",
        type: "link",
        onPress: this.handleOpenModal.bind(this, "showModal"),
      },
      {
        title: "Type",
        key: "thing_id.type",
      },
      {
        title: "Schedule Status",
        key: "schedule_status",
      },
    ];

    for (let i = 0; i < headers.length; i++) {
      if (headers[i].key === "createdAt" && this.props.status === "served") {
        headers[i] = {
          title: "Served Date",
          key: "served_date",
          isMoment: true,
          formatTime: "lll",
        };
      }

      if (
        headers[i].key === "served_detection_level" &&
        this.props.status !== "served"
      ) {
        delete headers[i];
      }
    }

    return headers;
  }

  downloadExcel() {
    const { user } = this.state;

    this.setState({
      isLoading: true,
    });

    let request_body = this.returnFilters();

    request_body.status = this.props.status;

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_schedule_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res.data;

        let on_time_data = [],
          late_data = [];

        for (let i = 0; i < data.length; i++) {
          if (data[i].schedule_status === "late") {
            late_data.push(data[i]);
          } else if (data[i].schedule_status === "ontime") {
            on_time_data.push(data[i]);
          }
        }

        const results =
          this.props.schedule_status === "ontime"
            ? on_time_data
            : this.props.schedule_status === "late"
            ? late_data
            : data;

        let formatted_results = [];

        for (let i = 0; i < results.length; i++) {
          formatted_results.push({
            ...results[i],
            thing_id: results[i].thing_id.thing_id,
            address: results[i].thing_id.address.geolocation,
            device_id: results[i].device_id.imei,
          });
        }

        this.setState(
          {
            csvData: formatted_results,
            isLoading: false,
          },
          () => {
            this.refs.csvDownload?.link.click();
          }
        );
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  downloadPDF() {
    const headers = this.returnTableHeaders();

    const { user } = this.state;

    this.setState({
      isLoading: true,
    });

    let request_body = this.returnFilters();

    request_body.status = this.props.status;

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_schedule_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res.data;

        let on_time_data = [],
          late_data = [];

        for (let i = 0; i < data.length; i++) {
          if (data[i].schedule_status === "late") {
            late_data.push(data[i]);
          } else if (data[i].schedule_status === "ontime") {
            on_time_data.push(data[i]);
          }
        }

        const results =
          this.props.schedule_status === "ontime"
            ? on_time_data
            : this.props.schedule_status === "late"
            ? late_data
            : data;

        this.setState({
          isLoading: false,
        });

        exportPDF("Schedules", headers, results);
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <Table
            data={this.state.data}
            no_bordered
            isSearch
            style={{ marginBottom: 0 }}
            totalPageCount={this.state.totalPageCount}
            page={this.state.page}
            search_text={this.state.search_text}
            handleSearch={this.handleSearch.bind(this)}
            limit={this.state.limit}
            isLoading={this.state.isLoading}
            handlePagination={this.handlePagination.bind(this)}
            headers={this.returnTableHeaders()}
            filters={[
              {
                type: "export",
                title: "Export",
                button_type: "dropdown",
                icon: "bx bxs-download",
                options: [
                  {
                    name: "PDF",
                    onPress: this.downloadPDF.bind(this),
                  },
                  {
                    name: "CSV",
                    onPress: this.downloadExcel.bind(this),
                  },
                ],
              },
            ]}
          />
        </div>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          size="lg"
        >
          <ThingInfo
            {...this.state.selected_thing}
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Schedules" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </div>
    );
  }
}

const mapStateProps = (state) => {
  const { filters } = state.Filters;
  const { notifications } = state.Notification;
  return {
    filters,
    notifications,
  };
};

export default connect(mapStateProps)(Schedules);

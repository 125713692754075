import React from "react";
import { Popup } from "react-leaflet";
import "./styles.css";
import moment from "moment";

const parkPopup = ({
  number_plate,
  speed,
  type,
  timestamp,
  geoCodedAddress,
}) => {
  return (
    <Popup>
      <div className="park-popup">
        <div className="right">
          <div className="item">
            <span>
              Number Plate: <b>{number_plate}</b>
            </span>
          </div>
          <div className="item">
            <span>
              Speed: <b>{speed}</b>
            </span>
          </div>
          <div className="item">
            <span>
              Type: <b>{type}</b>
            </span>
          </div>
          <div className="item">
            <span>
              Location: <b>{geoCodedAddress}</b>
            </span>
          </div>
          <div className="item">
            <span>
              Last Seen: <b>{moment(timestamp).format("lll")}</b>
            </span>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default parkPopup;

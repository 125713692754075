import React from "react";
import { Input, Select } from "../../Input";
import moment from "moment";

class PastRange extends React.Component {
  state = {
    show: false,
    error: {},
    distances: [],
    start_date: moment().startOf("D").format("YYYY-MM-DD HH:MM"),
    end_date: moment().endOf("D").format("YYYY-MM-DD HH:MM"),
    isSubmitting: false,
  };

  onChangeText(field, value) {
    let { error } = this.state;

    delete error[field];
    this.setState({
      [field]: value,
      error,
    });
  }

  validateForm() {
    let { error, start_date, end_date } = this.state;

    if (start_date === "") {
      error.start_date = "Start date is mandatory";
    }

    if (end_date === "") {
      error.end_date = "End date is mandatory";
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const { error, start_date, end_date } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });
    }
  };

  render() {
    return (
      <>
        <div className="date-container">
          <Input
            type="datetime-local"
            value={this.state.start_date}
            onChange={(e) => this.onChangeText("start_date", e)}
          />
          <Input
            type="datetime-local"
            value={this.state.end_date}
            onChange={(e) => this.onChangeText("end_date", e)}
          />
        </div>
      </>
    );
  }
}

export default PastRange;

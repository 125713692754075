import React from 'react';
import {Home} from './common';
import {WaterTank} from '../components/WaterTank';

class WaterTankScreen extends React.Component {
  render() {
    return (
      <div>
        <Home date location type level />
        <div>
          <WaterTank />
        </div>
      </div>
    );
  }
}

export default WaterTankScreen;

import React from "react";
import LOGO from "../../assets/logo.png";
import "./styles.css";

const Splash = () => {
  return (
    <div className="splash-container">
      <div className="logo-splash-container">
        <img src={LOGO} />
      </div>
    </div>
  );
};

export default Splash;

const vehicleTags = [
  {
    label: "Cars",
    value: "car",
  },
  {
    label: "Buses",
    value: "bus",
  },
  {
    label: "Trucks",
    value: "truck",
  },
];

export default vehicleTags;

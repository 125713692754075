import DailyActive from "./DailyActive";
import DailyStationary from "./DailyStationary";
import AverageSpeedPerDay from "./AverageSpeedPerDay";
import DairlyTotalRevenues from "./DairlyTotalRevenues";
import RevenuesPerPark from "./RevenuesPerPark";

export {
  DairlyTotalRevenues,
  RevenuesPerPark,
  AverageSpeedPerDay,
  DailyStationary,
  DailyActive,
};

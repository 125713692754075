import React from "react";

import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { getStorage } from "../../../app/utils/storage";
import { Button } from "../../../app/components/Button";
import { Input, Select } from "../../../app/components/Input";
import formatSelectData from "../../../app/utils/formatSelectData";

class Group extends React.Component {
  state = {
    group_id: "",
    error: {},
    isSubmitting: false,
    user: {},
    id: "",
    user_groups: [],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    if (this.props.createdAt) {
      this.setState({ group_id: this.props.group_id });
    }

    if (this.props.email && this.props.createdAt) {
      this.getGroups(true);
    }
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getGroups(isLoading) {
    const { user } = this.state;

    this.setState({
      isLoading,
    });

    let url = ENDPOINT + "/get_access_group";

    const options = {
      method: "POST",
      url,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let data = res.data;

        let user_groups = formatSelectData(data, "group_id", "_id");
        this.setState({
          user_groups,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", "Retrieving account groups was failed ");
      });
  }

  onChangeText(name, e) {
    let error = this.state.error;
    let value = e.target ? e.target.value : e;

    delete error[name];

    this.setState({
      error,
      [name]: value,
    });
  }

  validateForm() {
    let { error, group_id } = this.state;

    if (group_id === "") {
      error.group_id = "Group id is required";
    }

    this.setState({ error });
  }

  onAddGroup = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      let { user, group_id } = this.state;
      let { _id, createdAt } = this.props;

      let url = ENDPOINT + "/register_access_group";

      this.setState({
        isSubmitting: true,
      });

      let data = {
        group_id,
      };

      // if (user.ref_org_account) {
      //   data.ref_org_account = user.ref_org_account;
      // }

      // if (user.ref_subaccount) {
      //   data.ref_subaccount = [user.ref_subaccount];
      // }

      if (createdAt) {
        data.id = _id;
        url = ENDPOINT + "/update_access_group";
      }

      const options = {
        method: "POST",
        url,
        data,
        headers: {
          authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            `Group  ${createdAt ? "updated" : "added"}  successfully `
          );

          this.props.getGroups(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("error", error);
        });
    }
  };

  validateAssignForm() {
    let { error, group_id } = this.state;

    if (!group_id) {
      error.group_id = "Group id is required";
    } else if (group_id.length === 0) {
      error.group_id = "Group id is required";
    }

    this.setState({ error });
  }

  onAssignGroup = async () => {
    await this.validateAssignForm();

    const { group_id, user, _id, error } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let url = "";

      if (user.account_type === "admin_account" && _id !== "") {
        url = ENDPOINT + "/update_user_account";
      }

      if (user.account_type === "user_account" && _id !== "") {
        url = ENDPOINT + "/update_user_subaccount";
      }

      let data = {
        access_group: group_id,
      };

      if (_id !== "") {
        delete data.password;

        data.id = _id;
      }

      const options = {
        method: "POST",
        url,
        data,
        headers: {
          authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((data) => {
          toastMessage(
            "success",
            `Account ${
              this.state._id !== "" ? "updated" : "created"
            } successfully`
          );

          this.props.handleCloseModal();
          this.props.getAccounts(true);
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("error", error);
        });
    }
  };

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className={this.props.isAccount && "col-md-4"}>
              {this.props._id && this.props.email ? (
                <Select
                  label="Groups:"
                  required
                  className="form-control-lg"
                  value={this.state.group_id}
                  onChange={(e) => this.onChangeText("group_id", e)}
                  error={this.state.error.group_id}
                  options={this.state.user_groups}
                  isMulti
                  isLoading={this.state.isLoading}
                />
              ) : (
                <Input
                  label="Group ID:"
                  required
                  className="form-control-lg"
                  value={this.state.group_id}
                  onChange={(e) => this.onChangeText("group_id", e)}
                  error={this.state.error.group_id}
                />
              )}

              <Button
                className="btn-primary"
                text="Submit"
                onPress={this.onAddGroup.bind(this)}
                isSubmitting={this.state.isSubmitting}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Group;

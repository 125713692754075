import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import { getStorage } from "../../../utils/storage";

export default async (that) => {
  const { defaultApplication } = that.props;
  const user = await getStorage();

  let applications = user.business_category
    ? user.business_category.map((el) => {
        return {
          value: el._id,
          label: capitalizeFirstLetter(el.name),
          ...el,
        };
      })
    : [];

  that.setState({
    applications,
    application: {
      value: defaultApplication?._id,
      label: capitalizeFirstLetter(defaultApplication?.name),
      ...defaultApplication,
    },
  });
};

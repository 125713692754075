import React from 'react';
import {Home} from './common';
import {Tabs} from '../components/Tabs';
import {ElectricityFlow} from './../components/ElectricityFlow';

class ElectricityFlowScreen extends React.Component {
  render() {
    return (
      <div>
        <Home date location type />
        <div>
          <ElectricityFlow />
        </div>
      </div>
    );
  }
}

export default ElectricityFlowScreen;

import React from "react";
import { Marker, useMap } from "react-leaflet";
import L from "leaflet";
import ThingPopup from "./ThingPopup";
import "./water.styles.css";
import returnLevel from "./utils/returnLevel";

export default function ThingMarker(props) {
  const map = useMap();
  const levelConfig = returnLevel({
    type: props.marker.type,
    level: props.marker.level_percentage || props.marker.level,
  });

  return (
    <div>
      <Marker
        icon={L.divIcon({
          className: "leaflet-data-marker",
          html: `<div className='bin-marker ' style='background-color:${levelConfig.color}'>
               <i className='bx ${levelConfig?.icon}'></i>
              </div>`,
        })}
        position={[props.marker.latitude, props.marker.longitude]}
        eventHandlers={{
          click: (e) => {
            map.flyTo(e.latlng);
          },
        }}
      >
        <ThingPopup data={props.marker} color={levelConfig?.color} />
      </Marker>
    </div>
  );
}

import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import deepFind from "../../../app/utils/deepFind";
import { Button, DropdownButton } from "../Button";
import { Input, Switch } from "../../../app/components/Input";
import { Pagination } from "../Pagination";
import "./styles.css";
import { LoadingSpinner } from "../LoadingSpinner";
import { CSVLink } from "react-csv";
import Circular from "../Progress/Circular";
import { TimeAgo } from "../TimeAgo";

let copyData = [];
class Table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: props.page ? props.page : 1,
      limit: props.limit,
      search_text: "",
      data: props.data,
    };
  }

  componentDidMount(props) {
    copyData = this.props.data;
  }

  componentDidUpdate(nextProps, nextState) {
    if (nextState.data !== this.props.data) {
      this.setState({
        data: this.props.data,
      });
    }
  }

  handleSearch(e) {
    let new_data = [],
      search_text = e.target.value;

    for (let i = 0; i < copyData.length; i++) {
      if (
        JSON.stringify(copyData[i])
          .toUpperCase()
          .indexOf(search_text.toUpperCase()) !== -1
      ) {
        new_data.push(copyData[i]);
      }
    }

    this.setState({
      data: new_data,
      search_text,
    });
  }

  render() {
    const {
      handlePagination,
      headers,
      totalPageCount,
      actions,
      rowPress,
      no_bordered,
      filters,
      showAdd,
      addButtonText,
      handleAddPressed,
      isLoading,
      page,
      placeholder,
      handleSort,
      sortOrder,
      sortColumn,
      tab,
      showRealTime,
    } = this.props;

    const { limit, data } = this.state;

    const currentData = data;
    const firstPage = 1;
    const lastPage = totalPageCount;

    const paginate = async (numPage) => {
      await handlePagination(numPage, tab);
    };

    const nextPage = async () => {
      if (page === lastPage) return;
      await handlePagination(page + 1, tab);
    };

    const prevPage = async () => {
      if (page === firstPage) return;
      await handlePagination(page - 1, tab);
    };

    return (
      <>
        <div
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            display: "block",
            alignItems: "flex-start",
          }}
        >
          <div className="row">
            <div className="col-md-4">
              {this.props.isSearch && (
                <div className="search-container">
                  <Input
                    placeholder={placeholder ? placeholder : "Search..."}
                    value={
                      this.props.search_text
                        ? this.props.search_text
                        : this.state.search_text
                    }
                    onChange={
                      this.props.handleSearch
                        ? tab
                          ? (e) => this.props.handleSearch(tab, e)
                          : this.props.handleSearch
                        : this.handleSearch.bind(this)
                    }
                    iconRight="bx bx-search"
                    inputContainerStyle={{ marginBottom: 0 }}
                    autoComplete="off"
                    type="search"
                  />
                  {this.props.addSearchButton && (
                    <Button
                      className="btn-primary"
                      icon="bx-search"
                      onPress={this.props.handlePressSearch}
                    />
                  )}
                </div>
              )}
            </div>
            <div className="col-md-8 mb-3">
              <div className="d-flex gap-2 flex-wrap justify-content-end">
                {filters &&
                  filters.map((filter, f) => {
                    if (filter.button_type === "dropdown") {
                      return (
                        <div key={f}>
                          <DropdownButton
                            className="btn-default bordered btn-sm dropdown-toggle dropdown-toggle-split"
                            text={filter.title}
                            selected={filter.selected}
                            isSearchable={filter.isSearchable}
                            options={filter.options}
                            onSelectDropdownItem={filter.onSelectDropdownItem}
                            search_text={filter.search_text}
                            onChangeSearch={filter.onChangeSearch}
                            selected_item={filter.selected_item}
                            isLoading={filter.isLoading}
                            isMulti={filter.isMulti}
                            default_key={filter.default_key}
                            handleReset={filter.handleReset}
                            clickBehaviorId="dropdownMenuClickableInside"
                            autoCloseType="outside"
                            icon={filter.icon}
                          >
                            {filter.options.map((option, o) => {
                              return (
                                <li key={o}>
                                  {option.isDownloadCsv ? (
                                    <CSVLink
                                      filename={option.filename + ".csv"}
                                      data={
                                        option && option.csvData
                                          ? option.csvData
                                          : []
                                      }
                                      asyncOnClick={true}
                                      // onClick={
                                      //   option.onPress ? option.onPress : null
                                      // }
                                      onClick={(event, done) => {
                                        option.onPress().then((res) => {
                                          console.log(res);
                                          done(false); // Don't Proceed
                                        });
                                      }}
                                      className="dropdown-item"
                                    >
                                      {option.name
                                        ? option.name
                                        : option.label
                                        ? option.label
                                        : ""}
                                    </CSVLink>
                                  ) : (
                                    <Link
                                      onClick={() => option.onPress(option)}
                                      className="dropdown-item"
                                      to="#"
                                    >
                                      {option.name
                                        ? option.name
                                        : option.label
                                        ? option.label
                                        : ""}
                                    </Link>
                                  )}
                                </li>
                              );
                            })}
                          </DropdownButton>
                        </div>
                      );
                    } else {
                      return (
                        <Button
                          selected={filter.selected}
                          className="btn-gray btn-sm"
                          text={filter.title}
                          onPress={filter.onPress}
                          icon={filter.icon ? filter.icon : "bx bx-filter"}
                        />
                      );
                    }
                  })}
                {showAdd && (
                  <Button
                    className="btn-primary btn-sm"
                    onPress={handleAddPressed}
                    text={addButtonText}
                    icon="bx bx-plus"
                  />
                )}
                {showRealTime && (
                  <div className="checkbox-container-table">
                    <label>
                      <input
                        type="checkbox"
                        onChange={this.props.handleRealTime}
                        checked={this.props.isReaTime}
                      />
                      <span>
                        <b>{this.props.checkboxLabel}</b>
                      </span>
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table
            className={`table table-hover table-condensed ${
              !no_bordered && "table-bordered"
            }`}
            style={this.props.style}
          >
            <thead style={this.props.style_header}>
              <tr>
                {headers &&
                  headers.map((item, i) => {
                    return (
                      <th key={i}>
                        {item.title}
                        {item.sort && (
                          <button
                            className="sort-btn"
                            onClick={() => handleSort(item.key, tab)}
                          >
                            {(sortOrder === -1 || sortOrder === "desc") &&
                            sortColumn === item.key ? (
                              <i className="bx bx-up-arrow-alt"></i>
                            ) : (
                              <i className="bx bx-down-arrow-alt"></i>
                            )}
                          </button>
                        )}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody className={this.props.tableBodyClass}>
              {isLoading ? (
                <tr>
                  <td colSpan={headers.length}>
                    <LoadingSpinner />
                  </td>
                </tr>
              ) : currentData.length === 0 ? (
                <tr>
                  <td colSpan={13} align="center">
                    No data found
                  </td>
                </tr>
              ) : (
                currentData.map((item, i) => {
                  return (
                    <tr key={i}>
                      {headers &&
                        headers.map((header) => {
                          let hasActionBtn = header.key === "action";
                          let find_text = deepFind(item, header.key)
                            ? deepFind(item, header.key)
                            : "-";
                          const text =
                            header.isMoment &&
                            item[header.key] &&
                            item[header.key] !== ""
                              ? moment(item[header.key]).format(
                                  header.formatTime
                                )
                              : find_text;

                          return (
                            <>
                              {hasActionBtn ? (
                                <td>
                                  <div className="dropdown">
                                    <Button
                                      className="btn-transparent btn-sm "
                                      type="button"
                                      id="dropdownMenuButton1"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      icon="bx-dots-vertical-rounded"
                                    />
                                    <ul
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton1"
                                    >
                                      {actions &&
                                        actions.map((action, a) => {
                                          return (
                                            <Link
                                              key={a}
                                              className="dropdown-item"
                                              to={
                                                action.route
                                                  ? action.route
                                                  : "#"
                                              }
                                              onClick={() =>
                                                action.onPress(item, i)
                                              }
                                            >
                                              {action.name}
                                            </Link>
                                          );
                                        })}
                                    </ul>
                                  </div>
                                </td>
                              ) : (
                                <td>
                                  {header.type === "photo" ? (
                                    <div>
                                      <img
                                        onClick={() =>
                                          header.onPress
                                            ? header.onPress(item)
                                            : null
                                        }
                                        src={text}
                                        style={{
                                          width: 100,
                                          height: 50,
                                          borderRadius: 8,
                                          border: "2px solid #777",
                                          aspectRatio: 3 / 2,
                                          objectFit: "cover",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                  ) : header.type === "level" ? (
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginRight: 10,
                                            marginTop: -5,
                                          }}
                                        >
                                          <Circular
                                            width={18}
                                            height={18}
                                            percentage={item[header.key]}
                                          />
                                        </div>
                                        <span>{item[header.key]} %</span>
                                      </div>
                                    </div>
                                  ) : header.type === "link" ? (
                                    <div>
                                      <Link
                                        className="text-primary"
                                        onClick={() =>
                                          header.onPress
                                            ? header.onPress(item)
                                            : null
                                        }
                                        to="#"
                                      >
                                        <u> {text}</u>
                                      </Link>
                                    </div>
                                  ) : header.type === "timeAgo" ? (
                                    <div>
                                    
                                    </div>
                                  ) : header.type === "sensor_status" ? (
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: 10,
                                            height: 10,
                                            borderRadius: "50%",
                                            backgroundColor:
                                              item[header.key] === "off"
                                                ? "#f05"
                                                : "#05c605",
                                            marginRight: 10,
                                          }}
                                        />
                                        <span>{item[header.key]}</span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className="data"
                                      onClick={() =>
                                        header.key !== "action"
                                          ? rowPress(item)
                                          : null
                                      }
                                      title={text}
                                      style={{
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        maxWidth: 200,
                                        overflow: "hidden",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <span>{text + ""}</span>
                                    </div>
                                  )}
                                </td>
                              )}
                            </>
                          );
                        })}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        {totalPageCount !== 0 && totalPageCount && (
          <Pagination
            totalPageCount={totalPageCount}
            limit={limit}
            paginate={paginate}
            length={currentData.length}
            nextPage={nextPage}
            prevPage={prevPage}
            firstPage={firstPage}
            lastPage={lastPage}
            page={page}
            tab={tab}
          />
        )}
      </>
    );
  }
}

export default Table;

import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { Navbar } from "../components/Navbar";
import { Sidebar } from "../components/Sidebar";
import { ENDPOINT } from "../../app/constants/api";
import { clearStorage, getStorage } from "../../app/utils/storage";
import toastMessage from "../utils/toastMessage";
import socket from "../../app/utils/socketIO";
import { onToggle } from "../action/ToggleSidebar";

class DashboardLayout extends React.Component {
  state = {
    user: {},
  };
  componentDidMount = async () => {
    const user = await getStorage();

    if (!user.token) {
      window.location.href = "/login";
      return;
    } else {
      // this.getAccount(user);

      this.setState({
        user,
      });

      socket.on("connect", function (data) {
        socket.emit("user_info", { email: user.username, id: user.id });
      });
    }

    const { innerWidth } = window;

    if (innerWidth < 750) {
      this.props.dispatch(onToggle(false));
    }

    window.addEventListener("resize", this.handleResize.bind(this));
  };

  getAccount(user) {
    let body = {},
      url = "";

    if (
      user.account_type === "user_account" ||
      user.account_type === "user_subaccount"
    ) {
      url = ENDPOINT + "/get_user_subaccount";

      body.user_id = [user.id];
      body.ref_org_account = [user.id];
    }

    if (user.account_type === "admin_account") {
      url = ENDPOINT + "/get_user_account";
    }

    const options = {
      method: "POST",
      url,
      data: {
        email: user.username,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        //token still valid: DO:: nothing
      })
      .catch((error) => {
        toastMessage("error", "Token expired, Login again");

        window.location.href = "/login";
      });
  }

  componentDidUpdate(nextProps) {
    // if (this.props.location.pathname !== nextProps.location.pathname) {
    //   const { innerWidth } = window;
    //   if (innerWidth < 750) {
    //     this.props.dispatch(onToggle(false));
    //   }
    // }
  }

  handleResize() {
    const { innerWidth } = window;

    if (innerWidth > 750) {
      this.props.dispatch(onToggle(true));
    } else {
      this.props.dispatch(onToggle(false));
    }
  }

  onLogout = async () => {
    await clearStorage();
    this.props.dispatch({ type: "ON_USER_LOGOUT" });

    const options = {
      method: "POST",
      url: ENDPOINT + "/broadcast_logout",
      data: {
        ...this.state.user,
      },
      headers: {
        authorization: "Bearer " + this.state.user.token,
      },
    };

    axios(options);

    window.location.href = "/login";
  };

  render() {
    let { location } = this.props;

    let pathname = location && location.pathname ? location.pathname : "";

    let title = "";

    if (pathname !== "") {
      const last_t = pathname.split("/");

      title = last_t[last_t.length - 1].replace("_", " ");
    }

    return (
      <div className="main-layout-dashboard">
        <Sidebar {...this.props} />
        <div className="dash-container-wrapper">
          <Navbar
            onLogout={this.onLogout.bind(this)}
            title={title}
            user={this.state.user}
          />
          <div className="dash-content">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default connect(null)(DashboardLayout);

export const menus = [
  {
    group: "Home",
    icon: "bx-home",
    items: [
      {
        name: "Map Insights",
        group: "Home",
      },
      {
        name: "Summary Activities",
        group: "Home",
      },
    ],
    show: true,
  },
  {
    group: "Analytics",
    icon: "bxs-dashboard",
    items: [
      {
        name: "Revenues Trends",
        group: "Analytics",
      },
      {
        name: "Device Activities",
        group: "Analytics",
      },
    ],

    show: true,
  },
  {
    group: "Tickets",
    icon: "bx-time-five",
    items: [
      {
        name: "Issued Tickets",
        group: "Tickets",
      },
      {
        name: "Validated Tickets",
        group: "Tickets",
      },
    ],
    show: true,
  },
  {
    group: "Alerts",
    icon: "bx-error",
    items: [
      {
        name: "Park Levels",
        route: "/dashboard/alerts/waste_levels",
        group: "Alerts",
      },
      {
        name: "Device State",
        group: "Alerts",
      },
    ],
    show: true,
  },
  {
    group: "Incidents",
    icon: "bx-copy",
    items: [
      {
        name: "Park Levels",
        group: "Incidents",
      },
      {
        name: "Device State",
        group: "Incidents",
      },
    ],
    show: true,
  },
  {
    group: "Vehicles",
    route: "/dashboard/vehicles",
    icon: "bx bx-bus",
    role: "admin",
  },

  {
    group: "Services",
    route: "/dashboard/services",
    icon: "bx bx-server",
    role: "admin",
  },
  {
    group: "Companies",
    route: "/dashboard/companies",
    icon: "bx bx-buildings",
    role: "admin",
  },
  {
    group: "Parks & Devices",
    route: "/dashboard/parks_&_devices",
    icon: "bx-devices",
    role: "admin",
  },
  {
    group: "Accounts",
    route: "/dashboard/accounts",
    icon: "bx-group",
  },
];

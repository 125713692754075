import React from "react";
import { Link } from "react-router-dom";
import Table from "../Table/Table";
import "./styles.css";
import { getStorage } from "../../../app/utils/storage";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { connect } from "react-redux";
import { Modal } from "../Modal";
import filtersData from "../../../app/utils/filtersData";
import { VehicleInfo } from "../Vehicle";

class LowAverageSpeed extends React.Component {
  state = {
    data: [],
    user: {},
    selected_data: {},
    limit: 5,
    page: 1,
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  returnFilters() {
    const { page, limit } = this.state;
    const { filters } = this.props;

    let request_body = {
      target: "rank",
      tag: "average_low_speed",
      limit,
      page,
      filters,
    };

    return filtersData(request_body);
  }

  getData(isLoading) {
    const { user } = this.state;

    if (this.props.all) {
      delete request_body.page;
      delete request_body.limit;
    }

    let request_body = this.returnFilters();

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_location_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        this.setState({
          isLoading: false,
          data: res?.data?.data || res.data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  handleOpenModal(modal, selected_data) {
    this.setState({
      [modal]: true,
      modalTitle: "#" + selected_data.vehicle.number_plate,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    return (
      <div className="card" style={this.props.styles}>
        {!this.props.all && (
          <div className="card-header">
            <h3>Top 5 Low Average Speed</h3>
          </div>
        )}
        <div className="card-body" style={{ paddingBottom: 0 }}>
          <Table
            data={this.state.data}
            no_bordered
            style={{ marginBottom: 0 }}
            isLoading={this.state.isLoading}
            headers={[
              {
                title: "Number Plate",
                key: "_id",
              },
              {
                title: "Average Speed",
                key: "average_speed",
              },
            ]}
          />
        </div>
        {this.state.data.length > 0 && !this.props.all && (
          <center className="card-footer border-top">
            <Link
              to="/dashboard/revenues_park_per_user"
              className="text-primary"
            >
              <b>View More</b>
            </Link>
          </center>
        )}
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showVehicleModal")}
          show={this.state.showVehicleModal}
          title={this.state.modalTitle}
          size="lg"
        >
          <VehicleInfo
            {...this.state.selected_data.vehicle}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showVehicleModal"
            )}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return { filters };
};

export default connect(mapStateToProps)(LowAverageSpeed);

export default (response) => {
  if (response?.results.length > 0) {
    const addressComponents = response.results[0].address_components;
    const addressParts = {
      street: "",
      neighborhood: "",
      district: "",
      city: "",
    };

    addressParts.street = response.plus_code.global_code;
    for (const component of addressComponents) {
      if (component.types.includes("route")) {
        addressParts.street = component.long_name;
      } else if (component.types.includes("sublocality_level_1")) {
        addressParts.neighborhood = component.long_name; // Set the neighborhood/sector name
      } else if (component.types.includes("administrative_area_level_2")) {
        addressParts.district = component.long_name; // Set the district name
      } else if (component.types.includes("locality")) {
        addressParts.city = component.long_name;
      }
    }

    return Object.values(addressParts).join(", ");
  } else {
    return "No results found";
  }
};

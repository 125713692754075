import axios from "axios";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CardCount } from "../../../app/components/CardCount";
import { Header } from "../../components/Header";
import { ENDPOINT } from "../../../app/constants/api";
import filtersData from "../../../app/utils/filtersData";
import { getStorage } from "../../../app/utils/storage";
import toastMessage from "../../../app/utils/toastMessage";

class Home extends React.Component {
  state = {
    user: {},
    route: "",
    users: [],
    totalVehicles: "...",
    totalActiveVehicles: "...",
    totalMovingVehicles: "...",
    totalStationaryVehicles: "...",
  };
  componentDidMount = async () => {
    const user = await getStorage();

    await this.setState({ user });

    this.getVehicles(true);
    this.getMovingVehicles(true);
    this.getStationary(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getVehicles(true);
      this.getMovingVehicles(true);
      this.getStationary(true);
    }
  }

  getStationary(isLoadingStationary) {
    const { user } = this.state;

    let request_body = {
      target: "last",
      tag: "count",
      is_moving: false,
    };

    this.setState({
      isLoadingStationary,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_location_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoadingStationary: false,
          totalStationaryVehicles: data.length > 0 ? data[0].count : 0,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingStationary: false });
      });
  }

  getMovingVehicles(isLoadingMoving) {
    const { user } = this.state;

    let request_body = {
      target: "last",
      tag: "count",
      is_moving: true,
    };

    this.setState({
      isLoadingMoving,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_location_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        console.log({ isLoadingMoving: res.data });
        const { data } = res.data;

        this.setState({
          isLoadingMoving: false,
          totalMovingVehicles: data.length > 0 ? data[0].count : 0,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingMoving: false });
      });
  }

  getVehicles(isLoadingVehicle) {
    const { user } = this.state;

    const { filters } = this.props;

    let params = {
      filters,
    };

    const request_body = filtersData(params);

    request_body.tag = "count";

    delete request_body.month_paid_fors;
    delete request_body.year_paid_fors;
    delete request_body.end_createdAt;
    delete request_body.start_createdAt;

    this.setState({
      isLoadingVehicle,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_motor_vehicle_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoadingVehicle: false,
          totalVehicles: data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingVehicle: false });
      });
  }

  // getDrivers(isLoadingActiveVehicles) {
  //   const { user } = this.state;

  //   const { filters } = this.props;

  //   let params = {
  //     filters,
  //   };

  //   const request_body = filtersData(params);

  //   request_body.tag = "count";

  //   delete request_body.month_paid_fors;
  //   delete request_body.year_paid_fors;
  //   delete request_body.end_createdAt;
  //   delete request_body.start_createdAt;

  //   this.setState({
  //     isLoadingActiveVehicles,
  //   });

  //   const options = {
  //     method: "POST",
  //     url: ENDPOINT + "/get_driver_info",
  //     data: request_body,
  //     headers: {
  //       authorization: "Bearer " + user.token,
  //     },
  //   };

  //   axios(options)
  //     .then((res) => {
  //       const { data } = res.data;

  //       this.setState({
  //         isLoadingActiveVehicles: false,
  //         totalActiveVehicles: data,
  //       });
  //     })
  //     .catch((error) => {
  //       toastMessage("error", error);
  //       this.setState({ isLoadingActiveVehicles: false });
  //     });
  // }

  handlePressCount(route) {
    this.setState({ route });
  }

  render() {
    if (this.state.route !== "") {
      return <Redirect to={this.state.route} />;
    }

    let list_filters = [];

    // if (this.props.location) {
    //   list_filters.push({
    //     name: "Park Station",
    //     isDropdown: true,
    //     selected:
    //       this.props.filters &&
    //       this.props.filters.locations &&
    //       this.props.filters.locations.value
    //         ? this.props.filters.locations.label
    //         : ["All"],
    //     clickBehaviorId: "dropdownMenuClickableInside",
    //     autoCloseType: "outside",
    //   });
    // }

    if (this.props.company) {
      list_filters.push({
        name: "company",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.companies &&
          this.props.filters.companies.value
            ? this.props.filters.companies.label
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.vehicle) {
      list_filters.push({
        name: "vehicle",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.vehicles &&
          this.props.filters.vehicles.length > 0
            ? this.props.filters.vehicles
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.user_account) {
      list_filters.push({
        name: "user_account",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.users &&
          this.props.filters.users.length > 0
            ? this.props.filters.users
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.date) {
      list_filters.push({
        name: "date",
        position: "right",
        isDropdown: true,
        selected: [
          moment(this.props.filters.start_date).format("DD-MM-YYYY"),
          moment(this.props.filters.end_date).format("DD-MM-YYYY"),
        ],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    // if (this.props.month) {
    //   list_filters.push({
    //     name: "month",
    //     position: "right",
    //     isDropdown: true,
    //     selected:
    //       this.props.filters &&
    //       this.props.filters.month &&
    //       this.props.filters.month.length > 0
    //         ? this.props.filters.month
    //         : ["All"],
    //     clickBehaviorId: "dropdownMenuClickableInside",
    //     autoCloseType: "outside",
    //   });
    // }

    // if (this.props.year) {
    //   list_filters.push({
    //     name: "year",
    //     position: "right",
    //     isDropdown: true,
    //     selected:
    //       this.props.filters &&
    //       this.props.filters.year &&
    //       this.props.filters.year.length > 0
    //         ? this.props.filters.year
    //         : ["All"],
    //     clickBehaviorId: "dropdownMenuClickableInside",
    //     autoCloseType: "outside",
    //   });
    // }

    return (
      <div>
        <Header filters={list_filters} />
        <hr />
        {this.props.isCountCard && (
          <div className="row">
            <>
              <div
                className="col-6 col-md-3"
                onClick={() =>
                  (window.location.href =
                    "/dashboard/fleet_management/vehicle_devices")
                }
              >
                <CardCount
                  title="Registered Vehicles"
                  total={
                    this.state.isLoadingVehicle
                      ? "..."
                      : this.state.totalVehicles
                  }
                  icon="bx-car"
                />
              </div>
              <div className="col-6 col-md-3">
                <CardCount
                  title="Active Vehicles"
                  total={
                    this.state.isLoadingStationary || this.state.isLoadingMoving
                      ? "..."
                      : this.state.totalMovingVehicles +
                        this.state.totalStationaryVehicles
                  }
                  icon="bxs-car-garage"
                />
              </div>
              <div
                className="col-6 col-md-3"
                onClick={() =>
                  (window.location.href = "/dashboard/fleet_management/trips")
                }
              >
                <CardCount
                  title="Moving Vehicles"
                  total={
                    this.state.isLoadingMoving
                      ? "..."
                      : this.state.totalMovingVehicles || 0
                  }
                  icon="bx-select-multiple"
                />
              </div>
              <div className="col-6 col-md-3">
                <CardCount
                  title="Stationary Vehicles"
                  total={
                    this.state.isLoadingStationary
                      ? "..."
                      : this.state.totalStationaryVehicles || 0
                  }
                  icon="bx-git-pull-request"
                />
              </div>
            </>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(Home);

import React from "react";
import { Notification } from "../components/Notification";
import { TopCompanies } from "../components/TopCompanies";
import { TopRevenues } from "../components/TopRevenues";

class CompanyTicketScreen extends React.Component {
  render() {
    return (
      <div>
        <TopCompanies all />
      </div>
    );
  }
}

export default CompanyTicketScreen;

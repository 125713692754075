import axios from "axios";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CardCount } from "../../../app/components/CardCount";
import { Header } from "../../components/Header";
import { ENDPOINT } from "../../../app/constants/api";
import filtersData from "../../../app/utils/filtersData";
import { numberWithCommas } from "../../../app/utils/formatCurrency";
import { getStorage } from "../../../app/utils/storage";
import toastMessage from "../../../app/utils/toastMessage";

class Home extends React.Component {
  state = {
    user: {},
    total_entity: 0,
    route: "",
    users: [],
    total_revenues: "...",
    total_entity: "...",
    total_issued_tickets: "...",
    total_stock: "...",
    total_revenues: "...",
  };

  componentDidMount = async () => {
    const user = await getStorage();

    await this.setState({ user });

    this.getStock(true);
    this.getEntityRevenues(true);
    this.getTickets(true);
    this.getEntity(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getStock(true);
      this.getEntityRevenues(true);
      this.getTickets(true);
      this.getEntity(true);
    }
  }

  getTickets(isLoadingTickets) {
    const { user } = this.state;
    const { filters } = this.props;

    let params = {
      filters,
      parkFilterType: "entity_id",
      vehicleFilterType: "ticket_user_ids",
    };

    const request_body = filtersData(params);

    request_body.status = "pending";
    request_body.tag = "count";

    this.setState({
      isLoadingTickets,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoadingTickets: false,
          total_tickets: data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingTickets: false });
      });
  }

  getStock(isLoadingStock) {
    const { user } = this.state;

    let request_body = {};

    request_body.tag = "count";

    this.setState({
      isLoadingStock,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_stock_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoadingStock: false,
          total_stock: data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingStock: false });
      });
  }

  getEntityRevenues(isLoadingEntityRevenues) {
    const { user } = this.state;
    const { filters } = this.props;

    let params = {
      filters,
      parkFilterType: "entity_id",
      vehicleFilterType: "ticket_user_ids",
    };

    let request_body = filtersData(params);

    request_body.tag = "trend";
    request_body.order = "daily";

    this.setState({
      isLoadingEntityRevenues,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let total_revenues = 0;

        console.log("====================================");
        console.log({
          revenues: res.data,
        });
        console.log("====================================");

        for (let el of res.data) {
          total_revenues += parseInt(el?.total_revenues) || 0;
        }

        this.setState({
          isLoadingRevenues: false,
          total_revenues,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingRevenues: false });
      });
  }

  getEntity(isLoadingEntity) {
    const { user } = this.state;

    this.setState({ isLoadingEntity });

    let request_body = {
      tag: "count",
      status: "active",
    };

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_entity_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const total_entity = res.data.data;
        this.setState({ total_entity, isLoadingEntity: false });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingEntity: false });
      });
  }

  handlePressCount(route) {
    this.setState({ route });
  }

  render() {
    if (this.state.route !== "") {
      return <Redirect to={this.state.route} />;
    }

    let list_filters = [];

    if (this.props.entity) {
      list_filters.push({
        name: "entity",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.entity &&
          this.props.filters.entity.value
            ? this.props.filters.entity.label
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.client) {
      list_filters.push({
        name: "client",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.client &&
          this.props.filters.client.value
            ? this.props.filters.client.label
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.vehicle) {
      list_filters.push({
        name: "vehicle",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.vehicles &&
          this.props.filters.vehicles.length > 0
            ? this.props.filters.vehicles
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.paymentmethod) {
      list_filters.push({
        name: "paymentmethod",
        position: "right",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.paymentmethod &&
          this.props.filters.paymentmethod.length > 0
            ? this.props.filters.paymentmethod
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.user_account) {
      list_filters.push({
        name: "user_account",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.users &&
          this.props.filters.users.length > 0
            ? this.props.filters.users
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.date) {
      list_filters.push({
        name: "date",
        position: "right",
        isDropdown: true,
        selected: [
          moment(this.props.filters.start_date).format("DD-MM-YYYY"),
          moment(this.props.filters.end_date).format("DD-MM-YYYY"),
        ],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.month) {
      list_filters.push({
        name: "month",
        position: "right",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.month &&
          this.props.filters.month.length > 0
            ? this.props.filters.month
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.year) {
      list_filters.push({
        name: "year",
        position: "right",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.year &&
          this.props.filters.year.length > 0
            ? this.props.filters.year
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    return (
      <div>
        <Header filters={list_filters} />
        <hr />
        {this.props.isCountCard && (
          <div className="row">
            <>
              <div className="col-6 col-md-3">
                <CardCount
                  title="Total Entities"
                  total={
                    this.state.isLoadingEntity ? "..." : this.state.total_entity
                  }
                  icon="bx-buildings"
                />
              </div>
              <div
                className="col-6 col-md-3"
                onClick={() => (window.location.href = "/dashboard/stock")}
              >
                <CardCount
                  title="Total Stocks"
                  total={
                    this.state.isLoadingStock ? "..." : this.state.total_stock
                  }
                  icon="bx-data"
                />
              </div>
              <div className="col-6 col-md-3">
                <CardCount
                  title="Total Issued Tickets"
                  total={
                    this.state.isLoadingTickets
                      ? "..."
                      : this.state.total_tickets
                  }
                  icon="bx-file"
                />
              </div>
              <div className="col-6 col-md-3">
                <CardCount
                  title="Total Revenues"
                  total={
                    this.state.isLoadingRevenues
                      ? "..."
                      : numberWithCommas(this.state.total_revenues)
                  }
                  icon="bx-wallet"
                />
              </div>
            </>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(Home);

import React from "react";
import "./styles.css";
import { Bar } from "react-chartjs-2";
import { getStorage } from "../../../app/utils/storage";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { connect } from "react-redux";
import { LoadingSpinner } from "../LoadingSpinner";
import filtersData from "../../../app/utils/filtersData";

const options = {
  maintainAspectRatio: false,
  animation: false,
  offset: true,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          min: 0,
        },
      },
    ],
  },
};

class DailyActive extends React.Component {
  state = {
    data: [],
    user: {},
    selected_alert: {},
    user: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  returnFilters() {
    const { filters } = this.props;

    let request_body = {
      target: "trend",
      order: "daily",
      filters,
    };

    return filtersData(request_body);
  }

  getData(isLoading) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_location_info",
      data: { ...request_body, isMoving: true },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        console.log(res.data);
        let group_by_data = {};

        for (let el of res.data) {
          const date = el._id;

          if (!group_by_data[date]) {
            group_by_data[date] = {};
          }

          group_by_data[date] = el;
        }

        const labels = Object.keys(group_by_data);
        let chart_data = [];

        for (let el of Object.keys(group_by_data)) {
          chart_data.push(group_by_data[el].count);
        }

        this.setState({
          isLoading: false,
          data: {
            labels,
            datasets: [
              {
                label: "Daily Active",
                data: chart_data,
                borderRadius: 15,
                backgroundColor: "#05c605",
                borderColor: "rgba(4, 199, 4,1)",
                barPercentage: 0.5,
              },
            ],
          },
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    return (
      <div className="chart-container">
        <div className="card">
          <div className="card-header">
            <h3>Active Vehicles Per Day</h3>
          </div>
          <div className="card-body" style={{ height: 280 }}>
            {this.state.isLoading ? (
              <LoadingSpinner />
            ) : (
              <Bar data={this.state.data} options={options} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return { filters };
};

export default connect(mapStateToProps)(DailyActive);

import React from "react";
import "./styles.css";
import QRCode from "react-qr-code";
import { Button } from "../../../app/components/Button";
import * as htmlToImage from "html-to-image";
import download from "downloadjs";

class GenerateQR extends React.Component {
  onDownload(data) {
    htmlToImage
      .toPng(document.getElementById("qr_container"))
      .then(function (dataUrl) {
        download(dataUrl, data + ".png");
      });
  }
  render() {
    return (
      <div>
        <div className="qr_generator_container" id="qr_container">
          <QRCode value={this.props.data} />
        </div>
        <center style={{ paddingBottom: 25 }}>
          <Button
            text="Print QR"
            className="btn-primary"
            onPress={() => this.onDownload(this.props.data)}
          />
        </center>
      </div>
    );
  }
}

export default GenerateQR;

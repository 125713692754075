import React from "react";
import { getStorage } from "../../../app/utils/storage";
import { ENDPOINT } from "../..//../app/constants/api";
import axios from "axios";
import { connect } from "react-redux";
import filtersData from "../../../app/utils/filtersData";
import Table from "../Table/Table";
import moment from "moment";
import Geocode from "react-geocode";
import { REACT_APP_MAPS_API_KEY } from "../../../app/constants/strings";
import getAddressName from "../../../app/utils/getAddressName";
import toastMessage from "../../../app/utils/toastMessage";

Geocode.setApiKey(REACT_APP_MAPS_API_KEY);

let copyData = [];

let midnight = moment().startOf("day").toString();
let now = moment().toString();

class TripLogs extends React.Component {
  state = {
    user: {},
    data: [],
    isLoading: true,
    fromDate: moment(midnight).unix() * 1000,
    toDate: moment(now).endOf("day").unix() * 1000,
    page: 1,
    limit: 10,
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.getTrips(true);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.getTrips(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  returnFilters() {
    const { filters, vehicles } = this.props;

    filters["vehicles"] = vehicles;

    let params = {
      target: "last",
      filters,
    };

    let request_body = filtersData(params);

    return request_body;
  }

  getTrips(isLoading) {
    const { user, fromDate, toDate } = this.state;

    this.setState({ isLoading });
    let filter_body = this.returnFilters();

    delete filter_body.target;

    filter_body.start_createdAt = fromDate;
    filter_body.end_createdAt = toDate;

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_location_info",
      data: filter_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then(async (res) => {
        let results = [];

        for (let el of res.data) {
          results.push({
            ...el,
            address: "Click to view",
          });
        }
        this.setState({
          data: results,
          isLoading: false,
        });

        copyData = res.data.slice(0);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  handleSearch(e) {
    const search_text = e.target.value;
    let array = [];

    this.setState({
      search_text,
    });

    for (let i = 0; i < copyData.length; i++) {
      if (
        JSON.stringify(copyData[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copyData[i]);
      }
    }

    this.setState({
      data: array,
    });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  onChangeText(name, e) {
    let { error } = this.state;

    delete error[name];

    this.setState({
      [name]: e.target.value,
      error,
    });
  }

  handleDisplayAddress = async (item) => {
    let { data } = this.state;

    const index = data.indexOf(item);

    if (index !== -1) {
      data[index]["address"] = "Please wait...";
      await Geocode.fromLatLng(item.latitude, item.longitude)
        .then((response) => {
          data[index]["address"] = getAddressName(response);
        })
        .catch((error) => {
          data[index]["address"] = "Click to view";

          toastMessage("error", "Error to get address name");
        });

      this.setState({ data });
    }
  };

  getDateRange(dates) {
    const now = moment().format("YYYY-MM-DD");

    this.setState(
      {
        fromDate: moment(dates.fromDate).unix() * 1000,
        toDate: moment(now + " " + dates.toDate).unix() * 1000,
      },
      () => {
        this.getTrips(true);
      }
    );
  }

  render() {
    let headers = [
      {
        title: "Number Plate",
        key: "number_plate",
      },
      {
        title: "Address",
        key: "address",
        type: "link",
        onPress: (item) => this.handleDisplayAddress(item),
      },
      {
        title: "Date",
        key: "date",
        isMoment: true,
        formatTime: "lll",
      },
    ];

    let dateRange =
      moment(this.state.fromDate).format("lll") +
      " - " +
      moment(this.state.toDate).format("LT");

    return (
      <div className="card" style={{ marginTop: "1rem" }}>
        <div className="card-body">
          <Table
            data={this.state.data}
            isSearch
            page={this.state.page}
            limit={this.state.limit}
            search_text={this.state.search_text}
            handleSearch={this.handleSearch.bind(this)}
            totalPageCount={this.state.totalPageCount}
            isLoading={this.state.isLoading}
            handlePagination={this.handlePagination.bind(this)}
            headers={headers}
            filters={[
              {
                type: "refresh",
                title: "Refresh",
                icon: "bx bx-refresh",
                onPress: () => this.getTrips(true),
              },
              {
                type: "datetime",
                title: "Filter by Date " + dateRange,
                button_type: "dropdown",
                icon: "bx bx-time",
                getDateRange: (dates) => this.getDateRange(dates),
                fromDate: moment(this.state.fromDate).format(
                  "YYYY-MM-DD HH:MM"
                ),
                toDate: moment(this.state.toDate).format("HH:mm"),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(TripLogs);

import React from "react";
import { Tabs } from "../components/Tabs";
import { Vehicles } from "../components/Vehicle";
import { Payments, Pricing } from "../components/ServiceFee";
import UnPaid from "../components/ServiceFee/UnPaid";
import PendingVehicles from "../components/Vehicle/PendingVehicles";

class VehicleScreen extends React.Component {
  renderVehicle() {
    return <Vehicles />;
  }

  renderServiceFee() {
    return <Pricing />;
  }

  renderPayment() {
    return <Payments />;
  }

  renderUnPaid() {
    return <UnPaid />;
  }

  renderPendingVehicles() {
    return <PendingVehicles />;
  }

  render() {
    return (
      <div>
        <div>
          <Tabs
            options={[
              {
                title: "Active Vehicles",
                data: this.renderVehicle(),
              },
              {
                title: "Pending Vehicles",
                data: this.renderPendingVehicles(),
              },
              {
                title: "Paid Service Fees",
                data: this.renderPayment(),
              },
              {
                title: "Unpaid Vehicles",
                data: this.renderUnPaid(),
              },
              {
                title: "Pricing",
                data: this.renderServiceFee(),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default VehicleScreen;

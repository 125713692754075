import React from "react";
import "./styles.css";
import { Checkbox, Input } from "../../../app/components/Input";
import { getStorage } from "../../../app/utils/storage";
import { LoadingSpinner } from "../LoadingSpinner";
import { onFilter } from "../../../app/action/Filters";
import rwanda from "../../constants/rwanda.json";
import { connect } from "react-redux";

let copyLocation = [];

class Location extends React.Component {
  state = {
    recent_location: [],
    selected_location: [],
    isLoading: false,
    provinces: [],
    districts: [],
    sectors: [],
    cells: [],
    villages: [],
    error: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    let sectors = [],
      cells = [];
    let provinces = Object.keys(rwanda);

    for (let p = 0; p < provinces.length; p++) {
      let districts = Object.keys(rwanda[provinces[p]]);

      for (let d = 0; d < districts.length; d++) {
        sectors.push(...Object.keys(rwanda[provinces[p]][districts[d]])); //= Object.keys(rwanda[provinces[p]][districts[d]]);

        // for (let s = 0; s < sectors.length; s++) {
        //   cells.push(
        //     ...Object.keys(rwanda[provinces[p]][districts[d]][sectors[s]])
        //   );
        // }
      }
    }

    copyLocation = sectors.slice(0);

    this.setState({
      recent_location: sectors,
      selected_location:
        this.props.filters && this.props.filters.locations
          ? this.props.filters.locations
          : [],
    });
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  handleCheck(location) {
    let selected_location = this.state.selected_location;

    let index = selected_location.indexOf(location);

    if (index !== -1) {
      selected_location.splice(index, 1);
    } else {
      selected_location.push(location);
    }

    // if (selected_location.length === 0) {
    //   this.props.dispatch(onFilter({ locations: [] }));
    // } else {
    this.setState(
      {
        selected_location,
      },
      () => {
        this.props.dispatch(onFilter({ locations: selected_location }));
      }
    );
    // }
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({ search_text });

    let array = [];

    for (let i = 0; i < copyLocation.length; i++) {
      if (
        copyLocation[i].toUpperCase().indexOf(search_text.toUpperCase()) !== -1
      ) {
        array.push(copyLocation[i]);
      }
    }

    this.setState({
      recent_location: array,
    });
  }

  onResetCheck() {
    this.props.dispatch(onFilter({ locations: [] }));

    this.setState({
      selected_location: [],
      search_text: "",
      recent_location: copyLocation,
    });
  }

  render() {
    return (
      // <form>
      <div className="card cdropdown-container">
        <div className="card-body">
          <Input
            placeholder="Search location..."
            className="form-control-sm"
            value={this.state.search_text}
            onChange={this.handleSearch.bind(this)}
          />
          {/* <Link to="#">Use current location</Link> */}
          {this.state.isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                className="cdropdown-content"
              >
                <h1 className="separator-title">Locations</h1>
                {this.props.filters &&
                  this.props.filters.locations &&
                  this.props.filters.locations.length > 0 && (
                    <a
                      href="#"
                      className="text-danger"
                      onClick={this.onResetCheck.bind(this)}
                    >
                      <b>{`Clear (${this.props.filters.locations.length})`}</b>
                    </a>
                  )}
              </div>
              {this.state.recent_location.map((item, i) => {
                return (
                  <div className="cdropdown-item" key={i}>
                    <Checkbox
                      name={item}
                      handleCheck={this.handleCheck.bind(this, item)}
                      checked={this.state.selected_location.includes(item)}
                    />
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
      // </form>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return {
    filters,
  };
};

export default connect(mapStateToProps)(Location);

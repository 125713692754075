import React from "react";
import AirImage from "../../../assets/air.png";
import Slide from "react-reveal/Slide";

const AirQuality = () => {
  return (
    <div className="section-container common-wrapper ">
      <div className="container d-lg-flex align-items-center justify-content-center ">
        <div className="col-md-6 flex-fill hidden-sm md-right-3 ">
          <div className="common-section common-img hidden-sm">
            <img src={AirImage} />
          </div>
        </div>
        <div className="col-md-6 flex-fill">
          <div className="description-section">
            <div>
              <Slide bottom>
                <h1>
                  <span className="primaryText">Air Quality </span>
                  <br />
                  Monitoring
                </h1>
                <p>
                  Our solution revolutionizes the way we understand and address
                  air
                  <br /> pollution. Through advanced sensing technology and data
                  analysis,
                  <br /> we provide real-time insights into air quality levels.
                  Empowering communities <br />
                  and organizations, we enable effective measures to improve air
                  quality,
                  <br /> safeguarding health and promoting a sustainable
                  environment.
                </p>
              </Slide>
            </div>
          </div>
        </div>
        <div className="col-md-6 flex-fill hidden-md ">
          <div className="common-section common-img hidden-md">
            <img src={AirImage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AirQuality;

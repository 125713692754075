import React from "react";
import { Button } from "../../../app/components/Button";
import { Input } from "../Input";

const DeleteOperator = (props) => {
  return (
    <div className="card">
      <div className="card-body">
        <Input
          type="password"
          label="Enter your password to confirm"
          required
          value={props.password}
          error={props.error}
          autocomplete="new-password"
          onChange={(e) => props.onChangePassword("delete_password", e)}
        />
        {props.withReason && (
          <Input
            label="Reason"
            required
            value={props.reason}
            error={props.error}
            onChange={(e) => props.onChangeText("delete_reason", e)}
          />
        )}
      </div>
      <div className="modal-footer">
        <Button
          text="Cancel"
          className="btn-default border"
          onPress={() => props.handleCloseModal()}
        />

        <Button
          text="Delete"
          className="btn-primary"
          onPress={() => props.onDelete()}
          isSubmitting={props.isDeleting}
        />
      </div>
    </div>
  );
};

export default DeleteOperator;

import React from "react";
import "./styles.css";
import { Checkbox, Input } from "../../../app/components/Input";
import axios from "axios";
import { ENDPOINT } from "../../../app/constants/api";
import toastMessage from "../../../app/utils/toastMessage";
import { getStorage } from "../../../app/utils/storage";
import { LoadingSpinner } from "../LoadingSpinner";
import { onFilter } from "../../../app/action/Filters";
import { connect } from "react-redux";

let copyData = [];

class Location extends React.Component {
  state = {
    recent_location: [],
    selected_location: [],
    isLoading: false,
    provinces: [],
    districts: [],
    sectors: [],
    cells: [],
    villages: [],
    error: {},
    isLoading: true,
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.setState({
      selected_location: this.props.filters.location || [],
    });
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState(
      {
        user,
      },
      () => {
        this.getData(true);
      }
    );
  };

  getData() {
    this.setState({
      isLoading: true,
    });

    const { user } = this.state;

    let { other } = this.props;

    let request_body = {
      tag: "all",
    };

    if (other && other.thingType) {
      request_body.type = other.thingType;
    }

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_thing_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((data) => {
        let locations = [];

        for (let el of data.data) {
          locations.push({
            label: el.address.geolocation,
            value: el.address.geolocation,
            thing_id: el._id,
            address: {
              geolocation: el.address.geolocation,
            },
          });
        }
        this.setState({
          isLoading: false,
          recent_location: locations,
        });

        copyData = data.data.slice(0);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage({ error, error });
      });
  }

  handleCheck(location) {
    let selected_location = this.state.selected_location;

    let index = selected_location.indexOf(location);

    if (index !== -1) {
      selected_location.splice(index, 1);
    } else {
      selected_location.push(location);
    }
    this.setState(
      {
        selected_location,
      },
      () => {
        this.props.dispatch(onFilter({ locations: selected_location }));
      }
    );
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({ search_text });

    let array = [];

    for (let i = 0; i < copyData.length; i++) {
      if (copyData[i].toUpperCase().indexOf(search_text.toUpperCase()) !== -1) {
        array.push(copyData[i]);
      }
    }

    this.setState({
      recent_location: array,
    });
  }

  onResetCheck() {
    this.props.dispatch(onFilter({ locations: [] }));

    this.setState({
      selected_location: [],
      search_text: "",
      recent_location: copyData,
    });
  }

  render() {
    return (
      <div className="card cdropdown-container">
        <div className="card-body">
          <Input
            placeholder="Search location..."
            className="form-control-sm"
            value={this.state.search_text}
            onChange={this.handleSearch.bind(this)}
          />
          {this.state.isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                className="cdropdown-content"
              >
                <h1 className="separator-title">Locations</h1>
                {this.props.filters &&
                  this.props.filters.locations &&
                  this.props.filters.locations.length > 0 && (
                    <a
                      href="#"
                      className="text-danger"
                      onClick={this.onResetCheck.bind(this)}
                    >
                      <b>{`Clear (${this.props.filters.locations.length})`}</b>
                    </a>
                  )}
              </div>
              {this.state.recent_location.map((item, i) => {
                return (
                  <div className="cdropdown-item" key={i}>
                    <Checkbox
                      name={item?.address?.geolocation}
                      handleCheck={this.handleCheck.bind(this, item)}
                      checked={this.state.selected_location.includes(item)}
                    />
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
      // </form>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return {
    filters,
  };
};

export default connect(mapStateToProps)(Location);

import React from "react";
import Alerts from "../components/Alerts/Alerts";

class AlertDeviceStateScreen extends React.Component {
  render() {
    return (
      <div>
        <Alerts
          status="resolved"
          type="device_state"
          bin_id={
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.bin_id
              ? this.props.match.params.bin_id
              : undefined
          }
        />
      </div>
    );
  }
}

export default AlertDeviceStateScreen;

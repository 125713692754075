import React from "react";
import { Checkbox } from "../../Input";

class LocationTypes extends React.Component {
  state = {
    show: false,
    error: {},
    isSubmitting: false,
  };

  handleCheck(field, value) {
    let { error } = this.state;

    delete error[field];
    this.setState({
      [field]: !this.state[field],
      error,
    });
  }

  validateForm() {
    let { error, distance } = this.state;

    if (!distance || !distance?.value) {
      error.distance = "Distance is mandatory";
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const { error, distance } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });
    }
  };

  render() {
    return (
      <>
        <div className="location-container">
          {this.props.types.map((el, i) => {
            let keyLabel = el.name.replaceAll().toLowerCase();
            return (
              <Checkbox
                key={i}
                name={el.name}
                handleCheck={(e) => this.handleCheck(keyLabel, e)}
                error={this.state[keyLabel]}
                checked={this.state[keyLabel]}
              />
            );
          })}
        </div>
      </>
    );
  }
}

export default LocationTypes;

import React from "react";
import Map from "../components/Map/Map";
import { Home } from "./common";
import { getStorage } from "../../app/utils/storage";
import socket from "../../app/utils/socketIO";
import { ENDPOINT } from "../../app/constants/api";
import axios from "axios";
import { connect } from "react-redux";
import filtersData from "../../app/utils/filtersData";

const position = [-1.9365986179180132, 30.155250107888524];

class WaterMapScreen extends React.Component {
  state = {
    user: {},
    water_level: [],
    position,
    vehicles: [],
    electricity_statuses: [],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.getWater(true);
    this.getVehicle(true);
    this.getElectricityStatus(true);

    socket.on("vehicles", () => {
      this.getVehicle(false);
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.getWater(true);
      this.getVehicle(true);
      this.getElectricityStatus(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getWater(isLoading) {
    const { user } = this.state;
    const { filters } = this.props;

    this.setState({
      isLoading,
    });

    let request_body = filtersData({ filters });

    request_body.target = "last";

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_water_level_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let response_data = res.data,
          water_level = [];

        for (let i = 0; i < response_data.length; i++) {
          response_data[i].last.address = response_data[i].thing[0].address;
          water_level.push({
            ...response_data[i].last,
            type: "water",
            data:
              response_data[i].thing && response_data[i].thing.length > 0
                ? response_data[i].thing[0]
                : {},
          });
        }

        this.setState({
          isLoading: false,
          water_level,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  getVehicle(isLoading) {
    const { user } = this.state;
    const { filters } = this.props;

    this.setState({
      isLoading,
    });

    let request_body = filtersData({ filters });

    request_body.target = "last";

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_vehicle_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let response_data = res.data,
          vehicles = [];

        for (let i = 0; i < response_data.length; i++) {
          response_data[i].last["address"] =
            response_data[i].last.thing[0].address;

          vehicles.push({
            ...response_data[i].last,
            type: "traffic",
            data:
              response_data[i].last.thing &&
              response_data[i].last.thing.length > 0
                ? response_data[i].last.thing[0]
                : {},
            location: {
              coordinates: [
                response_data[i].last["address"].lat,
                response_data[i].last["address"].long,
              ],
            },
            latitude: response_data[i].last["address"].lat,
            longitude: response_data[i].last["address"].long,
          });
        }

        this.setState({
          isLoading: false,
          vehicles,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  getElectricityStatus(isLoading) {
    const { user } = this.state;
    const { filters } = this.props;

    this.setState({
      isLoading,
    });

    let request_body = filtersData({ filters });

    request_body.target = "last";

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_electricity_status_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let response_data = res.data,
          electricity_statuses = [];

        for (let i = 0; i < response_data.length; i++) {
          response_data[i].last["address"] =
            response_data[i].last.thing[0].address;

          electricity_statuses.push({
            ...response_data[i].last,
            type: "power",
            data:
              response_data[i].last.thing &&
              response_data[i].last.thing.length > 0
                ? response_data[i].last.thing[0]
                : {},
            location: {
              coordinates: [
                response_data[i].last["address"].lat,
                response_data[i].last["address"].long,
              ],
            },
            latitude: response_data[i].last["address"].lat,
            longitude: response_data[i].last["address"].long,
          });
        }

        this.setState({
          isLoading: false,
          electricity_statuses,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const legend = {
      styles: {
        right: 0,
        top: 0,
        margin: 25,
      },
      items: [
        {
          color: "#fd0c0c",
          icon: "bx-droplet",
          name: "Low Water Level",
        },
        {
          color: "#000",
          icon: "bxs-droplet-half",
          name: "Mid Water Level",
        },
        {
          color: "#0530c6",
          icon: "bx bxs-droplet",
          name: "High Water Level",
        },
        {
          color: "#fd0c0c",
          icon: "bxl-tailwind-css",
          name: "Good air Quality",
        },
        {
          color: "#0530c6",
          icon: "bxl-tailwind-css",
          name: "Low Air Quality",
        },
        {
          color: "#fd0c0c",
          icon: "bxs-plug",
          name: "Partial-Power Coverage",
        },
        {
          color: "#0530c6",
          icon: "bxs-bolt",
          name: "Full-Power Coverage",
        },
        {
          color: "#1EC639",
          icon: "bxs-car",
          name: "Low Traffic",
        },
        {
          color: "#0530c6",
          icon: "bxs-car",
          name: "Mid Traffic",
        },
        {
          color: "#fd0c0c",
          icon: "bxs-car",
          name: "High Traffic",
        },
        {
          color: "transparent",
          icon: "bxs-trash",
          name: "N Grouped Thing",
          className: "n_group",
        },
      ],
    };

    return (
      <div>
        <Home isCountCard location date />
        <div style={{ position: "relative", marginTop: "1rem" }}>
          <Map
            isLoading={this.state.isLoading}
            legend={legend}
            data={[
              ...this.state.water_level,
              ...this.state.vehicles,
              ...this.state.electricity_statuses,
            ]}
            type="thing_level"
          />
        </div>{" "}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(WaterMapScreen);

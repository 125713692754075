import React from "react";
import { TopParkUserRevenues } from "../components/TopRevenues";
import PerUser from "../components/TopServiceFee/PerUser";

class RevenuesPerUserScreen extends React.Component {
  render() {
    return (
      <div>
        <PerUser all />
      </div>
    );
  }
}

export default RevenuesPerUserScreen;

import React from "react";
import Logo from "../../../assets/logo.png";
const Footer = () => {
  const socials = [
    {
      name: "Youtube",
      icon: "bxl-youtube",
      link: "",
    },
    {
      name: "Twitter",
      icon: "bxl-twitter",
      link: "",
    },
    {
      name: "Facebook",
      icon: "bxl-facebook",
      link: "",
    },
    {
      name: "Linkdin",
      icon: "bxl-linkedin",
      link: "https://www.linkedin.com/company/huzalabs/about/",
    },
  ];
  return (
    <div className="footer-wrapper">
      <div className="container">
        <div className="footer-left">
          <img src={Logo} />
        </div>
        <div className="footer-middle">
          {socials.map((item, i) => {
            return (
              <div className="social-item" key={i}>
                <a href={item.link} target="_blank">
                  <i className={`bx ${item.icon}`} />
                </a>
              </div>
            );
          })}
        </div>
        <div className="footer-right">
          <div className="powered">
            <span>
              Powered by{" "}
              <a href="https://www.huzalabs.com/" target="_blank">
                <span className="text-primary">Huza</span>Labs
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import { Button } from "../../../app/components/Button";
import { Input, Select } from "../../../app/components/Input";

import "./styles.css";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { getStorage } from "../../../app/utils/storage";
import { availables } from "../../../app/constants/strings";

class NewWebhook extends React.Component {
  state = {
    webhook_url: "",
    error: {},
    isSubmitting: false,
    user: {},
    _id: "",
    available: availables[0],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    if (this.props._id && this.props._id !== "") {
      this.setState({
        ...this.props,
        available: {
          label: this.props.available + "",
          value: this.props.available + "",
        },
      });
    }
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  onChangeText = async (name, e) => {
    let error = this.state.error;
    let value = e.target ? e.target.value : e;

    delete error[name];

    await this.setState({
      error,
      [name]: value,
    });
  };

  validateForm() {
    let { webhook_url, error } = this.state;

    if (webhook_url === "") {
      error.webhook_url = "Webhook webhook_url is required";
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const { webhook_url, user, _id, error, available } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let end_point = ENDPOINT + "/add_webhook_info";

      let data = {
        webhook_url,
        available: available.value,
      };

      if (_id !== "") {
        end_point = ENDPOINT + "/update_webhook_info";
        data.id = _id;
      }

      const options = {
        method: "POST",
        url: end_point,
        data,
        headers: {
          authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
            webhook_url: "",
          });

          toastMessage(
            "success",
            `Webhook webhook_url ${
              this.state._id !== "" ? "updated" : "created"
            } successfully`
          );

          this.props.handleCloseModal();
          this.props.getWebhook(true);
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("error", error);
        });
    }
  };

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                label="Webhook URL:"
                required
                className="form-control-lg"
                value={this.state.webhook_url}
                onChange={(e) => this.onChangeText("webhook_url", e)}
                error={this.state.error.webhook_url}
              />
            </div>
            <div className="col-md-12">
              <Select
                options={availables}
                label="Available:"
                className="form-control-lg "
                value={this.state.available}
                onChange={(e) => this.onChangeText("available", e)}
                error={this.state.error.available}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="card-footer d-flex align-content-center justify-content-end gap-3">
          <Button
            text="Close"
            className="btn-default btn-lg border"
            onPress={this.props.handleCloseModal}
          />
          <Button
            isSubmitting={this.state.isSubmitting}
            text="Submit"
            className="btn-primary btn-lg"
            onPress={this.onSubmit.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default NewWebhook;

import React from "react";
import "./styles.css";
import { getStorage } from "../../../app/utils/storage";
import axios from "axios";
import { ENDPOINT } from "../../../app/constants/api";
import toastMessage from "../../../app/utils/toastMessage";
import moment from "moment";
import handleFilterDates from "../../utils/handleFilterDates";
import { Input } from "../../../app/components/Input";
import { Tabs } from "../Tabs";
import GeneralInfo from "./GeneralInfo";
import UpdateInfo from "./UpdateInfo";
import { Button } from "../../../app/components/Button";

class VehicleInfo extends React.Component {
  state = {
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    data: [],
   
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    //set parent dates on load
    this.setState({
      ...this.props.dates,
    });

    // await this.getData(true);
  };
  
  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  renderImages = () => {
    return (
      <>
        <GeneralInfo {...this.props} />
      </>
    );
  };

  render() {
    return (
      <div className="vehicleInfoContainer">
        <div className="card">
          <div className="card-body">{this.renderImages()}</div>
        </div>
      </div>
    );
  }
}
export default VehicleInfo;

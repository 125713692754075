import moment from 'moment';

export default ({
  start_date,
  end_date,
  startDateType = 'start_createdAt',
  endDateType = 'end_createdAt',
}) => {
  let request_body = {};
  if (start_date && end_date) {
    if (start_date === end_date) {
      var midnight = moment().startOf('day').toString();
      var now = moment().toString();

      request_body[startDateType] = new Date(midnight).getTime();
      request_body[endDateType] = new Date(now).getTime();
    } else {
      request_body[startDateType] = new Date(start_date).getTime();
      request_body[endDateType] = new Date(end_date).getTime();
    }
  }

  return request_body;
};

import React from 'react';
import './styles.css';
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

class Circular extends React.Component {
  render() {
    const props = this.props;
    const {width = 60, height = 60} = this.props;
    return (
      <div className="circular" style={{width, height}}>
        <CircularProgressbar
          value={props.percentage}
          strokeWidth={props.strokeWidth ? props.strokeWidth : 50}
          styles={buildStyles({
            strokeLinecap: 'butt',
            pathColor: props.color || 'rgb(12, 151, 244)',
            textColor: 'rgb(12, 151, 244) ',
          })}
          text={props.showPercentageText && props.percentage + '%'}
        />
      </div>
    );
  }
}

export default Circular;

import axios from "axios";
import React from "react";
import { ENDPOINT } from "../../../app/constants/api";
import toastMessage from "../../../app/utils/toastMessage";
import "./styles.css";
import { getStorage } from "../../../app/utils/storage";
import { Button } from "../../../app/components/Button";
import { Select } from "../../../app/components/Input";
import formatSelectData from "../../../app/utils/formatSelectData";
import { LoadingSpinner } from "../LoadingSpinner";

class AssignGroupMember extends React.Component {
  state = {
    accounts: [],
    isLoading: true,
    user: {},
    page: 1,
    limit: 10,
    error: {},
    existed_assigned_accounts: [],
    existed_ref_account: [],
    existed_ref_subaccount: [],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.getAccounts(true);
    this.getGroupById(true);
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getGroupById(isLoading) {
    const { user } = this.state;
    const { _id } = this.props;

    this.setState({
      isLoading,
    });

    let body = {
      id: _id,
    };

    let url = ENDPOINT + "/get_access_group";

    const options = {
      method: "POST",
      url,
      data: {
        ...body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let data = res.data.length > 0 ? res.data[0] : {};

        let result = [];

        const ref_account = data.ref_account;
        const ref_subaccount = data.ref_subaccount;

        result = [...ref_account, ...ref_subaccount];

        this.setState({
          existed_assigned_accounts: result,
          isLoading: false,
          existed_ref_account: ref_account,
          existed_ref_subaccount: ref_subaccount,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  }

  getAccounts(isLoadingAccount) {
    const { user, page, limit } = this.state;

    this.setState({
      isLoadingAccount,
    });

    let body = {
      page,
      limit,
    };

    let url = "";

    if (user.account_type === "user_account") {
      url = ENDPOINT + "/get_user_subaccount";

      body.user_id = [user.id];
      body.ref_account = [user.id];
    }

    if (user.account_type === "admin_account") {
      url = ENDPOINT + "/get_user_account";
    }

    const options = {
      method: "POST",
      url,
      data: {
        ...body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let { data } = res.data;

        if (!data) {
          data = res.data;
        }

        let existed_members = [];

        for (let i = 0; i < this.state.existed_assigned_accounts.length; i++) {
          existed_members.push(this.state.existed_assigned_accounts[i]._id);
        }

        let non_members = [];
        for (let i = 0; i < data.length; i++) {
          if (!existed_members.includes(data[i]._id)) {
            non_members.push(data[i]);
          }
        }

        const accounts = formatSelectData(non_members, "email", "_id");

        this.setState({
          accounts,
          isLoadingAccount: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoadingAccount: false,
        });

        toastMessage("error", error);
      });
  }

  handleShowModal(modal, modalTitle, selected_account = {}) {
    this.setState({
      [modal]: true,
      modalTitle: selected_account.email
        ? selected_account.email + " 's account"
        : modalTitle,
      selected_account,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getAccounts(true);
      }
    );
  }

  onChangeText(name, e) {
    let { error } = this.state;

    delete error[name];

    this.setState({
      [name]: e.target ? e.target.value : e,
      error,
    });
  }

  validateForm() {
    let { error, new_member } = this.state;

    if (!new_member) {
      error.new_member = "Select member";
    }

    this.setState({
      error,
    });
  }

  handleAddMember = async (e) => {
    e.preventDefault();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isAdding: true,
      });

      let { user, new_member, existed_ref_account, existed_ref_subaccount } =
        this.state;
      let { _id } = this.props;

      let url = ENDPOINT + "/update_access_group";

      let data = {
        id: _id,
      };

      for (let i = 0; i < new_member.length; i++) {
        if (new_member[i].account_role === "admin") {
          existed_ref_account.push(new_member[i].value);
        } else {
          existed_ref_subaccount.push(new_member[i].value);
        }
      }

      data.ref_account = [...new Set(existed_ref_account)];
      data.ref_subaccount = [...new Set(existed_ref_subaccount)];

      const options = {
        method: "POST",
        url,
        data,
        headers: {
          authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isAdding: false,
            new_member: [],
          });

          toastMessage("success", `Member(s) added  successfully `);

          this.getGroupById(true);
          this.props.getGroups(true);
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("error", error);
        });
    }
  };

  handleDeleteMember = async (member) => {
    this.setState({
      isDeleting: true,
    });

    let { user, new_member, existed_ref_account, existed_ref_subaccount } =
      this.state;
    let { _id } = this.props;

    existed_ref_account.splice(existed_ref_account.indexOf(member._id), 1);
    existed_ref_subaccount.splice(
      existed_ref_subaccount.indexOf(member._id),
      1
    );

    let url = ENDPOINT + "/update_access_group";

    let data = {
      id: _id,
    };

    data.ref_account = [...new Set(existed_ref_account)];
    data.ref_subaccount = [...new Set(existed_ref_subaccount)];

    const options = {
      method: "POST",
      url,
      data,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((data) => {
        this.setState({
          isDeleting: false,
          new_member: [],
        });

        toastMessage("success", `Member deleted  successfully `);

        this.getGroupById(true);
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
        });

        toastMessage("error", error);
      });
  };

  render() {
    return (
      <div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {this.state.isLoading ? (
              <LoadingSpinner />
            ) : this.state.existed_assigned_accounts.length > 0 ? (
              this.state.existed_assigned_accounts.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.email}</td>
                    <td>
                      <Button
                        className="btn btn-sm btn-default"
                        icon="bx-trash"
                        onPress={this.handleDeleteMember.bind(this)}
                        isSubmitting={this.state.isDeleting}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td align="center" colSpan={2}>
                  No member
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td>
                <Select
                  defaultOptions={this.state.accounts}
                  label="Assign new member:"
                  className="form-control-lg "
                  value={this.state.new_member}
                  onChange={(e) => this.onChangeText("new_member", e)}
                  error={this.state.error.new_member}
                  async
                  isMulti
                  isLoading={this.state.isLoadingAccount}
                  onInputChange={(e) => this.onChangeText("search_account", e)}
                  loadOptions={(inputValue) =>
                    this.getAccounts(true, inputValue)
                  }
                />
              </td>

              <td>
                {this.state.new_member ? (
                  <div style={{ marginTop: 10 }}>
                    <Button
                      className="btn btn-sm btn-primary"
                      icon="bx-plus"
                      text="Add"
                      isSubmitting={this.state.isAdding}
                      onPress={this.handleAddMember.bind(this)}
                    />
                  </div>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}

export default AssignGroupMember;

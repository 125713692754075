import React from "react";
import { Button } from "../../../app/components/Button";
import { Input, Select } from "../../../app/components/Input";

import "./styles.css";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { getStorage } from "../../../app/utils/storage";
import { availables, currencies } from "../../../app/constants/strings";

class NewPrice extends React.Component {
  state = {
    password: "",
    error: {},
    isSubmitting: false,
    user: {},
    _id: "",
    currencies: {
      label: "RWF",
      value: "RWF",
    },
    currency: currencies[0],
    available: availables[0],
    name: "Parking",
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    if (this.props._id && this.props._id !== "") {
      let { price, other, available } = this.props;

      this.setState({
        ...this.props,
        amount: price,
        currency: {
          label: other?.currency,
          value: other?.currency,
        },
        available: {
          label: available + "",
          value: available + "",
        },
      });
    }
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  onChangeText = async (name, e) => {
    let error = this.state.error;
    let value = e.target ? e.target.value : e;

    delete error[name];

    await this.setState({
      error,
      [name]: value,
    });
  };

  validateForm() {
    let { error, amount, currency } = this.state;

    if (amount === "") {
      error.amount = "Amount is required";
    }

    if (!currency.label) {
      error.device = "Please select currency";
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const { user, _id, error, available, amount, currency, name } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let _currency;

      if (currency && currency.value) {
        _currency = currency.value;
      }

      let url = ENDPOINT + "/add_service_info";

      let data = {
        price: amount,
        other: {
          currency: _currency,
        },
        name,
        available: available.value,
        ticket_user_id: user.id,
      };

      if (_id !== "") {
        url = ENDPOINT + "/update_service_info";
        data.id = _id;
      }

      const options = {
        method: "POST",
        url,
        data,
        headers: {
          authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
            amount: "",
          });

          toastMessage(
            "success",
            `Data ${this.state._id !== "" ? "updated" : "created"} successfully`
          );

          this.props.handleCloseModal();
          this.props.getData(true);
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("error", error);
        });
    }
  };
  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Select
                defaultOptions={currencies}
                label="Currency:"
                className="form-control-lg "
                value={this.state.currency}
                onChange={(e) => this.onChangeText("currency", e)}
                error={this.state.error.device}
                required
              />
            </div>
            <div className="col-md-12">
              <Input
                label="Amount:"
                required
                className="form-control-lg"
                value={this.state.amount}
                onChange={(e) => this.onChangeText("amount", e)}
                error={this.state.error.amount}
                type="number"
              />
            </div>
            <div className="col-md-12">
              <Select
                options={availables}
                label="Available:"
                className="form-control-lg "
                value={this.state.available}
                onChange={(e) => this.onChangeText("available", e)}
                error={this.state.error.available}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="card-footer d-flex align-content-center justify-content-end gap-3">
          <Button
            text="Close"
            className="btn-default btn-lg border"
            onPress={this.props.handleCloseModal}
          />
          <Button
            isSubmitting={this.state.isSubmitting}
            text="Submit"
            className="btn-primary btn-lg"
            onPress={this.onSubmit.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default NewPrice;

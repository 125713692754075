import moment from 'moment';
import React from 'react';
import {
  SensorActivities,
  SensorMonitoring,
  SensorStatus,
} from '../components/Sensors';
import {Home} from './common';

class SensorActivitiesScreen extends React.Component {
  state = {
    fromDate: moment().add(30, 'd').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  };
  render() {
    return (
      <div>
        <Home date location sensor />
        <div style={{position: 'relative', marginTop: '1rem'}}>
          <div className="row mb-4">
            <div className="row mb-4">
              
              <div className="col-md-4">
                <SensorStatus type="Traffic" />
              </div>
              <div className="col-md-8 mb-4">
              <SensorMonitoring />
            </div>
            </div>
            <div className="col-md-12">
              <SensorActivities />
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-12">
              <SensorActivities />
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default SensorActivitiesScreen;

import React from "react";
import Table from "../../../app/components/Table/Table";
import "./styles.css";
import { getStorage } from "../../../app/utils/storage";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { Modal } from "../../../app/components/Modal";
import { StockInfo } from "../Stocks";
import { connect } from "react-redux";
import exportPDF from "../../../app/utils/exportPDF";
import { CSVLink } from "react-csv";
import { DeviceInfo } from "../Devices";
import { ProductInfo } from "../Product";
import moment from "moment";
import filtersData from "../../../app/utils/filtersData";
import { CompanyInfo } from "../Company";

let copyData = [];

class Tickets extends React.Component {
  state = {
    data: [],
    page: 1,
    limit: 10,
    selected_ticket: {},
    user: {},
    totalPageCount: 0,
    csvData: [],
    selected_vehicle: {},
    selected_device: {},
    selected_company: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  returnFilters() {
    const { page, limit } = this.state;
    const { filters } = this.props;

    let copyFilters = { ...filters };

    delete copyFilters.year;
    delete copyFilters.month;

    let request_body = {
      status: this.props.status,
      ticket_status: this.props.ticket_status,
      filters: copyFilters,
      page,
      limit,
      parkFilterType: "park_id",
      vehicleFilterType: "ticket_user_ids",
    };

    return filtersData(request_body);
  }

  getData(isLoading) {
    const { user, page, limit } = this.state;

    this.setState({
      isLoading,
    });

    let request_body = this.returnFilters();

    request_body.status = this.props.status;
    request_body.page = page;
    request_body.limit = limit;

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        console.log({ validated: res.data });

        const { data, count } = res.data;

        let on_time_data = [],
          late_data = [];

        for (let i = 0; i < data.length; i++) {
          if (data[i].ticket_status === "late") {
            late_data.push(data[i]);
          } else if (data[i].ticket_status === "ontime") {
            on_time_data.push(data[i]);
          }
        }

        const results =
          this.props.ticket_status === "ontime"
            ? on_time_data
            : this.props.ticket_status === "late"
            ? late_data
            : data;

        this.setState({
          data: results,
          isLoading: false,
          totalPageCount: count,
        });

        copyData = results.slice(0);
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleOpenModal(modal, item) {
    let selected_ticket = {},
      selected_vehicle = {},
      selected_device = {},
      modalTitle = "";

    if (modal === "showModal") {
      selected_ticket = item.park_id;
      modalTitle = item.park_id.park_id + " Info";
    }

    if (modal === "showDeviceModal") {
      selected_device = item.device_id;
      modalTitle = item.device_id.device_id + " Info";
    }

    if (modal === "showVehicleModal") {
      selected_vehicle = item.ticket_user_id;
      modalTitle = item.ticket_user_id.identifier + " Info";
    }

    this.setState({
      [modal]: true,
      selected_ticket,
      selected_device,
      selected_vehicle,
      modalTitle,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({ search_text });

    let array = [];

    for (let i = 0; i < copyData.length; i++) {
      if (
        JSON.stringify(copyData[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copyData[i]);
      }
    }

    this.setState({ data: array });
  }

  onViewCompanyInfo(ticket) {
    this.setState({
      showCompanyInfo: true,
      modalTitle: ticket?.ticket_user_id?.organization_id.name + "'s Info",
      selected_company: ticket?.ticket_user_id.organization_id,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: "Entity",
        key: "entity_id.entity_id",
        // type: "link",
        // onPress: this.handleOpenModal.bind(this, "showModal"),
      },
      {
        title: "Product",
        key: "service_id.name",
        // type: "link",
        // onPress: this.onViewCompanyInfo.bind(this),
      },
      {
        title: "Cost",
        key: "service_id.price",
        type: "objamount",
      },
      {
        title: "Device",
        key: "device_id.imei",
        type: "link",
        onPress: this.handleOpenModal.bind(this, "showDeviceModal"),
      },
      {
        title: "Amount Received",
        key: "price",
        type: "amount",
      },
      {
        title: "Created Date",
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },

      {
        title: "Last Update At",
        key: "updatedAt",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: "Printed By",
        key: "service_product_end_user_id.email",
      },
    ];

    for (let i = 0; i < headers.length; i++) {
      if (headers[i].key === "createdAt" && this.props.status === "served") {
        headers[i] = {
          title: "ChecK Out Time",
          key: "checkout_time",
          isMoment: true,
          formatTime: "lll",
        };
      }

      if (
        headers[i].key === "served_detection_level" &&
        this.props.status !== "served"
      ) {
        delete headers[i];
      }
    }

    return headers;
  }

  downloadExcel() {
    const { user } = this.state;

    this.setState({
      isLoading: true,
    });

    let request_body = this.returnFilters();

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        let results = [];

        for (let el of data) {
          results.push({
            Device: el?.device_id?.device_id,
            Company: el?.ticket_user_id?.organization_id?.name,
            Vehicle: el?.ticket_user_id?.identifier,
            Service: el?.service_id?.price,
            "Bus Park": el?.park_id?.park_id,
            printed_by: el?.account?.email,
          });
        }

        this.setState(
          {
            csvData: results,
            isLoading: false,
          },
          () => {
            this.refs.csvDownload?.link.click();
          }
        );
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  downloadPDF() {
    const headers = this.returnTableHeaders();

    const { user } = this.state;

    this.setState({
      isLoading: true,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_info",
      data: this.returnFilters(),
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoading: false,
        });

        exportPDF("Tickets", headers, data, "landscape");
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <Table
            data={this.state.data}
            no_bordered
            isSearch
            style={{ marginBottom: 0 }}
            totalPageCount={this.state.totalPageCount}
            page={this.state.page}
            search_text={this.state.search_text}
            handleSearch={this.handleSearch.bind(this)}
            limit={this.state.limit}
            isLoading={this.state.isLoading}
            handlePagination={this.handlePagination.bind(this)}
            headers={this.returnTableHeaders()}
            filters={[
              {
                type: "refresh",
                title: "Refresh",
                icon: "bx bx-refresh",
                onPress: () => this.getData(true),
              },
              {
                type: "export",
                title: "Export",
                button_type: "dropdown",
                icon: "bx bxs-download",
                options: [
                  {
                    name: "PDF",
                    onPress: this.downloadPDF.bind(this),
                  },
                  {
                    name: "CSV",
                    onPress: this.downloadExcel.bind(this),
                  },
                ],
              },
            ]}
          />
        </div>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          size="lg"
        >
          <StockInfo
            {...this.state.selected_ticket}
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showVehicleModal")}
          show={this.state.showVehicleModal}
          title={this.state.modalTitle}
          size="lg"
        >
          <ProductInfo
            {...this.state.selected_vehicle}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showVehicleModal"
            )}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeviceModal")}
          show={this.state.showDeviceModal}
          title={this.state.modalTitle}
          size="lg"
        >
          <DeviceInfo
            {...this.state.selected_device}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeviceModal"
            )}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showCompanyInfo")}
          show={this.state.showCompanyInfo}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <CompanyInfo {...this.state.selected_company} />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Tickets" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </div>
    );
  }
}

const mapStateProps = (state) => {
  const { filters } = state.Filters;
  const { notifications } = state.Notification;
  return {
    filters,
    notifications,
  };
};

export default connect(mapStateProps)(Tickets);

import React from "react";
import { Home } from "./common";
import { Tabs } from "../components/Tabs";
import { Tickets } from "../components/Tickets";

class CheckOutTicketScreen extends React.Component {
  renderAll() {
    return <Tickets status="validated" />;
  }

  renderServedOnTime() {
    return <Tickets status="validated" ticket_status="ontime" />;
  }

  renderServedLate() {
    return <Tickets status="validated" ticket_status="late" />;
  }

  render() {
    return (
      <div>
        <Home date location company vehicle paymentmethod />
        <div>
          <Tabs
            options={[
              {
                title: "Validated Tickets",
                data: this.renderAll(),
              },
              // {
              //   title: "Served on Time",
              //   data: this.renderServedOnTime(),
              // },
              // {
              //   title: "Served Late",
              //   data: this.renderServedLate(),
              // },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default CheckOutTicketScreen;

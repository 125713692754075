import React from "react";
import { Select } from "../../Input";

class Distance extends React.Component {
  state = {
    show: false,
    error: {},
    distances: [],
    start_location: "",
    isSubmitting: false,
    distance: {},
  };

  componentDidMount() {
    let { distances, defaultDistance } = this.props;

    let list = distances.map((el) => {
      return {
        label: el,
        value: el,
      };
    });

    this.setState({
      distances: list,
      distance: defaultDistance
        ? {
            label: defaultDistance,
            value: defaultDistance,
          }
        : undefined,
    });
  }

  onChangeText(field, value) {
    let { error } = this.state;

    delete error[field];
    this.setState({
      [field]: value,
      error,
    });
  }

  validateForm() {
    let { error, distance } = this.state;

    if (!distance || !distance?.value) {
      error.distance = "Distance is mandatory";
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const { error, distance } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });
    }
  };

  render() {
    return (
      <>
        <div className="distance-container">
          <label>Range(km)</label>
          <Select
            options={this.state.distances}
            error={this.state.error.distance}
            value={this.state.distance}
            onChange={(e) => this.onChangeText("distance", e)}
          />
        </div>
      </>
    );
  }
}

export default Distance;

import { Route, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import SummaryActivitiesScreen from "./screen/SummaryActivitiesScreen";
import AccountScreen from "./screen/AccountScreen";
import SensorActivitiesScreen from "./screen/SensorActivitiesScreen";
import CamerasMapScreen from "./screen/CamerasMapScreen";
import DeviceThingScreen from "./screen/DeviceThingScreen";
import PendingScheduleScreen from "./screen/PendingScheduleScreen";
import AlertSensorStateScreen from "./screen/AlertSensorStateScreen";
import ZoneStateAlert from "./screen/ZoneStateAlert";

import NotificationScreen from "./screen/NotificationScreen";
import VehicleRecognitionAnalytics from "./screen/VehicleRecognitionAnalytics";
import TicketsScreen from "./screen/TicketsScreen";
import ValidatedTicketsScreen from "./screen/ValidatedTicketsScreen";
import TrafficScreeningScreen from "./screen/TrafficScreeningScreen";
import TrafficStatusScreen from "./screen/TrafficStatusScreen";
import RejectedTicketsScreen from "./screen/RejectedTicketsScreen";
function App({ url = "/dashboard", appName = "zone_watch" }) {
  return (
    <Switch>
      <Route
        exact
        path={`${url}/${appName}/map_insights`}
        component={CamerasMapScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/traffic_summary`}
        component={SummaryActivitiesScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/traffic_insights`}
        component={VehicleRecognitionAnalytics}
      />
      <Route
        exact
        path={`${url}/${appName}/device_activities`}
        component={SensorActivitiesScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/issued_tickets`}
        component={TicketsScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/approved_tickets`}
        component={ValidatedTicketsScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/rejected_tickets`}
        component={RejectedTicketsScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/traffic_screening`}
        component={TrafficScreeningScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/traffic_status`}
        component={TrafficStatusScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/alerts/zone_state`}
        component={ZoneStateAlert}
      />
      <Route
        exact
        path={`${url}/${appName}/alerts/device_state`}
        component={AlertSensorStateScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/entity_devices`}
        component={DeviceThingScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/alerts/waste_bin levels/:bin_id`}
        component={AlertSensorStateScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/alerts/sensor_state`}
        component={AlertSensorStateScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/schedules/pending_schedules`}
        component={PendingScheduleScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/accounts`}
        component={AccountScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/notifications`}
        component={NotificationScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/notifications/id`}
        component={NotificationScreen}
      />
    </Switch>
  );
}

export default App;

import { Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

//screens
import WaterMapScreen from "./screen/WaterMapScreen";
import WaterActivitiesScreen from "./screen/WaterActivitiesScreen";
import AccountScreen from "./screen/AccountScreen";
import SensorActivitiesScreen from "./screen/SensorActivitiesScreen";
import WaterLevelScreen from "./screen/WaterLevelScreen";
import PendingScheduleScreen from "./screen/PendingScheduleScreen";
import AlertWaterThingLevelScreen from "./screen/AlertWaterThingLevelScreen";
import AlertSensorStateScreen from "./screen/AlertSensorStateScreen";
import IncidentWaterThingLevelScreen from "./screen/IncidentWaterThingLevelScreen";
import IncidentSensorStateScreen from "./screen/IncidentSensorStateScreen";
import DeviceThingScreen from "./screen/DeviceThingScreen";
import NotificationScreen from "./screen/NotificationScreen";
import WaterTankScreen from "./screen/WaterTankScreen";
import CheckedVehicleScreen from "./screen/CheckedVehicleScreen";
import VehicleRecognitionAnalytics from "./screen/VehicleRecognitionAnalytics";
import ElectricityFlowScreen from "./screen/ElectricityFlowScreen";
import ElectricityAnalyticsScreen from "./screen/ElectricityAnalyticsScreen";

function App({ url = "/dashboard", appName = "vision" }) {
  return (
    <>
      <Route
        exact
        path={`${url}/${appName}/map_insights`}
        component={WaterMapScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/Water_activities`}
        component={WaterActivitiesScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/analytics/sensor_activities`}
        component={SensorActivitiesScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/analytics/water_levels`}
        component={WaterLevelScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/alerts_&_incidents/alerts_levels`}
        component={AlertWaterThingLevelScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/alerts_&_incidents/Water_thing levels/:thing_id`}
        component={AlertWaterThingLevelScreen}
      />
      <Route
        exact
        path={`${url}/alerts_&_incidents/alerts_sensors`}
        component={AlertSensorStateScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/incidents/Water_thing levels`}
        component={IncidentWaterThingLevelScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/incidents/sensor_state`}
        component={IncidentSensorStateScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/schedules/pending_schedules`}
        component={PendingScheduleScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/dashboard/object_devices`}
        component={DeviceThingScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/dashboard/accounts`}
        component={AccountScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/dashboard/notifications`}
        component={NotificationScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/dashboard/notifications/id`}
        component={NotificationScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/dashboard/series_reports/water_levels`}
        component={WaterTankScreen}
      />
      <Route
        exact
        path={`/dashboard/series_reports/vehicle_recognition`}
        component={CheckedVehicleScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/dashboard/analytics/traffic_insights`}
        component={VehicleRecognitionAnalytics}
      />
      <Route
        exact
        path={`${url}/${appName}/dashboard/series_reports/electricity_flow`}
        component={ElectricityFlowScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/dashboard/analytics/electricity_flow`}
        component={ElectricityAnalyticsScreen}
      />
      <ToastContainer
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
      />
    </>
  );
}

export default App;

import React from "react";
import { PerUser } from "../components/TopRent";

class RentRevenuesPerUserScreen extends React.Component {
  render() {
    return (
      <div>
        <PerUser all />
      </div>
    );
  }
}

export default RentRevenuesPerUserScreen;

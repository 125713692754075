import React from 'react';
import {Popup} from 'react-leaflet';
import './styles.css';
import {Circular} from '../Progress';
import moment from 'moment';

const ThingPopup = props => {
  return (
    <Popup>
      <div className="bin-popup">
        <div className="left">
          {props.data.type === 'traffic' ? (
            <a href={props.data.vehicle_identity.plate_image} target="_blank">
              <img
                src={props.data.vehicle_identity.plate_image}
                style={{
                  width: 80,
                  height: 60,
                  borderRadius: 8,
                  marginBottom: 15,
                }}
              />
            </a>
          ) : (
            <Circular
              percentage={
                props.data.level_percentage
                  ? props.data.level_percentage
                  : props.data.level
              }
              strokeWidth={10}
              showPercentageText
              color={props.color}
            />
          )}
        </div>

        {props.data.type === 'traffic' ? (
          <div className="right">
            <div className="item">
              <span>
                Object ID: <b>{props.data.data.thing_id}</b>
              </span>
            </div>
            <div className="item">
              <span>
                Scans: <b>{props.data.level}</b>
              </span>
            </div>
            <div className="item">
              <span>
                Plate: <b>{props.data.vehicle_identity.plate}</b>
              </span>
            </div>
            <div className="item">
              <span>
                Type: <b>{props.data.vehicle_identity.type}</b>
              </span>
            </div>
            <div className="item">
              <span>
                Plate Color: <b>{props.data.vehicle_identity.plate_color}</b>
              </span>
            </div>
            <div className="item">
              <span>
                Location:{' '}
                <b>
                  {props.data.data &&
                    props.data.data.address &&
                    props.data.data.address.geolocation}
                </b>
              </span>
            </div>
            <div className="item">
              <span>
                Type: <b className="text-primary">{props.data.data.type}</b>
              </span>
            </div>
            <div className="item">
              <span>
                Last updated on:
                <b>{moment(props.data.updatedAt).format('lll')}</b>
              </span>
            </div>
            <hr />
            {/* <Link
            to={`/dashboard/alerts/Water_thing levels/${props.data._id}`}
            className="btn btn-bordered radius20 text-primary">
            View Alerts
          </Link> */}
          </div>
        ) : (
          <div className="right">
            <div className="item">
              <span>
                Object ID: <b>{props.data.data.thing_id}</b>
              </span>
            </div>
            <div className="item">
              <span>
                IMEI: <b>{props.data.imei}</b>
              </span>
            </div>
            <div className="item">
              <span>
                Serial number: <b>{props.data.serial_number}</b>
              </span>
            </div>
            <div className="item">
              <span>
                Location:{' '}
                <b>
                  {props.data.data &&
                    props.data.data.address &&
                    props.data.data.address.geolocation}
                </b>
              </span>
            </div>
            <div className="item">
              <span>
                Type: <b className="text-primary">{props.data.data.type}</b>
              </span>
            </div>
            <div className="item">
              <span>
                Last updated on:
                <b>{moment(props.data.updatedAt).format('lll')}</b>
              </span>
            </div>
            <hr />
            {/* <Link
            to={`/dashboard/alerts/Water_thing levels/${props.data._id}`}
            className="btn btn-bordered radius20 text-primary">
            View Alerts
          </Link> */}
          </div>
        )}
      </div>
    </Popup>
  );
};

export default ThingPopup;

import React from 'react';
import {Alerts} from '../components/Alerts';

class IncidentWaterThingLevelScreen extends React.Component {
  render() {
    return (
      <div>
        <Alerts status="resolved" type="thing_level" />
      </div>
    );
  }
}

export default IncidentWaterThingLevelScreen;

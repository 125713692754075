import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { ENDPOINT } from "../../../app/constants/api";
import { getStorage } from "../../../app/utils/storage";
import toastMessage from "../../../app/utils/toastMessage";
import { LoadingSpinner } from "../LoadingSpinner";
import { Line } from "react-chartjs-2";
import { colors, days, months } from "../../../app/constants/strings";
import filtersData from "../../../app/utils/filtersData";
import { CardCount } from "../../../app/components/CardCount";

const options = {
  legend: {
    position: "bottom",
    display: false,
  },
  maintainAspectRatio: false,
  responsive: true,
};

class Analytics extends React.Component {
  state = {
    user: {},
    chart: {
      hourly_trends: [],
      weekly_trends: [],
      monthly_trends: [],
    },
    total_vehicles: 0,
    total_morning_checkins: 0,
    total_evening_checkins: 0,
    total_afternoon_checkins: 0,
  };

  componentDidMount = async () => {
    const user = await getStorage();

    await this.setState({ user });

    this.getVehicles(true);
    this.getHourly(true);
    this.getWeekly(true);
    this.getMDaily(true);
    this.getMonthly(true);
  };

  componentDidUpdate = async (prevProps) => {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getHourly(true);
      this.getWeekly(true);
      this.getMDaily(true);
      this.getMonthly(true);
      this.getVehicles(true);
    }
  };

  returnFilters() {
    const { filters } = this.props;

    let request_body = filtersData({ filters });

    return request_body;
  }

  getVehicles(isLoadingVehicle) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    this.setState({
      isLoadingVehicle,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_vehicle_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let data = res.data;
        this.setState({
          isLoadingVehicle: false,
          total_vehicles: data.length,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingVehicle: false });
      });
  }

  getWeekly(isLoadingWeekly) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    request_body.tag = "trend";
    request_body.order = "day_of_week";

    this.setState({
      isLoadingWeekly,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_vehicle_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let data = res.data;

        let chart_data = [],
          labels = [],
          outputArray = this.returnOutputArray({ list: days, data });

        for (let i = 0; i < outputArray.length; i++) {
          labels.push(days[outputArray[i]._id]);

          chart_data.push(Math.round(outputArray[i].count));
        }

        this.state.chart.weekly_trends = {
          labels: labels,
          datasets: [
            {
              label: "Total number per week",
              data: chart_data,
              backgroundColor: colors(0.1),
              borderColor: colors(1),
              radius: 5,
            },
          ],
        };

        this.setState({
          isLoadingWeekly: false,
          chart: this.state.chart,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingWeekly: false });
      });
  }

  getMDaily(isLoadingDaily) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    request_body.tag = "trend";
    request_body.order = "daily";

    this.setState({
      isLoadingDaily,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_vehicle_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res.data;

        let chart_data = [],
          labels = [];

        for (let i = 0; i < data.length; i++) {
          labels.push(data[i]._id);

          chart_data.push(Math.round(data[i].count));
        }

        this.state.chart.daily_trends = {
          labels: labels,
          datasets: [
            {
              label: "Total number per day",
              data: chart_data,
              backgroundColor: colors(0.1),
              borderColor: colors(1),
              radius: 5,
            },
          ],
        };

        this.setState({
          isLoadingDaily: false,
          chart: this.state.chart,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingDaily: false });
      });
  }

  getHourly(isLoadingHourly) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    request_body.tag = "trend";
    request_body.order = "hourly";

    this.setState({
      isLoadingHourly,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_vehicle_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res.data;

        let chart_data = [],
          labels = [],
          total_morning_checkins = 0,
          total_afternoon_checkins = 0,
          total_evening_checkins = 0;

        for (let i = 0; i < data.length; i++) {
          labels.push(data[i]._id);

          let id = data[i]._id;
          let count = data[i].count;

          chart_data.push(count);

          //set morning
          if (id >= 4 && id < 12) {
            total_morning_checkins += count;
          }

          //set afternoon
          if (id >= 12 && id < 18) {
            total_afternoon_checkins += count;
          }

          //set evening
          if (id >= 18 && (id < 24 || (id >= 0 && id < 3))) {
            total_evening_checkins += count;
          }
        }

        let customLabels = labels.map((label, index) => `${label}h`);

        this.state.chart.hourly_trends = {
          labels: customLabels,
          datasets: [
            {
              label: "Total number per hour",
              data: chart_data,
              backgroundColor: colors(0.1),
              borderColor: colors(1),
              radius: 5,
            },
          ],
        };

        this.setState({
          isLoadingHourly: false,
          chart: this.state.chart,
          total_morning_checkins,
          total_evening_checkins,
          total_afternoon_checkins,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingHourly: false });
      });
  }

  getMonthly(isLoadingMonthly) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    request_body.tag = "trend";
    request_body.order = "monthly";

    this.setState({
      isLoadingMonthly,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_vehicle_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res.data;

        let chart_data = [],
          labels = [],
          outputArray = this.returnOutputArray({ list: months, data });

        for (let i = 0; i < outputArray.length; i++) {
          labels.push(months[outputArray[i]._id]);

          chart_data.push(Math.round(outputArray[i].count));
        }

        this.state.chart.monthly_trends = {
          labels: labels,
          datasets: [
            {
              label: "Total number per Month",
              data: chart_data,
              backgroundColor: colors(0.1),
              borderColor: colors(1),
              radius: 5,
            },
          ],
        };

        this.setState({
          isLoadingMonthly: false,
          chart: this.state.chart,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingMonthly: false });
      });
  }

  handlePressCount(route) {
    this.setState({ route });
  }

  returnOutputArray({ list, data }) {
    return Object.keys(list).map((listItem) => {
      const foundObject = data.find(
        (obj) => Number(obj._id) === Number(listItem)
      );
      return {
        _id: listItem,
        count: foundObject ? foundObject.count : 0,
      };
    });
  }

  returnChartList(data) {
    return (
      <ul className="chart-list">
        {data &&
          data.map((item, i) => {
            let title = item.split(":");
            return (
              <li key={i}>
                <div style={{ flex: 1, marginRight: 20 }}>
                  <span className="title">{title[0]}</span>
                </div>

                <span className="number">{title[1]}</span>
              </li>
            );
          })}
      </ul>
    );
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-6 col-md-3">
            <CardCount
              title="Total Vehicles"
              total={
                this.state.isLoadingHourly
                  ? "..."
                  : `${Math.round(
                      this.state.total_morning_checkins +
                        this.state.total_afternoon_checkins +
                        this.state.total_evening_checkins
                    )}`
              }
              icon="bxs-car"
            />
          </div>
          <div className="col-6 col-md-3">
            <CardCount
              title="Morning Checkins (4am-12pm)"
              total={
                this.state.isLoadingHourly
                  ? "..."
                  : `${Math.round(this.state.total_morning_checkins)}`
              }
              icon="bxs-time-five"
            />
          </div>
          <div className="col-6 col-md-3">
            <CardCount
              title="Afternoon Checkins (12pm-6pm)"
              total={
                this.state.isLoadingHourly
                  ? "..."
                  : this.state.total_afternoon_checkins
              }
              icon="bxs-sun"
            />
          </div>
          <div className="col-6 col-md-3">
            <CardCount
              title="Nights Checkins (6pm-4am)"
              total={
                this.state.isLoadingHourly
                  ? "..."
                  : this.state.total_evening_checkins
              }
              icon="bxs-moon"
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-md-6">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Hourly Vehicle CheckIns</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingHourly ? (
                  <LoadingSpinner />
                ) : (
                  <Line
                    data={this.state.chart.hourly_trends}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Daily Vehicle CheckIns</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingDaily ? (
                  <LoadingSpinner />
                ) : (
                  <Line
                    data={this.state.chart.daily_trends}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-md-6">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Weekly Vehicle CheckIns</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingWeekly ? (
                  <LoadingSpinner />
                ) : (
                  <Line
                    data={this.state.chart.weekly_trends}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      bezierCurve: true,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Monthly Vehicle CheckIns</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingMonthly ? (
                  <LoadingSpinner />
                ) : (
                  <Line
                    data={this.state.chart.monthly_trends}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      bezierCurve: true,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(Analytics);

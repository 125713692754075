export const menus = [
  {
    group: "Home",
    icon: "bx-home",
    route: "/dashboard/NAME_OF_APPLICATION/",
  },
  {
    group: "Analytics",
    icon: "bxs-dashboard",
    items: [
      {
        name: "Water Levels",
        route: "/dashboard/NAME_OF_APPLICATION/analytics/Water_activities",
        group: "Analytics",
      },
      {
        name: "Traffic Insights",
        route: "/dashboard/NAME_OF_APPLICATION/analytics/Water_activities",
        group: "Analytics",
      },
      {
        name: "Electricity Flow",
        route: "/dashboard/NAME_OF_APPLICATION/analytics/Water_activities",
        group: "Analytics",
      },
      {
        name: "Air Quality",
        route: "/dashboard/NAME_OF_APPLICATION/analytics/Water_activities",
        group: "Analytics",
      },
      {
        name: "Sensor Activities",
        route: "/dashboard/NAME_OF_APPLICATION/analytics/sensor_activities",
        group: "Analytics",
      },
    ],

    show: true,
  },
  {
    group: "Series Reports",
    icon: "bx-grid",
    items: [
      {
        name: "Water Levels",
        route: "/dashboard/NAME_OF_APPLICATION/series_reports/Water_levels",
        group: "Series Reports",
      },
      {
        name: "Vehicle Recognition",
        route:
          "/dashboard/NAME_OF_APPLICATION/series_reports/check_in-checkout",
        group: "Series Reports",
      },
      {
        name: "Electricity Flow",
        route:
          "/dashboard/NAME_OF_APPLICATION/series_reports/electricity_cut-off",
        group: "Series Reports",
      },
      {
        name: "Air Quality",
        route: "/dashboard/NAME_OF_APPLICATION/series_reports/air_quality",
        group: "Series Reports",
      },
    ],
    show: true,
  },
  {
    group: "Alerts & Incidents",
    icon: "bx-error",
    items: [
      {
        name: "Alerts Levels",
        route: "/dashboard/NAME_OF_APPLICATION/alerts/alerts_levels",
        group: "Alerts",
      },
      {
        name: "Alerts Sensors",
        route: "/dashboard/NAME_OF_APPLICATION/alerts/alerts_sensors",
        group: "Alerts",
      },
    ],
    show: true,
  },
  {
    group: "Objects & Devices",
    route: "/dashboard/NAME_OF_APPLICATION/object_devices",
    icon: "bxs-cylinder",
    role: "admin",
  },
  {
    group: "Accounts",
    route: "/dashboard/NAME_OF_APPLICATION/accounts",
    icon: "bx-group",
  },
];

import React from "react";
import { Bar } from "react-chartjs-2";
import { LoadingSpinner } from "../LoadingSpinner";
import "./styles.css";
import { connect } from "react-redux";
import { ENDPOINT } from "../../../app/constants/api";
import { getStorage } from "../../../app/utils/storage";
import toastMessage from "../../../app/utils/toastMessage";
import axios from "axios";

class DeviceMonitoring extends React.Component {
  state = {
    data: [],
    user: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getData(isLoading) {
    const { user } = this.state;

    this.setState({
      isLoading,
    });

    let request_body = {
      target: "all",
      ...this.returnFilters(),
    };

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_device_trend_status_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let data = res.data;

        let chart_data = [],
          labels = [];

        for (let i = 0; i < data.length; i++) {
          labels.push(data[i].down_date);
          chart_data.push(data[i].down_percentage);
        }

        this.setState({
          isLoading: false,
          data: {
            labels,
            datasets: [
              {
                label: "Offline Devices (%)",
                data: chart_data,
                backgroundColor: "#f02",
                borderColor: "rgba(0,0,0,1)",
              },
            ],
          },
        });
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  returnFilters() {
    const { filters } = this.props;

    let request_body = {};

    if (filters && filters.start_date && filters && filters.end_date) {
      request_body.start_date = new Date(filters.start_date).getTime();
      request_body.end_date = new Date(filters.end_date).getTime();
    }
    return request_body;
  }

  render() {
    return (
      <div className="card Device-monitoring">
        <div className="card-header">
          <h3>Device Monitoring</h3>
        </div>
        <div className="card-body">
          {this.state.isLoading ? (
            <LoadingSpinner />
          ) : (
            <Bar
              data={this.state.data}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                  xAxes: [
                    {
                      gridLines: {
                        display: false,
                      },
                    },
                  ],
                },
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(DeviceMonitoring);

import React from "react";
import "./styles.css";
import { Checkbox, Input } from "../../../app/components/Input";
import axios from "axios";
import { ENDPOINT } from "../../../app/constants/api";
import toastMessage from "../../../app/utils/toastMessage";
import { getStorage } from "../../../app/utils/storage";
import { LoadingSpinner } from "../LoadingSpinner";
import { onFilter } from "../../../app/action/Filters";
import { connect } from "react-redux";
import formatSelectData from "../../../app/utils/formatSelectData";

let copyData = [];

class entity extends React.Component {
  state = {
    recent_entity: [],
    selected_entity: {},
    isLoading: false,
    provinces: [],
    districts: [],
    sectors: [],
    cells: [],
    villages: [],
    error: {},
    limit: 1000,
    page: 1,
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.getParks(true);

    // let sectors = [],
    //   cells = [];
    // let provinces = Object.keys(rwanda);

    // for (let p = 0; p < provinces.length; p++) {
    //   let districts = Object.keys(rwanda[provinces[p]]);

    //   for (let d = 0; d < districts.length; d++) {
    //     sectors.push(...Object.keys(rwanda[provinces[p]][districts[d]])); //= Object.keys(rwanda[provinces[p]][districts[d]]);

    //     // for (let s = 0; s < sectors.length; s++) {
    //     //   cells.push(
    //     //     ...Object.keys(rwanda[provinces[p]][districts[d]][sectors[s]])
    //     //   );
    //     // }

    // copyData = sectors.slice(0);

    // this.setState({
    //   recent_entity: sectors,
    //   selected_entity:
    //     this.props.filters && this.props.filters.entitys
    //       ? this.props.filters.entitys
    //       : [],
    // });
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getParks(isLoading) {
    const { user, page, limit } = this.state;

    this.setState({
      isLoading,
    });

    let body = {
      page,
      limit,
    };

    let url = ENDPOINT + "/get_entity_info";

    const options = {
      method: "POST",
      url,
      data: {
        ...body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let { data } = res.data;

        let recent_entity = formatSelectData(data, "entity_id", "_id");

        this.setState({
          isLoading: false,
          recent_entity,
        });

        if (data.length !== 0) {
          copyData = recent_entity.slice(0);
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  }

  handleCheck(entity) {
    this.setState(
      {
        selected_entity: entity,
      },
      () => {
        this.props.dispatch(onFilter({ entity: entity }));
      }
    );
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({ search_text });

    let array = [];

    for (let i = 0; i < copyData.length; i++) {
      if (copyData[i].toUpperCase().indexOf(search_text.toUpperCase()) !== -1) {
        array.push(copyData[i]);
      }
    }

    this.setState({
      recent_entity: array,
    });
  }

  onResetCheck() {
    this.props.dispatch(onFilter({ entitys: [] }));

    this.setState({
      selected_entity: {},
      search_text: "",
      recent_entity: copyData,
    });
  }

  render() {
    return (
      // <form>
      <div className="card cdropdown-container">
        <div className="card-body">
          <Input
            placeholder="Search entity..."
            className="form-control-sm"
            value={this.state.search_text}
            onChange={this.handleSearch.bind(this)}
          />
          {/* <Link to="#">Use current entity</Link> */}
          {this.state.isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                className="cdropdown-content"
              >
                <h1 className="separator-title">Entities</h1>
                {this.props.filters &&
                  this.props.filters.entitys &&
                  this.props.filters.entitys.value && (
                    <a
                      href="#"
                      className="text-danger"
                      onClick={this.onResetCheck.bind(this)}
                    >
                      <b>{`Clear `}</b>
                    </a>
                  )}
              </div>
              {this.state.recent_entity.map((item, i) => {
                let checked = false;

                if (this.props.filters.entitys?.value === item.value) {
                  checked = true;
                }

                return (
                  <div className="cdropdown-item" key={i}>
                    <Checkbox
                      name={item.label}
                      handleCheck={this.handleCheck.bind(this, item)}
                      checked={checked}
                    />
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
      // </form>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return {
    filters,
  };
};

export default connect(mapStateToProps)(entity);

import React from "react";
import Logo from "../../../assets/logo.png";
import { Button } from "../../Button";

const Navbar = () => {
  return (
    <nav className="navbar-wrapper sticky navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <a className="navbar-brand" href="#">
          <img src={Logo} />
          <div className="appName">
            <span>
              Huza<b>Lytics</b>
            </span>
          </div>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a href="#home" className="nav-link active" aria-current="page">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a href="#features" className="nav-link">
                Features
              </a>
            </li>
            <li className="nav-item">
              <a href="#get-started" className="nav-link">
                Get Started
              </a>
            </li>
            <li className="nav-item">
              <Button
                text="Sign In"
                className="btn-primary"
                onPress={() => (window.location.href = "/login")}
              />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

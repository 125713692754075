import React from "react";
import { Popup } from "react-leaflet";
import "./styles.css";
import { Circular } from "../Progress";
import moment from "moment";
import { Link } from "react-router-dom";

const parkPopup = (props) => {
  return (
    <Popup>
      <div className="park-popup">
        <div className="left">
          {/* <Circular
            percentage={
              props.data.level_percentage ? props.data.level_percentage : 0
            }
            strokeWidth={10}
            showPercentageText
          /> */}
        </div>
        <div className="right">
          <div className="item">
            <span>
              Bus Park: <b>{props.data.park.park_id}</b>
            </span>
          </div>
          <div className="item">
            <span>
              Issued Tickets: <b>{props.data.in_level}</b>
            </span>
          </div>
          <div className="item">
            <span>
              Validated Tickets: <b>{props.data.out_level}</b>
            </span>
          </div>
          {props.data.serial_number && props.data.serial_number !== "" && (
            <div className="item">
              <span>
                Serial number: <b>{props.data.serial_number}</b>
              </span>
            </div>
          )}
          {props.data.imei && props.data.imei !== "" && (
            <div className="item">
              <span>
                Device IMEI: <b>{props.data.imei}</b>
              </span>
            </div>
          )}
          <div className="item">
            <span>
              Location:{" "}
              <b>
                {props.data.park &&
                  props.data.park.address &&
                  props.data.park.address.geolocation}
              </b>
            </span>
          </div>
          <div className="item">
            <span>
              Created At:
              <b> {moment(props.data.updatedAt).format("lll")}</b>
            </span>
          </div>
          <hr />
          {/* <Link
            to={`/dashboard/alerts/waste_park levels/${props.data._id}`}
            className="btn btn-bordered radius20 text-primary"
          >
            View Alerts
          </Link> */}
        </div>
      </div>
    </Popup>
  );
};

export default parkPopup;

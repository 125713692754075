import React from "react";
import toggleMenu from "../utils/toggleMenu";

class MenuDropdown extends React.Component {
  render() {
    const { parentRef, menu, index, pathname } = this.props;

    return (
      <div
        className="menu-item-header"
        onClick={() => menu.items && toggleMenu(parentRef, index)}
      >
        <h1
          className={
            pathname.includes(menu.group.toLowerCase().replaceAll(" ", "_"))
              ? "text-primary"
              : ""
          }
        >
          <i className={`bx ${menu.icon} left-icon`} />
          {menu.group}
        </h1>
        {menu.items && (
          <div className="menu-header-right-icon">
            <i className="bx bx-chevron-down" />
          </div>
        )}
      </div>
    );
  }
}

export default MenuDropdown;

import React from "react";
import "./styles.css";
import { Checkbox, Input } from "../../../app/components/Input";
import axios from "axios";
import { ENDPOINT } from "../../../app/constants/api";
import toastMessage from "../../../app/utils/toastMessage";
import { getStorage } from "../../../app/utils/storage";
import { LoadingSpinner } from "../LoadingSpinner";
import { onFilter } from "../../../app/action/Filters";
import { connect } from "react-redux";
import formatSelectData from "./../../../app/utils/formatSelectData";

let copyData = [];

class Thing extends React.Component {
  state = {
    data: [],
    selected_thing: [],
    error: {},
    isLoading: true,
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.setState({
      selected_thing: this.props.filters.thing || [],
    });
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState(
      {
        user,
      },
      () => {
        this.getData(true);
      }
    );
  };

  getData() {
    this.setState({
      isLoading: true,
    });

    const { user } = this.state;

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_thing_info",
      data: { tag: "all" },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((data) => {
        let formattedSelect = formatSelectData(data.data, "thing_id", "_id");
        this.setState({
          isLoading: false,
          data: formattedSelect,
        });

        console.log("====================================");
        console.log(formattedSelect);
        console.log("====================================");

        copyData = formattedSelect.slice(0);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        console.log(error);

        toastMessage("Failed to fetch thing");
      });
  }

  handleCheck(item) {
    let { selected_thing } = this.state;

    let index = selected_thing.findIndex((el) => el.value === item.value);

    if (index !== -1) {
      selected_thing.splice(index, 1);
    } else {
      selected_thing.push(item);
    }

    this.setState(
      {
        selected_thing,
      },
      () => {
        this.props.dispatch(onFilter({ thing: selected_thing }));
      }
    );
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({ search_text });

    let array = [];

    for (let i = 0; i < copyData.length; i++) {
      if (copyData[i].toUpperCase().indexOf(search_text.toUpperCase()) !== -1) {
        array.push(copyData[i]);
      }
    }

    this.setState({
      recent_location: array,
    });
  }

  onResetCheck() {
    this.props.dispatch(onFilter({ locations: [] }));

    this.setState({
      selected_thing: [],
      search_text: "",
      data: copyData,
    });
  }

  render() {
    return (
      // <form>
      <div className="card cdropdown-container">
        <div className="card-body">
          <Input
            placeholder="Search location..."
            className="form-control-sm"
            value={this.state.search_text}
            onChange={this.handleSearch.bind(this)}
          />
          {this.state.isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                className="cdropdown-content"
              >
                <h1 className="separator-title">Things</h1>
                {this.props.filters &&
                  this.props.filters.thing &&
                  this.props.filters.thing.length > 0 && (
                    <a
                      href="#"
                      className="text-danger"
                      onClick={this.onResetCheck.bind(this)}
                    >
                      <b>{`Clear (${this.props.filters.thing.length})`}</b>
                    </a>
                  )}
              </div>
              {this.state.data.map((item, i) => {
                return (
                  <div className="cdropdown-item" key={i}>
                    <Checkbox
                      name={item.label}
                      handleCheck={this.handleCheck.bind(this, item)}
                      checked={this.state.selected_thing.includes(item)}
                    />
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
      // </form>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return {
    filters,
  };
};

export default connect(mapStateToProps)(Thing);

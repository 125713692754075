import { onToggle } from "../../../action/ToggleSidebar";

export default (that, event) => {
  const { innerWidth } = window;

  if (
    that.refs.sidebar &&
    !that.refs.sidebar.contains(event.target) &&
    innerWidth < 750
  ) {
    that.props.dispatch(onToggle(false));
  }
};

import React from "react";
import Table from "../../../app/components/Table/Table";
import "./styles.css";
import { getStorage } from "../../../app/utils/storage";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { Modal } from "../../../app/components/Modal";
import { ThingInfo } from "../Thing";
import { connect } from "react-redux";

class ScheduleInfo extends React.Component {
  state = {
    data: [],
    selected_thing: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getData(isLoading) {
    const { user } = this.state;

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_schedule_info",
      data: {
        id: this.props.id,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res.data;

        this.setState({
          data,
          isLoading: false,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  handleOpenModal(modal, selected_thing) {
    this.setState({
      [modal]: true,
      selected_thing: selected_thing.thing_id ? selected_thing.thing_id : {},
      modalTitle: selected_thing.thing_id
        ? "Thing #" + selected_thing.thing_id.thing_id
        : "",
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <Table
            data={this.state.data}
            no_bordered
            isLoading={this.state.isLoading}
            headers={[
              {
                title: "Level",
                key: "level",
                type: "level",
              },
              {
                title: "Created At",
                key: "createdAt",
                isMoment: true,
                formatTime: "lll",
              },
              {
                title: "Schedule Date",
                key: "schedule_date",
                isMoment: true,
                formatTime: "lll",
              },
              {
                title: "Location",
                key: "thing_id.address.geolocation",
              },
              {
                title: "Object ID",
                key: "thing_id.thing_id",
                type: "link",
                onPress: this.handleOpenModal.bind(this, "showModal"),
              },
              {
                title: "Schedule Status",
                key: "schedule_status",
              },
            ]}
          />
        </div>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          size="lg"
        >
          <ThingInfo
            {...this.state.selected_thing}
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
          />
        </Modal>
      </div>
    );
  }
}

export default ScheduleInfo;

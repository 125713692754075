import moment from "moment";
import React from "react";
import {
  DailyActive,
  DailyStationary,
  AverageSpeedPerDay,
} from "../components/Chart";
import { MaxSpeed } from "../components/MaxSpeed";
import { LowAverageSpeed } from "../components/LowAverageSpeed";
import ServiceFeePerUser from "../components/TopServiceFee/PerUser";
import ServiceFeePerPark from "../components/TopServiceFee/PerPark";
import RentPerPark from "../components/TopRent/PerPark";
import RentPerUser from "../components/TopRent/PerUser";

import { Home } from "./common";
import { HighAverageSpeed } from "../components/HighAverageSpeed";

class SummaryActivitiesScreen extends React.Component {
  state = {
    fromDate: moment().add(30, "d").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
  };
  render() {
    return (
      <div>
        <Home isCountCard date location company month year vehicle />

        <div style={{ position: "relative", marginTop: "1rem" }}>
          <div className="row mb-4">
            <div className="col-md-12">
              <DailyActive />
            </div>
            <div className="col-md-6" style={{ marginTop: 15 }}>
              <DailyStationary />
            </div>
            <div className="col-md-6" style={{ marginTop: 15 }}>
              <AverageSpeedPerDay />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-6">
              <MaxSpeed styles={{ height: 340 }} />
            </div>
            <div className="col-md-6">
              <HighAverageSpeed styles={{ height: 340 }} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SummaryActivitiesScreen;

import { Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

//screens
import ServiceFeeRevenuesPerParkScreen from ".//screen/ServiceFeeRevenuesPerParkScreen";
import ServiceFeeRevenuesPerUserScreen from ".//screen/ServiceFeeRevenuesPerUserScreen";
import AccountScreen from ".//screen/AccountScreen";
import DeviceActivitiesScreen from ".//screen/DeviceActivitiesScreen";
import AlertParkLevelScreen from ".//screen/AlertParkLevelScreen";
import AlertDeviceStateScreen from ".//screen/AlertDeviceStateScreen";
import IncidentWasteBinLevelScreen from ".//screen/IncidentWasteBinLevelScreen";
import IncidentDeviceStateScreen from ".//screen/IncidentDeviceStateScreen";
import DeviceParkScreen from ".//screen/DeviceParkScreen";
import NotificationScreen from ".//screen/NotificationScreen";
import CheckInTicketScreen from ".//screen/CheckInTicketScreen";
import CheckOutTicketScreen from ".//screen/CheckOutTicketScreen";
import ParkLevelScreen from ".//screen/ParkLevelScreen";
import ParkMapScreen from ".//screen/ParkMapScreen";
import PricingScreen from ".//screen/PricingScreen";
import ServiceScreen from ".//screen/ServiceScreen";
import VehicleScreen from ".//screen/VehicleScreen";
import CompanyScreen from ".//screen/CompanyScreen";
import ParkActivitiesScreen from ".//screen/ParkActivitiesScreen";
import HighestRevenueScreen from ".//screen/HighestRevenueScreen";
import CompanyTicketScreen from ".//screen/CompanyTicketScreen";
import RevenuesPerUserScreen from ".//screen/RevenuesPerUserScreen";
import RentRevenuesPerParkScreen from ".//screen/RentRevenuesPerParkScreen";
import RentRevenuesPerUserScreen from ".//screen/RentRevenuesPerUserScreen";

function App({ url }) {
  return (
    <>
      <Route
        exact
        path={`${url}/Logistics%20Management/map_insights`}
        component={ParkMapScreen}
      />
      <Route
        exact
        path={`${url}/Logistics%20Management/summary_activities`}
        component={ParkActivitiesScreen}
      />
      <Route
        exact
        path={`${url}/Logistics%20Management/analytics/device_activities`}
        component={DeviceActivitiesScreen}
      />
      <Route
        exact
        path={`${url}/Logistics%20Management/analytics/revenues_trends`}
        component={ParkLevelScreen}
      />
      <Route
        exact
        path={`${url}/Logistics%20Management/tickets/validated_tickets`}
        component={CheckOutTicketScreen}
      />
      <Route
        exact
        path={`${url}/Logistics%20Management/alerts/park_levels`}
        component={AlertParkLevelScreen}
      />
      <Route
        exact
        path={`${url}/Logistics%20Management/alerts/park_levels/:bin_id`}
        component={AlertParkLevelScreen}
      />
      <Route
        exact
        path={`${url}/Logistics%20Management/alerts/device_state`}
        component={AlertDeviceStateScreen}
      />
      <Route
        exact
        path={`${url}/Logistics%20Management/incidents/park_levels`}
        component={IncidentWasteBinLevelScreen}
      />
      <Route
        exact
        path={`${url}/Logistics%20Management/incidents/device_state`}
        component={IncidentDeviceStateScreen}
      />
      <Route
        exact
        path={`${url}/Logistics%20Management/tickets/issued_tickets`}
        component={CheckInTicketScreen}
      />
      <Route
        exact
        path={`/Logistics%20Management/parks_&_devices`}
        component={DeviceParkScreen}
      />
      <Route
        exact
        path={`/Logistics%20Management/accounts`}
        component={AccountScreen}
      />
      <Route
        exact
        path={`/Logistics%20Management/notifications`}
        component={NotificationScreen}
      />
      <Route
        exact
        path={`/Logistics%20Management/notifications/id`}
        component={NotificationScreen}
      />
      <Route
        exact
        path={`/Logistics%20Management/finance/pricing`}
        component={PricingScreen}
      />
      <Route
        exact
        path={`/Logistics%20Management/services`}
        component={ServiceScreen}
      />
      <Route
        exact
        path={`/Logistics%20Management/vehicles`}
        component={VehicleScreen}
      />
      <Route
        exact
        path={`/Logistics%20Management/companies`}
        component={CompanyScreen}
      />

      <Route
        exact
        path="/Logistics%20Management/highest_revenues"
        component={HighestRevenueScreen}
      />

      <Route
        exact
        path="/Logistics%20Management/company_ticket"
        component={CompanyTicketScreen}
      />

      <Route
        exact
        path="/Logistics%20Management/revenues_park_per_user"
        component={RevenuesPerUserScreen}
      />

      <Route
        exact
        path="/Logistics%20Management/servicefee_revenues_per_user"
        component={ServiceFeeRevenuesPerUserScreen}
      />

      <Route
        exact
        path="/Logistics%20Management/servicefee_revenues_per_park"
        component={ServiceFeeRevenuesPerParkScreen}
      />

      <Route
        exact
        path="/Logistics%20Management/rent_revenues_per_park"
        component={RentRevenuesPerParkScreen}
      />

      <Route
        exact
        path="/Logistics%20Management/rent_revenues_per_user"
        component={RentRevenuesPerUserScreen}
      />
      <ToastContainer
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
      />
    </>
  );
}

export default App;

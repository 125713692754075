import React from "react";
import { Trips } from "../components/Trips";

class TripScreen extends React.Component {
  render() {
    return <Trips />;
  }
}

export default TripScreen;

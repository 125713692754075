import React from "react";
import { Trips } from "../components/reports";
import { Tabs } from "../components/Tabs";

class TripReportScreen extends React.Component {
  render() {
    return (
      <div className="card">
        <Tabs
          options={[
            {
              title: "Moving",
              data: <Trips is_moving={true} />,
            },
            {
              title: "Stationary",
              data: <Trips is_moving={false} />,
            },
          ]}
        />
      </div>
    );
  }
}

export default TripReportScreen;

import React from 'react';
import Alerts from '../components/Alerts/Alerts';

class AlertWaterThingLevelScreen extends React.Component {
  render() {
    return (
      <div>
        <Alerts
          status="unresolved"
          type="thing_level"
          thing_id={
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.thing_id
              ? this.props.match.params.thing_id
              : undefined
          }
        />
      </div>
    );
  }
}

export default AlertWaterThingLevelScreen;

import React from "react";
import { Analytics } from "../components/Analytics";
import { Home } from "./common";
import { Tabs } from "../components/Tabs";
import TripLogScreen from "./TripLogScreen";

class TripRevenueScreen extends React.Component {
  renderRevenues() {
    return <Analytics />;
  }

  renderHistoricalLocation() {
    return <TripLogScreen />;
  }
  render() {
    return (
      <div>
        <Home isCountCard location date company mapinsight vehicle />
        <div style={{ marginTop: "1rem" }}>
          <Analytics />
        </div>
      </div>
    );
  }
}

export default TripRevenueScreen;

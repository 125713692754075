import axios from "axios";
import React from "react";
import { ENDPOINT } from "../../../app/constants/api";
import toastMessage from "../../../app/utils/toastMessage";
import Table from "../../../app/components/Table/Table";
import "./styles.css";
import { getStorage } from "../../../app/utils/storage";
import { Home } from "../../screen/common";
import { connect } from "react-redux";
import exportPDF from "../../../app/utils/exportPDF";
import { CSVLink } from "react-csv";
import { Modal } from "../../../app/components/Modal";
import { ParkInfo } from "../Parks";
import { RentInfo } from "../Service";
import { CompanyInfo } from "../Company";
import filtersData from "../../../app/utils/filtersData";

let copyData = [];

class Payments extends React.Component {
  state = {
    data: [],
    isLoading: true,
    user: {},
    page: 1,
    limit: 10,
    error: {},
    csvData: [],
    selected_park: {},
    selected_company: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.getData(true);
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  returnFilters = () => {
    const { page, limit } = this.state;
    const { filters } = this.props;

    let copyFilters = { ...filters };

    delete copyFilters.start_date;
    delete copyFilters.end_date;

    let request_body = {
      category: "rent",
      page,
      limit,
      filters: copyFilters,
      parkFilterType: "reference_park_id",
      tag: "monthly",
    };

    return filtersData(request_body);
  };

  getData(isLoading) {
    const { user } = this.state;

    this.setState({
      isLoading,
    });

    let url = ENDPOINT + "/get_service_payment_info";

    const options = {
      method: "POST",
      url,
      data: this.returnFilters(),
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let { data, count } = res.data;

        if (!data) {
          data = res.data;
        }
        this.setState({
          data,
          isLoading: false,
          totalPageCount: count,
        });

        if (data.length !== 0) {
          copyData = data.slice(0);
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleSearch(e) {
    const search_text = e.target.value;
    let array = [];

    this.setState({
      search_text,
    });

    for (let i = 0; i < copyData.length; i++) {
      if (
        JSON.stringify(copyData[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copyData[i]);
      }
    }

    this.setState({
      data: array,
    });
  }

  downloadExcel = () => {
    const { user } = this.state;

    this.setState({
      isLoading: true,
    });

    let url = ENDPOINT + "/get_service_payment_info";

    const options = {
      method: "POST",
      url,
      data: this.returnFilters(),
      headers: {
        authorization: "Bearer " + user.token,
      },
    };
    axios(options)
      .then((res) => {
        let { data } = res.data;

        let csvData = [];

        for (let el of data) {
          delete el.__v;
          delete el._id;
          delete el.updatedAt;

          csvData.push({
            ...el,
          });
        }
        this.setState(
          {
            isLoading: false,
            csvData: data,
          },
          () => {
            this.refs.csvDownload?.link.click();
          }
        );
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  };

  downloadPDF = () => {
    const headers = this.returnTableHeaders();

    const { user } = this.state;

    this.setState({
      isLoading: true,
    });

    let url = ENDPOINT + "/get_service_payment_info";

    const options = {
      method: "POST",
      url,
      data: this.returnFilters(),
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let { data } = res.data;

        this.setState({
          isLoading: false,
        });

        exportPDF("Payments-Rent-Service", headers, data, "landscape");
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  };

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onViewParkInfo(rent) {
    this.setState({
      showParkInfo: true,
      modalTitle: rent.park_id?.park_id + "'s Info",
      selected_park: rent.park_id,
    });
  }

  onViewServiceInfo(rent) {
    this.setState({
      showServiceInfo: true,
      modalTitle: "Service Info",
      selected_service: rent.service_id,
    });
  }

  onViewCompanyInfo(vehicle) {
    this.setState({
      showCompanyInfo: true,
      modalTitle: vehicle?.organization_id.name + "'s Info",
      selected_company: vehicle.organization_id,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: "Service",
        key: "service_id.other.name",
        type: "link",
        onPress: this.onViewServiceInfo.bind(this),
      },
      {
        title: "Target Price",
        key: "service_id.price",
        type: "objamount",
      },
      {
        title: "Paid Amount",
        key: "price",
      },
      {
        title: "Payment Method",
        key: "payment_method",
      },
      {
        title: "Bank Slip",
        key: "proof_number",
      },
      {
        title: "Tenant",
        key: "organization_id.name",
        type: "link",
        onPress: this.onViewCompanyInfo.bind(this),
      },

      {
        title: "Bus Park",
        key: "reference_park_id.park_id",
        type: "link",
        onPress: this.onViewParkInfo.bind(this),
      },
      {
        title: "Contact",
        key: "other.payer",
      },
      {
        title: "Month",
        key: "month_paid_for.month",
      },
      {
        title: "Year",
        key: "month_paid_for.year",
      },
      {
        title: "Issued At",
        key: "park_id.park_id",
        type: "link",
        onPress: this.onViewParkInfo.bind(this),
      },
      {
        title: "Printed By",
        key: "account.email",
      },
      {
        title: "Created At",
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
    ];

    return headers;
  }

  render() {
    return (
      <div>
        <Home month year location company paymentmethod />
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={this.handleSearch.bind(this)}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          filters={[
            {
              type: "refresh",
              title: "Refresh",
              icon: "bx bx-refresh",
              onPress: () => this.getData(true),
            },
            {
              type: "export",
              title: "Export",
              button_type: "dropdown",
              icon: "bx bxs-download",
              options: [
                {
                  name: "PDF",
                  onPress: this.downloadPDF.bind(this),
                },
                {
                  name: "CSV",
                  onPress: this.downloadExcel.bind(this),
                },
              ],
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showParkInfo")}
          show={this.state.showParkInfo}
          title={this.state.modalTitle}
          size="lg"
        >
          <ParkInfo
            {...this.state.selected_park}
            handleCloseModal={this.handleCloseModal.bind(this, "showParkInfo")}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showServiceInfo")}
          show={this.state.showServiceInfo}
          title={this.state.modalTitle}
          size="lg"
        >
          <RentInfo
            data={this.state.selected_service}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showServiceInfo"
            )}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showCompanyInfo")}
          show={this.state.showCompanyInfo}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <CompanyInfo {...this.state.selected_company} />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"House Rents" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(Payments);

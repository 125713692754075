import React from "react";
import { Button } from "../../Button";
import IntroImg from "../../../assets/intro.png";
import Slide from "react-reveal/Slide";

const Footer = () => {
  return (
    <div className="section-container introduction-wrapper">
      <div className="container d-lg-flex align-items-center justify-content-center ">
        <div className="col-md-6 flex-fill">
          <div className="description-section">
            <div>
              <Slide bottom>
                <h1 className="textWhite">
                  AI Full Monitoring <br /> for
                  <span className="primaryText">
                    {" "}
                    Smart Cities <br /> & E-services
                  </span>
                  .
                </h1>
                <p className="textWhite">
                  Embrace the game-changing impact of AI-powered <br />{" "}
                  monitoring and analytics with our Huzalytics solution.
                  <br /> Unleash cutting-edge technology to optimize operations
                  <br />
                  and gain invaluable insights. Achieve seamless precision
                  <br /> and elevated success with Smart-cities and E-services.
                </p>
              </Slide>
              <div className="intro-btns">
                <Slide bottom>
                  <div className="intro-btn">
                    <Button
                      text="Sign In"
                      className="btn-primary"
                      onPress={() => (window.location.href = "/login")}
                    />
                  </div>
                </Slide>
                <Slide bottom>
                  <div className="intro-btn">
                    <Button
                      text="Contact US"
                      className="btn-bordered"
                      onPress={() => (window.location.href = "#contactUS")}
                    />
                  </div>
                </Slide>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 flex-fill">
          <div className="description-section common-img">
            <img src={IntroImg} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

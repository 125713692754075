import React from "react";
import { Things } from "../components/Thing";
import { Collectors } from "../components/Collector";
import Devices from "../components/Devices/Devices";
import { Tabs } from "../components/Tabs";
import { getStorage } from "../../app/utils/storage";

class DeviceThingcreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  renderThing() {
    return (
      <div className="card-body">
        <Things />
      </div>
    );
  }

  renderDevice() {
    return (
      <div className="card-body">
        <Devices />
      </div>
    );
  }

  renderCollector() {
    return (
      <div className="card-body">
        <Collectors />
      </div>
    );
  }

  render() {
    return (
      <div className="card">
        <Tabs
          options={[
            {
              title: "Objects",
              data: this.renderThing(),
            },
            {
              title: "Devices",
              data: this.renderDevice(),
            },
            {
              title: "Collection-Admins",
              data: this.renderCollector(),
            },
          ]}
        />
      </div>
    );
  }
}

export default DeviceThingcreen;

import React from "react";
import { Button } from "../../../app/components/Button";
import { Input, Select } from "../../../app/components/Input";

import "./styles.css";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { getStorage } from "../../../app/utils/storage";
import formatSelectData from "../../../app/utils/formatSelectData";
import { statuses } from "../../../app/constants/strings";

class NewEmployee extends React.Component {
  state = {
    password: "",
    error: {},
    isSubmitting: false,
    user: {},
    _id: "",
    name: "",
    identifier: "",
    clients: [],
    status: statuses[0],
    entities: [],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();
    await this.getEntities(true);
    await this.getClients(true);

    if (this.props._id && this.props._id !== "") {
      let _entity = {},
        _client = {},
        status = {
          label: this.props.status,
          value: this.props.value,
        };

      for (let client of this.state.clients) {
        if (client._id === this.props?.client_id?._id) {
          _client = {
            label: client.name,
            value: client._id,
          };
        }
      }

      for (let entity of this.state.entities) {
        if (entity._id === this.props?.client_id?._id) {
          _entity = {
            label: entity.entity_id,
            value: entity._id,
          };
        }
      }

      this.setState({
        ...this.props,
        entity: _entity,
        client: _client,
        status,
      });
    }
  };

  getEntities(isFetchingEntities, search_entity) {
    this.setState({
      isFetchingEntities,
    });

    const { user } = this.state;

    let body = {
      page: 1,
      limit: 10,
    };

    if (search_entity && search_entity !== "") {
      body.search = search_entity;
    }

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_entity_info",
      data: {
        ...body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options).then((res) => {
      const entities = formatSelectData(res.data.data, "entity_id", "_id");

      this.setState({
        entities,
        isFetchingEntities: false,
      });

      return entities;
    });
  }

  getClients(isFetchingClients, search_client) {
    this.setState({
      isFetchingClients,
    });

    const { user } = this.state;

    let body = {
      page: 1,
      limit: 10000,
    };

    if (search_client && search_client !== "") {
      body.search = search_client;
    }

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_client_info",
      data: {
        ...body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options).then((res) => {
      const clients = formatSelectData(res.data.data, "name", "_id");

      this.setState({
        clients,
        isFetchingClients: false,
      });

      return clients;
    });
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  onChangeText = async (name, e) => {
    let error = this.state.error;
    let value = e.target ? e.target.value : e;

    delete error[name];

    await this.setState({
      error,
      [name]: value,
    });
  };

  validateForm() {
    let { error, identifier, client, entity } = this.state;

    if (identifier === "") {
      error.identifier = "Identifier is required";
    }

    if (!client || client?.value === "") {
      error.client = "Select client";
    }

    if (!entity || entity?.value === "") {
      error.entity = "Select entity";
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const { user, _id, error, identifier, client, entity, status } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let url = ENDPOINT + "/add_service_product_enduser_info";

      let data = {
        identifier,
        status: status.value,
        entity_id: entity.value,
        client_id: client.value,
        ref_org_account: user.id,
      };

      if (_id !== "") {
        url = ENDPOINT + "/update_service_product_enduser_info";
        data.id = _id;
      }

      const options = {
        method: "POST",
        url,
        data,
        headers: {
          authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
            name: "",
          });

          toastMessage(
            "success",
            `Data ${this.state._id !== "" ? "updated" : "created"} successfully`
          );

          this.props.handleCloseModal();
          this.props.getData(true);
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("error", error);
        });
    }
  };

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                label="Identifier:"
                required
                className="form-control-lg"
                value={this.state.identifier}
                onChange={(e) => this.onChangeText("identifier", e)}
                error={this.state.error.identifier}
              />
            </div>
            <div className="col-md-12">
              <Select
                defaultOptions={this.state.entities}
                label="Entity:"
                className="form-control-lg "
                value={this.state.entity}
                onChange={(e) => this.onChangeText("entity", e)}
                error={this.state.error.entity}
                async
                isLoading={this.state.isFetchingEntities}
                onInputChange={(e) => this.onChangeText("search_entity", e)}
                loadOptions={(inputValue) => this.getEntities(true, inputValue)}
              />
            </div>
            <div className="col-md-12">
              <Select
                defaultOptions={this.state.clients}
                label="Client:"
                className="form-control-lg "
                value={this.state.client}
                onChange={(e) => this.onChangeText("client", e)}
                error={this.state.error.client}
                async
                isLoading={this.state.isFetchingClients}
                onInputChange={(e) => this.onChangeText("search_entity", e)}
                loadOptions={(inputValue) => this.getClients(true, inputValue)}
              />
            </div>
            <div className="col-md-12">
              <Select
                options={statuses}
                label="Status:"
                className="form-control-lg "
                value={this.state.status}
                onChange={(e) => this.onChangeText("status", e)}
                error={this.state.error.status}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="card-footer d-flex align-content-center justify-content-end gap-3">
          <Button
            text="Close"
            className="btn-default btn-lg border"
            onPress={this.props.handleCloseModal}
          />
          <Button
            isSubmitting={this.state.isSubmitting}
            text="Submit"
            className="btn-primary btn-lg"
            onPress={this.onSubmit.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default NewEmployee;

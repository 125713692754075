import React from "react";
import { Alerts } from "../components/Alerts";

class IncidentEntityLevelScreen extends React.Component {
  render() {
    return (
      <div>
        <Alerts status="resolved" type="entity_level" />
      </div>
    );
  }
}

export default IncidentEntityLevelScreen;

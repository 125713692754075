import React from "react";
import CDRImage from "../../../assets/cdr.png";
import Slide from "react-reveal/Slide";

const CDRFeature = () => {
  return (
    <div id="features" className="section-container common-wrapper ">
      <div className="container d-lg-flex align-items-center justify-content-center ">
        <div className="col-md-6 flex-fill hidden-sm md-right-3 ">
          <div className="common-section common-img hidden-sm">
            <img src={CDRImage} />
          </div>
        </div>
        <div className="col-md-6 flex-fill">
          <div className="description-section">
            <div>
              <Slide bottom>
                <h1>
                  <span className="primaryText">Mobility Analytics</span> <br />
                  for Smart Cities.
                </h1>
                <p>
                  With anonymized mobility analytics for smart cities
                  empowering,
                  <br />
                  we revolutionize urban environments. Our onsite and hybrid
                  <br />
                  solutions counter crime, monitor disease outbreaks, analyze
                  <br />
                  mobility patterns and optimize network infrastructure.
                </p>
              </Slide>
            </div>
          </div>
        </div>
        <div className="col-md-6 flex-fill hidden-md ">
          <div className="common-section common-img hidden-md">
            <img src={CDRImage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CDRFeature;

import React from "react";
import { Input, Switch } from "../../../app/components/Input";

import "./style.css";
import { getStorage } from "../../../app/utils/storage";
import { onToggleAllNotification } from "../../../app/action/Notification";
import { connect } from "react-redux";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";

class General extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({
      user,
    });
  };

  handleNotificationToggle = (e) => {
    const { user } = this.state;
    this.props.dispatch(onToggleAllNotification(e.target.checked));

    const options = {
      method: "POST",
      url: ENDPOINT + "/update_user_account",
      data: {
        id: user.id,
        notification: e.target.checked,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        toastMessage("error", error);
      });
  };

  render() {
    return (
      <div className="row">
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-6">
              <Input
                label="Email Address"
                required
                className="form-control-lg"
                value={this.state.user.username}
                disabled
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Role"
                required
                disabled
                className="form-control-lg"
                value={this.state.user.account_type}
              />
            </div>
            {this.state.user.ref_account && (
              <div className="col-md-6">
                <Input
                  label="Referer"
                  required
                  disabled
                  className="form-control-lg"
                  value={this.state.user.ref_account}
                />
              </div>
            )}
          </div>
          {(this.state.user.account_type === "admin_account" ||
            this.state.user.account_type === "user_account") && (
            <>
              <hr />
              <div className="notification-control-box">
                <div className="icon-notification">
                  <i className="bx bx-bell" />
                </div>
                <div className="notification-info">
                  <div>
                    <h1>Notification</h1>
                    <p>Get notification when waste levels are high</p>
                  </div>
                </div>
                <Switch
                  checked={this.props.receive_all_notification}
                  onChange={this.handleNotificationToggle.bind(this)}
                />
              </div>
            </>
          )}
        </div>
        {/* <div className="col-md-3">
          <div className="img-thumbnail mb-3">
            <img src={profilePhoto} style={{ width: "100%" }} />
          </div>
          <div>
            <Button text="Choose Image" className="btn-default w-100 btn-lg" />
          </div>
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { receive_all_notification } = state.Notification;
  return {
    receive_all_notification,
  };
};

export default connect(mapStateToProps)(General);

export default [
  {
    type: 'water',
    min: 0,
    max: 40,
    level: 'low_water_level',
    icon: 'bx-droplet',
    color: '#fd0c0c',
  },
  {
    type: 'water',
    min: 40,
    max: 80,
    level: 'mid_water_level',
    icon: 'bxs-droplet-half',
    color: '#000',
  },
  {
    type: 'water',
    min: 60,
    max: 100,
    level: 'high_water_level',
    icon: 'bxs-droplet',
    color: '#0530c6',
  },
  {
    type: 'traffic',
    min: 0,
    max: 500,
    level: 'low_traffic_level',
    icon: 'bxs-car',
    color: '#1EC639',
  },
  {
    type: 'traffic',
    min: 500,
    max: 3000,
    level: 'mid_traffic_level',
    icon: 'bxs-car',
    color: '#0530c6',
  },
  {
    type: 'traffic',
    min: 3000,
    max: 1000000,
    level: 'high_traffic_level',
    icon: 'bxs-car',
    color: '#C6052B',
  },
  {
    type: 'air_quality',
    min: 0,
    max: 100,
    level: 'low_traffic_level',
    icon: 'bxl-tailwind-css',
    color: '#fd0c0c',
  },
  {
    type: 'air_quality',
    min: 100,
    max: 200,
    level: 'good_air_quality_level',
    icon: 'bxl-tailwind-css',
    color: '#0530c6',
  },
  {
    type: 'power',
    min: 0,
    max: 99,
    level: 'partial_power_level',
    icon: 'bxs-plug',
    color: '#fd0c0c',
  },
  {
    type: 'power',
    min: 100,
    max: 100,
    level: 'full_power_level',
    icon: 'bxs-bolt',
    color: '#0530c6',
  },
];

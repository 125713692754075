export const menus = [
  {
    group: "Home",
    icon: "bx-home",
    items: [
      {
        name: "C.Waste Map",
        route: "/dashboard/home/waste_map",
        group: "Home",
      },
      {
        name: "C.Waste Activities",
        route: "/dashboard/home/waste_activities",
        group: "Home",
      },
    ],
    show: true,
  },
  {
    group: "Analytics",
    icon: "bxs-dashboard",
    items: [
      {
        name: "Waste Levels",
        route: "/dashboard/analytics/waste_activities",
        group: "Analytics",
      },
      {
        name: "Sensor Activities",
        route: "/dashboard/analytics/sensor_activities",
        group: "Analytics",
      },
    ],

    show: true,
  },
  {
    group: "Schedules",
    icon: "bx-time-five",
    items: [
      {
        name: "Pending Schedules",
        route: "/dashboard/schedules/pending_Schedules",
        group: "Schedules",
      },
      {
        name: "Served Schedules",
        route: "/dashboard/analytics/served_schedules",
        group: "Schedules",
      },
    ],
    show: true,
  },
  {
    group: "Alerts",
    icon: "bx-error",
    items: [
      {
        name: "Waste Bin Levels",
        route: "/dashboard/alerts/waste_levels",
        group: "Alerts",
      },
      {
        name: "Sensor State",
        route: "/dashboard/alerts/sensor_state",
        group: "Alerts",
      },
    ],
    show: true,
  },
  {
    group: "Incidents",
    icon: "bx-copy",
    items: [
      {
        name: "Waste Bin Levels",
        route: "/dashboard/incidents/waste_levels",
        group: "Incidents",
      },
      {
        name: "Sensor State",
        route: "/dashboard/incidents/sensor_state",
        group: "Incidents",
      },
    ],
    show: true,
  },
  {
    group: "Bins & Devices",
    route: "/dashboard/bin_devices",
    icon: "bx-trash",
    role: "admin",
  },
  {
    group: "Accounts",
    route: "/dashboard/accounts",
    icon: "bx-group",
  },
];

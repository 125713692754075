import React from "react";
import { Splash } from "../components/Splash";

class SplashScreen extends React.Component {
  componentDidMount = async () => {
    setTimeout(() => {
      window.location.href = "/login";
    }, 2000);
  };
  render() {
    return <Splash />;
  }
}

export default SplashScreen;

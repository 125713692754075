import React from "react";
import { Button } from "../../../app/components/Button";
import { Input, Select } from "../../../app/components/Input";

import "./styles.css";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { getStorage } from "../../../app/utils/storage";
import formatSelectData from "../../../app/utils/formatSelectData";

class NewStock extends React.Component {
  state = {
    email: "",
    password: "",
    error: {},
    isSubmitting: false,
    user: {},
    _id: "",
    products: [],
    quantity: "",
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.getProducts(true);

    if (this.props._id && this.props._id !== "") {
      let product = {};

      if (this.props?.product?.name) {
        product = {
          label: this.props?.product?.name,
          value: this.props?.product?._id,
        };
      }

      this.setState({
        ...this.props,
        quantity: this.props.stock,
        product,
      });
    }
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getProducts(isFetchingProduct, search_product) {
    this.setState({
      isFetchingProduct,
    });

    const { user } = this.state;

    let body = {
      page: 1,
      limit: 10000,
    };

    if (search_product && search_product !== "") {
      body.search = search_product;
    }

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_service_product_info",
      data: {
        ...body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options).then((res) => {
      const products = formatSelectData(res.data.data, "name", "_id");

      this.setState({
        products,
        isFetchingProduct: false,
      });

      return products;
    });
  }

  onChangeText = async (name, e) => {
    let error = this.state.error;
    let value = e.target ? e.target.value : e;

    delete error[name];

    await this.setState({
      error,
      [name]: value,
    });
  };

  validateForm() {
    let { product, error, quantity } = this.state;

    if (quantity === "") {
      error.quantity = "Quantity is required";
    }

    if (!product) {
      error.product = "Product is required";
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const { product, user, _id, error, quantity } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let url = ENDPOINT + "/add_stock_info";

      let data = {
        stock: quantity,
        service_product_id: product.value,
      };

      if (_id !== "") {
        url = ENDPOINT + "/update_stock_info";
        data.id = _id;
      }

      const options = {
        method: "POST",
        url,
        data,
        headers: {
          authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
            email: "",
            password: "",
            role: {},
            contact: "",
          });

          toastMessage(
            "success",
            `Data ${this.state._id !== "" ? "updated" : "created"} successfully`
          );

          this.props.handleCloseModal();
          this.props.getParks(true);
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("error", error);
        });
    }
  };

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Select
                defaultOptions={this.state.products}
                label="Product:"
                className="form-control-lg "
                value={this.state.product}
                onChange={(e) => this.onChangeText("product", e)}
                error={this.state.error.product}
                required
                async
                isLoading={this.state.isFetchingProduct}
                onInputChange={(e) => this.onChangeText("search_product", e)}
                loadOptions={(inputValue) => this.getProducts(true, inputValue)}
              />
            </div>
            <div className="col-md-12">
              <Input
                label="Quantity:"
                required
                className="form-control-lg"
                value={this.state.quantity}
                onChange={(e) => this.onChangeText("quantity", e)}
                error={this.state.error.quantity}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="card-footer d-flex align-content-center justify-content-end gap-3">
          <Button
            text="Close"
            className="btn-default btn-lg border"
            onPress={this.props.handleCloseModal}
          />
          <Button
            isSubmitting={this.state.isSubmitting}
            text="Submit"
            className="btn-primary btn-lg"
            onPress={this.onSubmit.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default NewStock;

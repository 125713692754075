import React from "react";
import Map from "../components/Map/Map";
import { Home } from "./common";
import { getStorage } from "../../app/utils/storage";
import { ENDPOINT } from "../../app/constants/api";
import axios from "axios";
import { connect } from "react-redux";
import filtersData from "../../app/utils/filtersData";
import Geocode from "react-geocode";
import { REACT_APP_MAPS_API_KEY } from "../../app/constants/strings";

Geocode.setApiKey(REACT_APP_MAPS_API_KEY);

class FleetMapScreen extends React.Component {
  state = {
    user: {},
    data: [],
    vehicleLocations: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  returnFilters() {
    const { filters } = this.props;

    let params = {
      target: "last",
      filters,
      vehicleField: "number_plate",
    };

    let request_body = filtersData(params);

    return request_body;
  }

  getData(isLoading) {
    const { user } = this.state;

    this.setState({ isLoading });

    let request_body = this.returnFilters();

    delete request_body.end_createdAt;
    delete request_body.start_createdAt;

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_location_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then(async (res) => {
        let response_data = res.data,
          data = [];

        for (let i = 0; i < response_data.length; i++) {
          const geoCodedAddress = await Geocode.fromLatLng(
            response_data[i].last.latitude,
            response_data[i].last.longitude
          )
            .then((response) => {
              if (response.results.length > 0) {
                const addressComponents =
                  response.results[0].address_components;
                const addressParts = {
                  street: "",
                  neighborhood: "",
                  district: "",
                  city: "",
                };

                addressParts.street = response.plus_code.global_code;
                for (const component of addressComponents) {
                  if (component.types.includes("route")) {
                    addressParts.street = component.long_name;
                  } else if (component.types.includes("sublocality_level_1")) {
                    addressParts.neighborhood = component.long_name; // Set the neighborhood/sector name
                  } else if (
                    component.types.includes("administrative_area_level_2")
                  ) {
                    addressParts.district = component.long_name; // Set the district name
                  } else if (component.types.includes("locality")) {
                    addressParts.city = component.long_name;
                  }
                }

                return Object.values(addressParts).join(", ");
              } else {
                return "No results found";
              }
            })
            .catch((error) => {
              console.error(error);
            });

          data.push({
            ...response_data[i].last,
            geoCodedAddress,
          });
        }

        this.setState({
          isLoading: false,
          data,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <div>
        <Home isCountCard location date company mapinsight vehicle />
        <div style={{ position: "relative", marginTop: "1rem" }}>
          <Map
            isLoading={this.state.isLoading}
            data={this.state.data}
            type="park_level"
            zoom={11}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(FleetMapScreen);

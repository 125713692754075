import React from "react";
import { Button } from "../../../app/components/Button";

const BinInfo = (props) => {
  return (
    <div className="card">
      <div className="card-body table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Bin ID</th>
              <th>Type</th>
              <th>Area</th>
              <th>Height</th>
              <th>Province</th>
              <th>District</th>
              <th>Sector</th>
              <th>Cell</th>
              <th>Village</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{props.bin_id}</td>
              <td>{props.type}</td>
              <td>{props.area}</td>
              <td>{props.height}</td>
              <td>{props.address.province}</td>
              <td>{props.address.district}</td>
              <td>{props.address.sector}</td>
              <td>{props.address.cell}</td>
              <td>{props.address.village}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="card-footer d-flex align-content-center justify-content-end gap-3">
        <Button
          text="Close"
          className="btn-default btn-lg border"
          onPress={props.handleCloseModal}
        />
      </div>
    </div>
  );
};

export default BinInfo;

import React from "react";
import { Marker, useMap } from "react-leaflet";
import L from "leaflet";
import moment from "moment";

export default function PolygonPointMarker(props) {
  const map = useMap();
  return (
    <div>
      <Marker
        icon={L.divIcon({
          className: "leaflet-data-marker",
          html: `<div class='polygon-marker'>
          <span>${moment(props.marker[2]).format("LT")}</span>
              </div>`,
        })}
        position={[props.marker[0], props.marker[1]]}
        eventHandlers={{
          click: (e) => {
            map.flyTo(e.latlng);
          },
        }}
      ></Marker>
    </div>
  );
}

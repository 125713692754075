import React from "react";
import Table from "../Table/Table";
import "./styles.css";
import { getStorage } from "../../../app/utils/storage";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { DeleteModal, Modal } from "../Modal";
import { CSVLink } from "react-csv";
import exportPDF from "../../../app/utils/exportPDF";
import { CompanyInfo } from "../Company";
import { connect } from "react-redux";
import { ParkInfo } from "../Parks";
import NewRent from "./NewRent";

let copyData = [];

class Rents extends React.Component {
  state = {
    data: [],
    page: 1,
    limit: 10,
    csvData: [],
    user: {},
    selected_rent: {},
    delete_password: "",
    isDeleting: false,
    error: {},
    delete_reason: "",
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  returnFilters() {
    const { page, limit } = this.state;
    const { filters } = this.props;

    let request_body = {
      page,
      limit,
      name: "rent",
    };

    if (filters.start_date && filters.end_date) {
      request_body.start_date = new Date(filters.start_date).getTime();
      request_body.end_date = new Date(filters.end_date).getTime();
    }

    return request_body;
  }

  getData(isLoading) {
    const { user } = this.state;

    this.setState({
      isLoading,
    });

    let request_body = this.returnFilters();

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_service_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data, count } = res.data;

        this.setState({
          data,
          isLoading: false,
          totalPageCount: count,
        });

        if (data.length > 0) {
          copyData = data.slice(0);
        }
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({ search_text });

    let array = [];

    for (let i = 0; i < copyData.length; i++) {
      if (
        JSON.stringify(copyData[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copyData[i]);
      }
    }

    this.setState({ data: array });
  }

  downloadExcel() {
    const { user } = this.state;

    this.setState({
      isLoading: true,
    });

    let request_body = this.returnFilters();

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_service_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res.data.data;

        let csvData = [];

        for (let el of data) {
          delete el.__v;
          delete el._id;
          delete el.updatedAt;

          csvData.push({
            ...el,
          });
        }

        this.setState(
          {
            csvData: data,
            isLoading: false,
          },
          () => {
            this.refs.csvDownload?.link.click();
          }
        );
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  downloadPDF() {
    const headers = this.returnTableHeaders();

    delete headers[headers.length - 1];

    const { user } = this.state;

    let request_body = this.returnFilters();

    this.setState({
      isLoading: true,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_service_info",
      data: {
        ...request_body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res.data.data;

        this.setState({ isLoading: false });

        exportPDF("ServiceRent", headers, data);
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  handleShowModal(modal, modalTitle, selected_rent = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_rent,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onChangeText(name, e) {
    let { error } = this.state;

    delete error[name];

    this.setState({
      [name]: e.target.value,
      error,
    });
  }

  validateDelete() {
    let { error, delete_password } = this.state;

    if (delete_password === "") {
      error.delete_password = "Your password is mandatory to confirm delete";
    }

    this.setState({
      error,
    });
  }

  onDelete = async () => {
    let { data, selected_rent, user, error, delete_password } = this.state;

    await this.validateDelete();

    if (Object.keys(error).length === 0) {
      this.setState({
        data,
        isDeleting: true,
      });

      let url = ENDPOINT + "/delete_service_info";

      const options = {
        method: "POST",
        url,
        data: {
          id: selected_rent._id,
          password: delete_password,
          available: false,
        },
        headers: {
          authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((res) => {
          const index = data.indexOf(selected_rent);

          data.splice(index, 1);

          this.setState({
            isDeleting: false,
          });

          toastMessage("success", "Data deleted successful");
          this.handleCloseModal("showDeleteModal");

          this.getData(false);
        })
        .catch((error) => {
          this.setState({
            isDeleting: false,
          });

          toastMessage("error", error);
        });
    }
  };

  onViewParkInfo(vehicle) {
    this.setState({
      showParkInfo: true,
      modalTitle: vehicle.reference_park_id?.park_id + "'s Info",
      selected_park: vehicle.reference_park_id,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: "Identifier",
        key: "other.name",
      },
      {
        title: "Currency",
        key: "other.currency",
      },
      {
        title: "Price",
        key: "price",
      },
      {
        title: "Action",
        key: "action",
      },
    ];
    return headers;
  }

  render() {
    return (
      <>
        <div className="card">
          <div className="card-body">
            <Table
              data={this.state.data}
              no_bordered
              isSearch
              style={{ marginBottom: 0 }}
              totalPageCount={this.state.totalPageCount}
              page={this.state.page}
              limit={this.state.limit}
              search_text={this.state.search_text}
              handleSearch={this.handleSearch.bind(this)}
              isLoading={this.state.isLoading}
              handlePagination={this.handlePagination.bind(this)}
              headers={this.returnTableHeaders()}
              showAdd
              addButtonText="Add Service"
              handleAddPressed={this.handleShowModal.bind(
                this,
                "showModal",
                "Create Service"
              )}
              actions={[
                {
                  name: "Edit",
                  onPress: (item) =>
                    this.handleShowModal("showModal", "Edit Service", item),
                },
                {
                  name: "Delete",
                  onPress: this.handleShowModal.bind(
                    this,
                    "showDeleteModal",
                    "Delete Service"
                  ),
                },
              ]}
              filters={[
                {
                  type: "export",
                  title: "Export",
                  button_type: "dropdown",
                  icon: "bx bxs-download",
                  options: [
                    {
                      name: "PDF",
                      onPress: this.downloadPDF.bind(this),
                    },
                    {
                      name: "CSV",
                      onPress: this.downloadExcel.bind(this),
                    },
                  ],
                },
              ]}
            />
          </div>
          <Modal
            handleClose={this.handleCloseModal.bind(this, "showModal")}
            show={this.state.showModal}
            title={this.state.modalTitle}
            showHeaderBottomBorder={false}
          >
            <NewRent
              handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
              getData={this.getData.bind(this)}
              {...this.state.selected_rent}
              status="active"
            />
          </Modal>
          <Modal
            handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
            show={this.state.showDeleteModal}
            title={this.state.modalTitle}
          >
            <DeleteModal
              handleCloseModal={this.handleCloseModal.bind(
                this,
                "showDeleteModal"
              )}
              error={this.state.error.delete_password}
              delete_password={this.state.delete_password}
              onDelete={this.onDelete.bind(this)}
              onChangePassword={this.onChangeText.bind(this)}
              onChangeText={this.onChangeText.bind(this)}
              isDeleting={this.state.isDeleting}
            />
          </Modal>
          <Modal
            handleClose={this.handleCloseModal.bind(this, "showCompanyInfo")}
            show={this.state.showCompanyInfo}
            title={this.state.modalTitle}
            showHeaderBottomBorder={false}
          >
            <CompanyInfo {...this.state.selected_company} />
          </Modal>
          <Modal
            handleClose={this.handleCloseModal.bind(this, "showParkInfo")}
            show={this.state.showParkInfo}
            title={this.state.modalTitle}
            size="lg"
          >
            <ParkInfo
              {...this.state.selected_park}
              handleCloseModal={this.handleCloseModal.bind(
                this,
                "showParkInfo"
              )}
            />
          </Modal>
          <CSVLink
            ref="csvDownload"
            filename={this.props.type + new Date().getTime()}
            data={this.state.csvData}
          ></CSVLink>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(Rents);

import React from "react";
import "./styles.css";
import { Bar } from "react-chartjs-2";
import { getStorage } from "../../../app/utils/storage";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { connect } from "react-redux";
import { LoadingSpinner } from "../LoadingSpinner";
import { numberWithCommas } from "../../../app/utils/formatCurrency";
import filtersData from "../../../app/utils/filtersData";

const options = {
  maintainAspectRatio: false,
  animation: false,
  offset: true,
};

class RevenuesPerPark extends React.Component {
  state = {
    data: [],
    user: {},
    selected_alert: {},
    user: {},
    rent: [],
    servicefee: [],
    parking: [],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getData = async (isLoading) => {
    await this.getParkingRevenues(isLoading);
    await this.getServiceFeeRevenues(isLoading);
    await this.getRentRevenues(isLoading);

    this.formatChartData();
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getParkingRevenues(isLoading) {
    const { user } = this.state;
    const { filters } = this.props;

    let params = {
      filters,
      parkFilterType: "park_id",
      vehicleFilterType: "ticket_user_ids",
    };

    let request_body = filtersData(params);

    request_body.tag = "park";

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options)
      .then((res) => {
        console.log({ tickets: res.data });

        this.setState({
          isLoading: false,
          parking: res.data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingParking: false });
      });
  }

  getServiceFeeRevenues(isLoading) {
    const { user } = this.state;

    this.setState({
      isLoading,
    });

    const { filters } = this.props;

    let copyFilters = { ...filters };

    delete copyFilters.month;
    delete copyFilters.year;

    let params = {
      category: "servicefee",
      filters: copyFilters,
      parkFilterType: "reference_park_id",
    };

    let request_body = filtersData(params);

    request_body.tag = "park";

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_service_payment_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        console.log({ servicefee: res.data });

        this.setState({
          isLoading: false,
          servicefee: res.data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  getRentRevenues(isLoading) {
    const { user } = this.state;
    const { filters } = this.props;

    let copyFilters = { ...filters };

    delete copyFilters.month;
    delete copyFilters.year;

    let params = {
      category: "rent",
      filters: copyFilters,
      parkFilterType: "reference_park_id",
    };

    let request_body = filtersData(params);

    request_body.tag = "park";

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_service_payment_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        this.setState({
          isLoading: false,
          rent: res.data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  formatChartData() {
    const { rent, servicefee, parking } = this.state;

    const results = [...rent, ...servicefee, ...parking];

    let group_by_data = {};

    for (let el of results) {
      const park = el._id;

      if (!group_by_data[park]) {
        group_by_data[park] = {};
      }

      group_by_data[park] = el;
    }

    const labels = Object.keys(group_by_data);
    let chart_data = [];

    for (let el of Object.keys(group_by_data)) {
      chart_data.push(group_by_data[el].total_revenues);
    }

    this.setState({
      isLoading: false,
      data: {
        labels,
        datasets: [
          {
            label: "Per Park - RWF",
            data: chart_data,
            borderRadius: 15,
            backgroundColor: "#05c605",
            borderColor: "rgba(0,0,0,1)",
            barPercentage: 0.5,
          },
        ],
      },
    });
  }

  render() {
    return (
      <div className="chart-container">
        <div className="card">
          <div className="card-header">
            <h3>Revenues per Park</h3>
          </div>
          <div className="card-body" style={{ height: 280 }}>
            {this.state.isLoading ? (
              <LoadingSpinner />
            ) : (
              <Bar data={this.state.data} options={options} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return { filters };
};

export default connect(mapStateToProps)(RevenuesPerPark);

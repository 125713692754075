import React from "react";
import { Button } from "../../../app/components/Button";
import { Input, Select } from "../../../app/components/Input";
import validateEmail from "../../../app/utils/validateEmail";

import "./styles.css";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { getStorage } from "../../../app/utils/storage";

class NewCollector extends React.Component {
  state = {
    email: "",
    password: "",
    error: {},
    isSubmitting: false,
    user: {},
    _id: "",
    name: "",
    contact: "",
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    if (this.props._id && this.props._id !== "") {
      this.setState({
        ...this.props,
      });
    }
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  onChangeText(name, e) {
    let error = this.state.error;
    let value = e.target ? e.target.value : e;

    delete error[name];

    this.setState({
      error,
      [name]: value,
    });
  }

  validateForm() {
    let { name, error, contact } = this.state;

    if (name === "") {
      error.name = "Name is required";
    }

    if (contact === "") {
      error.contact = "Phone number is required";
    } else if (contact.length < 9) {
      error.contact = "Invalid phone number";
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const { contact, user, _id, error, name } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let url = ENDPOINT + "/add_reviewer_info";

      let data = {
        name,
        contact,
      };

      if (user.ref_account) {
        data.ref_account = user.ref_account;
      }

      if (user.ref_subaccount) {
        data.ref_subaccount = user.ref_subaccount;
      }

      if (_id !== "") {
        url = ENDPOINT + "/update_reviewer_info";
        data.id = _id;
      }

      const options = {
        method: "POST",
        url,
        data,
        headers: { "Content-Type": "multipart/form-data" },
        headers: {
          authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
            contact: "",
            name: "",
          });

          toastMessage(
            "success",
            `Data ${this.state._id !== "" ? "updated" : "created"} successfully`
          );

          this.props.handleCloseModal();
          this.props.getCheckInAdmins(true);
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("error", error);
        });
    }
  };

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                label="Name:"
                required
                className="form-control-lg"
                value={this.state.name}
                onChange={(e) => this.onChangeText("name", e)}
                error={this.state.error.name}
              />
            </div>
            <div className="col-md-12">
              <Input
                label="Phone Number:"
                required
                className="form-control-lg"
                value={this.state.contact}
                onChange={(e) => this.onChangeText("contact", e)}
                error={this.state.error.contact}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="card-footer d-flex align-content-center justify-content-end gap-3">
          <Button
            text="Close"
            className="btn-default btn-lg border"
            onPress={this.props.handleCloseModal}
          />
          <Button
            isSubmitting={this.state.isSubmitting}
            text="Submit"
            className="btn-primary btn-lg"
            onPress={this.onSubmit.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default NewCollector;

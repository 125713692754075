import React from "react";
import { Button } from "../../Button";
import Slide from "react-reveal/Slide";

const ReadyStart = () => {
  return (
    <div id="get-started" className="section-container ready-start-wrapper">
      <div className="container">
        <div>
          <Slide bottom>
            <center>
              <h1 className="primaryText">
                Ready to get <br />
                Started
              </h1>
              <center
                className="intro-btns"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className="intro-btn"
                  style={{
                    marginRight: 0,
                  }}
                >
                  <Button
                    text="Sign In"
                    className="btn-primary"
                    onPress={() => (window.location.href = "/login")}
                  />
                </div>
                <div style={{ width: "2rem" }} />
                <div
                  className="intro-btn"
                  style={{
                    marginRight: 0,
                  }}
                >
                  <Button
                    text="Waiting List"
                    className="btn-bordered"
                    onPress={() =>
                      window.open(
                        "https://docs.google.com/forms/d/e/1FAIpQLScZhQM_gb409akumn4pq9d8VWaoPRDzDatxNVHXAVgy2zXRKw/viewform",
                        "_blank"
                      )
                    }
                  />
                </div>
              </center>
            </center>
          </Slide>
        </div>
      </div>
    </div>
  );
};

export default ReadyStart;

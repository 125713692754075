import { io } from "socket.io-client";
import { API_URL } from "../../app/constants/api";

const socket = io(API_URL, {
  transports: ["websocket"],
});

socket.connect();

export default socket;

import React from "react";
import { Home } from "./common";
import { ValidatedTickets } from "../components/CheckedVehicles";
import { Tabs } from "../components/Tabs";

class ValidatedTicketsScreen extends React.Component {
  renderAll() {
    return <ValidatedTickets status="approved" />;
  }

  renderValid() {
    return <ValidatedTickets valid status="approved" />;
  }
  renderInvalid() {
    return <ValidatedTickets invalid status="approved" />;
  }
  render() {
    return (
      <div>
        <Home
          date
          location
          type
          level
          other={{
            thingType: "Traffic",
          }}
        />
        <div>
          <Tabs
            options={[
              {
                title: "Zebra Crossline",
                data: this.renderValid(),
              },
              {
                title: "Speed",
                data: this.renderInvalid(),
              },
              {
                title: "All",
                data: this.renderAll(),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default ValidatedTicketsScreen;

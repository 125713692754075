import React from "react";
import { Link } from "react-router-dom";
import Location from "./Location";
import Distance from "./Distance";
import LocationTypes from "./LocationTypes";
import Province from "./Province";
import { LineSeparator } from "../../LineSeparator";
import Search from "./Search";
import PastRange from "./PastRange";

class DefaultMenuItem extends React.Component {
  render() {
    const { menu, index, pathname } = this.props;

    let split_path = pathname.split("/");

    let last_route = split_path[split_path.length - 1];

    let actions = menu.actions || menu.action || [];

    if (menu.route) {
      return (
        <Link
          key={index}
          className="menu-item-header"
          to={`/dashboard/${menu.route}`}
        >
          <h1
            className={
              menu.group.toLowerCase().replaceAll(" ", "_") === last_route
                ? "text-primary"
                : ""
            }
          >
            <i className={`bx ${menu.icon} left-icon`} />
            {menu.group}
          </h1>
        </Link>
      );
    } else {
      return (
        <>
          <div key={index} className="menu-item-header">
            <h1
              className={
                menu.group.toLowerCase().replaceAll(" ", "_") === last_route
                  ? "text-primary"
                  : ""
              }
            >
              <i className={`bx ${menu.icon} left-icon`} />
              {menu.group}
            </h1>
          </div>
          <div>
            <div style={{ marginLeft: "1.1rem", paddingRight: "1rem" }}>
              {actions.includes("location") && (
                <Location steps={menu.locations} />
              )}
            </div>

            {actions.includes("distance") && (
              <>
                <div style={{ marginLeft: "1.1rem", paddingRight: "1rem" }}>
                  <Distance
                    distances={menu.distances}
                    defaultDistance={menu.defaultDistance}
                  />
                </div>
                <LineSeparator />
              </>
            )}

            {actions.includes("locationType") && (
              <>
                <div style={{ marginLeft: "1.1rem", paddingRight: "1rem" }}>
                  <LocationTypes types={menu.locationTypes} />
                </div>
                <LineSeparator />
              </>
            )}

            {actions.includes("province") && (
              <>
                <div style={{ marginLeft: "1.1rem", paddingRight: "1rem" }}>
                  <Province description={menu.description} />
                </div>
                <LineSeparator />
              </>
            )}

            {actions.includes("search") && (
              <>
                <div style={{ marginLeft: "1.1rem", paddingRight: "1rem" }}>
                  <Search />
                </div>
                <LineSeparator />
              </>
            )}

            {actions.includes("pastInRange") && (
              <>
                <div style={{ marginLeft: "1.1rem", paddingRight: "1rem" }}>
                  <PastRange />
                </div>
                <LineSeparator />
              </>
            )}
          </div>
        </>
      );
    }
  }
}

export default DefaultMenuItem;

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

//screens
import ServiceFeeRevenuesPerParkScreen from "./screen/ServiceFeeRevenuesPerParkScreen";
import ServiceFeeRevenuesPerUserScreen from "./screen/ServiceFeeRevenuesPerUserScreen";
import AccountScreen from "./screen/AccountScreen";
import DeviceActivitiesScreen from "./screen/DeviceActivitiesScreen";
import AlertStockLevelScreen from "./screen/AlertStockLevelScreen";
import AlertDeviceStateScreen from "./screen/AlertDeviceStateScreen";
import IncidentEntityLevelScreen from "./screen/IncidentEntityLevelScreen";
import IncidentDeviceStateScreen from "./screen/IncidentDeviceStateScreen";
import NotificationScreen from "./screen/NotificationScreen";
import CheckInTicketScreen from "./screen/CheckInTicketScreen";
import CheckOutTicketScreen from "./screen/CheckOutTicketScreen";
import ParkLevelScreen from "./screen/ParkLevelScreen";
import StockMapScreen from "./screen/StockMapScreen";
import PricingScreen from "./screen/PricingScreen";
import ServiceScreen from "./screen/ServiceScreen";
import ProductScreen from "./screen/ProductScreen";
import CompanyScreen from "./screen/CompanyScreen";
import ParkActivitiesScreen from "./screen/ParkActivitiesScreen";
import HighestRevenueScreen from "./screen/HighestRevenueScreen";
import CompanyTicketScreen from "./screen/CompanyTicketScreen";
import RevenuesPerUserScreen from "./screen/RevenuesPerUserScreen";
import RentRevenuesPerParkScreen from "./screen/RentRevenuesPerParkScreen";
import RentRevenuesPerUserScreen from "./screen/RentRevenuesPerUserScreen";
import EntityStockScreen from "./screen/EntityStockScreen";
import ClientScreen from "./screen/ClientScreen";

function App({ url = "/dashboard", appName = "stock_sales" }) {
  return (
    <Switch>
      <Route exact path={`${url}/${appName}`} component={StockMapScreen} />
      <Route
        exact
        path={`${url}/${appName}/map_insights`}
        component={StockMapScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/summary_activities`}
        component={ParkActivitiesScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/device_activities`}
        component={DeviceActivitiesScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/revenues_trends`}
        component={ParkLevelScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/validated_tickets`}
        component={CheckOutTicketScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/stock_&_sales_levels`}
        component={AlertStockLevelScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/stock_levels/:bin_id`}
        component={AlertStockLevelScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/device_state`}
        component={AlertDeviceStateScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/entity_levels`}
        component={IncidentEntityLevelScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/device_state`}
        component={IncidentDeviceStateScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/issued_tickets`}
        component={CheckInTicketScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/entity_devices`}
        component={EntityStockScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/accounts`}
        component={AccountScreen}
      />
      <Route
        exact
        path={`${url}/${appName}/notifications`}
        component={NotificationScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/notifications/id`}
        component={NotificationScreen}
      />

      <Route exact path={`${url}/pricing`} component={PricingScreen} />

      <Route
        exact
        path={`${url}/${appName}/services`}
        component={ServiceScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/services_products`}
        component={ProductScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/clients`}
        component={ClientScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/companies`}
        component={CompanyScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/highest_revenues`}
        component={HighestRevenueScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/company_ticket`}
        component={CompanyTicketScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/revenues_park_per_user`}
        component={RevenuesPerUserScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/servicefee_revenues_per_user`}
        component={ServiceFeeRevenuesPerUserScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/servicefee_revenues_per_park`}
        component={ServiceFeeRevenuesPerParkScreen}
      />

      <Route
        exact
        path={`${url}/${appName}dashboard/rent_revenues_per_park`}
        component={RentRevenuesPerParkScreen}
      />

      <Route
        exact
        path={`${url}/${appName}/rent_revenues_per_user`}
        component={RentRevenuesPerUserScreen}
      />
    </Switch>
  );
}

export default App;

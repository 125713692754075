import React from "react";
import { Button } from "../../../app/components/Button";
import { Input, Select } from "../../../app/components/Input";

import "./styles.css";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { getStorage } from "../../../app/utils/storage";
import formatSelectData from "../../../app/utils/formatSelectData";

class NewProduct extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    user: {},
    _id: "",
    name: "",
    description: "",
    price: "",
    entities: [],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getEntities(true);

    if (this.props._id && this.props._id !== "") {
      const { name, description, price, _id } = this.props;

      this.setState({
        _id,
        name,
        description,
        price,
      });
    }
  };

  getEntities(isLoading) {
    const { user } = this.state;

    this.setState({
      isLoading,
    });

    let body = {
      page: 1,
      limit: 1000,
    };

    let url = ENDPOINT + "/get_entity_info";

    const options = {
      method: "POST",
      url,
      data: {
        ...body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let { data } = res.data;

        if (!data) {
          data = res.data;
        }

        let entities = formatSelectData(data, "entity_id", "_id");

        this.setState({
          entities,
          isLoading: false,
          entity: entities.length === 1 ? entities[0] : undefined,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  onChangeText = async (name, e) => {
    let error = this.state.error;
    let value = e.target ? e.target.value : e;

    delete error[name];

    await this.setState({
      error,
      [name]: value,
    });
  };

  validateForm() {
    let { error, name, price, entity } = this.state;

    if (name === "") {
      error.name = "Name is required";
    }

    if (price === "") {
      error.company = "Price is  required";
    }

    if (!entity) {
      error.entity = "Entity is  required";
    }
    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const { user, _id, error, name, description, price, entity } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let url = ENDPOINT + "/add_service_product_info";

      let data = {
        name,
        description,
        price,
        ref_org_account: user.id,
      };

      if (_id !== "") {
        url = ENDPOINT + "/update_service_product_info";
        data.id = _id;
      }

      if (entity && entity.value) {
        data["entity"] = entity.value;
      }

      const options = {
        method: "POST",
        url,
        data,
        headers: {
          authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
            name: "",
          });

          toastMessage(
            "success",
            `Data ${this.state._id !== "" ? "updated" : "created"} successfully`
          );

          this.props.handleCloseModal();
          this.props.getData(true);
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("error", error);
        });
    }
  };

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Select
                defaultOptions={this.state.entities}
                label="Entity:"
                className="form-control-lg "
                value={this.state.entity}
                onChange={(e) => this.onChangeText("entity", e)}
                error={this.state.error.entity}
                required
                async
                isLoading={this.state.isLoading}
                onInputChange={(e) => this.onChangeText("search_entity", e)}
                loadOptions={(inputValue) => this.getEntities(true, inputValue)}
              />
            </div>
            <div className="col-md-12">
              <Input
                label="Name:"
                required
                className="form-control-lg"
                value={this.state.name}
                onChange={(e) => this.onChangeText("name", e)}
                error={this.state.error.name}
              />
            </div>
            <div className="col-md-12">
              <Input
                label="Description:"
                className="form-control-lg"
                value={this.state.description}
                onChange={(e) => this.onChangeText("description", e)}
                error={this.state.error.description}
              />
            </div>
            <div className="col-md-12">
              <Input
                label="Price:"
                required
                className="form-control-lg"
                value={this.state.price}
                onChange={(e) => this.onChangeText("price", e)}
                error={this.state.error.price}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="card-footer d-flex align-content-center justify-content-end gap-3">
          <Button
            text="Close"
            className="btn-default btn-lg border"
            onPress={this.props.handleCloseModal}
          />
          <Button
            isSubmitting={this.state.isSubmitting}
            text="Submit"
            className="btn-primary btn-lg"
            onPress={this.onSubmit.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default NewProduct;

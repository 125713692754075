import DailyActive from "./DailyActive";
import AverageSpeedPerDay from "./AverageSpeedPerDay";
import DailyStationary from './DailyStationary';
import MonthlyVehicleCkeckins from './MonthlyVehicleCkeckins'
import WeeklyVehicleChekins from './WeeklyVehicleChekins'
export {
   AverageSpeedPerDay,
   DailyActive,
      
      DailyStationary,
      MonthlyVehicleCkeckins,
      WeeklyVehicleChekins
    
    };

import React from "react";
import Table from "../Table/Table";
import "./styles.css";
import { getStorage } from "../../../app/utils/storage";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { CSVLink } from "react-csv";
import exportPDF from "../../../app/utils/exportPDF";
import Geocode from "react-geocode";
import { REACT_APP_MAPS_API_KEY } from "../../../app/constants/strings";

Geocode.setApiKey(REACT_APP_MAPS_API_KEY);

let copyData = [];
class Trips extends React.Component {
  state = {
    data: [],
    page: 1,
    limit: 10,
    csvData: [],
    user: {},
    selected_price: {},
    delete_password: "",
    isDeleting: false,
    error: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getData(isLoading) {
    const { user, page, limit } = this.state;

    this.setState({
      isLoading,
    });

    let request_body = {
      limit,
      page,
      target: "last",
    };

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_location_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then(async (res) => {
        const { data, count } = res.data;

        const resData = await this.readLocation(data);

        this.setState({
          data: resData,
          isLoading: false,
          totalPageCount: count,
        });

        if (data.length > 0) {
          copyData = data.slice(0);
        }
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  readLocation = async (response_data) => {
    let data = [];
    for (let i = 0; i < response_data.length; i++) {
      const geoCodedAddress = await Geocode.fromLatLng(
        response_data[i].last.latitude,
        response_data[i].last.longitude
      ).then(
        (response) => {
          return response.results[0].formatted_address;
        },
        (error) => {
          console.error(error);
        }
      );
      data.push({
        ...response_data[i].last,
        geoCodedAddress,
      });
    }

    return data;
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleOpenModal(modal, selected_price) {
    this.setState({
      [modal]: true,
      selected_price: selected_price,
      modalTitle: "Price",
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({ search_text });

    let array = [];

    for (let i = 0; i < copyData.length; i++) {
      if (
        JSON.stringify(copyData[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copyData[i]);
      }
    }

    this.setState({ data: array });
  }

  downloadExcel() {
    const { user } = this.state;

    this.setState({
      isLoading: true,
    });

    let request_body = {
      page: 1,
      limit: 10,
    };

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_location_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res.data.data;

        let csvData = [];

        for (let el of data) {
          delete el.__v;
          delete el._id;
          delete el.updatedAt;

          let other = el.other;

          delete el.other;

          csvData.push({
            ...el,
            ...other,
          });
        }

        this.setState(
          {
            csvData,
            isLoading: false,
          },
          () => {
            this.refs.csvDownload?.link.click();
          }
        );
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  downloadPDF() {
    const headers = this.returnTableHeaders();

    delete headers[headers.length - 1];

    const { user } = this.state;

    this.setState({
      isLoading: true,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_location_info",
      data: {
        page: 1,
        limit: 10,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data.data;

        this.setState({ isLoading: false });

        exportPDF("Trips", headers, data);
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: "Number Plate",
        key: "number_plate",
      },
      {
        title: "Location",
        key: "geoCodedAddress",
      },
      {
        title: "Last Seen",
        key: "timestamp",
        formatTime: "lll",
        isMoment: true,
      },
    ];
    return headers;
  }

  render() {
    return (
      <>
        <div className="card">
          <div className="card-body">
            <Table
              data={this.state.data}
              no_bordered
              isSearch
              style={{ marginBottom: 0 }}
              totalPageCount={this.state.totalPageCount}
              page={this.state.page}
              limit={this.state.limit}
              search_text={this.state.search_text}
              handleSearch={this.handleSearch.bind(this)}
              isLoading={this.state.isLoading}
              handlePagination={this.handlePagination.bind(this)}
              headers={this.returnTableHeaders()}
              filters={[
                {
                  type: "export",
                  title: "Export",
                  button_type: "dropdown",
                  icon: "bx bxs-download",
                  options: [
                    {
                      name: "PDF",
                      onPress: this.downloadPDF.bind(this),
                    },
                    {
                      name: "CSV",
                      onPress: this.downloadExcel.bind(this),
                    },
                  ],
                },
              ]}
            />
          </div>
          <CSVLink
            ref="csvDownload"
            filename={"Services" + new Date().getTime()}
            data={this.state.csvData}
          ></CSVLink>
        </div>
      </>
    );
  }
}

export default Trips;

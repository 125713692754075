import React from "react";
import { Tabs } from "../components/Tabs";
import { Pricing } from "../components/Pricing";
import { Payment } from "../components/Rent";
import { Rent } from "../components/Service";
import { Tenant, UnPaidTenant } from "../components/Tenant";

class ServiceScreen extends React.Component {
  renderParking() {
    return <Pricing />;
  }

  renderPayment() {
    return <Payment />;
  }

  renderRent() {
    return <Rent />;
  }

  renderTenant() {
    return <Tenant />;
  }

  renderUnpaid() {
    return <UnPaidTenant />;
  }

  render() {
    return (
      <div>
        <div>
          <Tabs
            options={[
              {
                title: "Payments",
                data: this.renderPayment(),
              },
              {
                title: "Unpaid Tenants",
                data: this.renderUnpaid(),
              },
              {
                title: "Parking",
                data: this.renderParking(),
              },
              {
                title: "Rent",
                data: this.renderRent(),
              },
              {
                title: "Tenants",
                data: this.renderTenant(),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default ServiceScreen;

import React from "react";
import { Navbar } from "../components/LandingPage/Navbar";
import { Footer } from "../components/LandingPage/Footer";
import "../components/LandingPage/styles.css";
import { Introduction } from "../components/LandingPage/Introduction";
import { CDRFeature } from "../components/LandingPage/CDRFeature";
import VehicleRecognition from "../components/LandingPage/VehicleRecognition/VehicleRecognition";
import { AirQuality } from "../components/LandingPage/AirQuality";
import MultiApplication from "../components/LandingPage/MultiApplication/MultiApplication";
import ReadyStart from "../components/LandingPage/ReadyStart/ReadyStart";
import { IParallax, Parallax, ParallaxLayer } from "@react-spring/parallax";
class LandingPageScreen extends React.Component {
  render() {
    return (
      <div className="app">
        <Navbar />
        <div className="landing-containers">
          <section className="section" id="home">
            <Introduction />
          </section>
          <section className="section" id="cdr">
            <CDRFeature />
          </section>
          <section className="section" id="vehicle">
            <VehicleRecognition />
          </section>
          <section className="section" id="air_quality">
            <AirQuality />
          </section>
          <section className="section" id="multi_app">
            <MultiApplication />
          </section>
          <section className="section" id="start">
            <ReadyStart />
          </section>
          <section className="section" id="contactus"></section>
          <Footer />
        </div>
      </div>
    );
  }
}

export default LandingPageScreen;

const vehicleTags = [
  {
    label: "Car",
    value: "car",
  },
  {
    label: "Motor",
    value: "motor",
  },
  {
    label: "Truck",
    value: "truck",
  },
];

export default vehicleTags;

import React from "react";
import { getStorage } from "../../../app/utils/storage";
import toastMessage from "../../../app/utils/toastMessage";
import { Button } from "../../../app/components/Button";
import "./styles.css";
import axios from "axios";
import { ENDPOINT } from "../../../app/constants/api";
import Table from "../../../app/components/Table/Table";

class VehicleInfo extends React.Component {
  state = {
    isLoading: true,
    data: [],
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState(
      {
        user,
      },
      () => {
        this.getData(true);
      }
    );
  };

  getData(isLoading) {
    const { user } = this.state;

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_user_info",
      data: {
        id: this.props.id ? this.props.id : this.props._id,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res.data;

        this.setState({
          data,
          isLoading: false,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: "Company",
        key: "organization_id.name",
      },
      {
        title: "Plate Number",
        key: "identifier",
      },
      {
        title: "Bus park",
        key: "reference_park_id.park_id",
      },
    ];
    return headers;
  }

  render() {
    const props = this.props;
    return (
      <div className="card">
        <div className="card-body table-responsive">
          <Table
            data={this.state.data}
            no_bordered
            style={{ marginBottom: 0 }}
            isLoading={this.state.isLoading}
            headers={this.returnTableHeaders()}
          />
        </div>
        <div className="card-footer d-flex align-content-center justify-content-end gap-3">
          <Button
            text="Close"
            className="btn-default btn-lg border"
            onPress={props.handleCloseModal}
          />
        </div>
      </div>
    );
  }
}

export default VehicleInfo;

import { DEFAULT_APPLICATION } from "../action/Application.js";
import { getStorage, setStorage } from "../../app/utils/storage.js";

const defaultApplication = (await getStorage("defaultApplication")) || {};

const initialState = {
  defaultApplication,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DEFAULT_APPLICATION:
      setStorage(action.payload, "defaultApplication");

      return {
        ...state,
        defaultApplication: { ...action.payload },
      };

    default:
      return state;
  }
};
export default reducer;

import React from "react";
import toastMessage from "../../../app/utils/toastMessage";
import { Button } from "../../../app/components/Button";
import { Input, Select } from "../../../app/components/Input";
import XLSX from "xlsx";
import axios from "axios";
import formatSelectData from "../../../app/utils/formatSelectData";
import { getStorage } from "../../../app/utils/storage";
import { ENDPOINT } from "../../../app/constants/api";

class UploadTenant extends React.Component {
  state = {
    isSubmitting: false,
    data: [],
    search_text: "",
    isLoading: false,
    selected_tenant: {},
    modalTitle: "",
    companies: [],
    error: {},
    isSubmitting: false,
    parks: [],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.getCompanies(true);
    this.getParks(true);
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getCompanies(isFetchingCompany, search_company) {
    this.setState({
      isFetchingCompany,
    });

    const { user } = this.state;

    let body = {
      page: 1,
      limit: 100000,
    };

    if (search_company && search_company !== "") {
      body.search = search_company;
    }

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_organization_info",
      data: {
        ...body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options).then((res) => {
      const companies = formatSelectData(res.data.data, "name", "_id");

      this.setState({
        companies,
        isFetchingCompany: false,
      });

      return companies;
    });
  }

  getParks(isFetchingPark, search_park) {
    this.setState({
      isFetchingPark,
    });

    const { user } = this.state;

    let body = {
      page: 1,
      limit: 1000000,
    };

    if (search_park && search_park !== "") {
      body.search = search_park;
    }

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_park_info",
      data: {
        ...body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options).then((res) => {
      const parks = formatSelectData(res.data.data, "park_id", "_id");

      this.setState({
        parks,
        isFetchingPark: false,
      });

      return parks;
    });
  }

  onChangeText = async (name, e) => {
    let error = this.state.error;
    let value = e.target ? e.target.value : e;

    delete error[name];

    await this.setState({
      error,
      [name]: value,
    });
  };

  onImportVehicles(e) {
    const { files } = e.target;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */

      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      const jsonData = this.convertToJson(data);

      let keys = jsonData.length > 0 ? Object.keys(jsonData[0]) : [];

      if (keys.length == 0) {
        return toastMessage("error", "Empty Data");
      }

      if (!keys.includes("Plate Number")) {
        return toastMessage("error", "Invalid loan file format");
      }

      this.setState({ isSubmitting: true });

      let length_data = 0;

      for (let i = 0; i < jsonData.length; i++) {
        length_data += 1;
        if (jsonData[i]["Plate Number"]) {
          this.saveVehicles(jsonData[i], length_data === jsonData.length);
        }
      }
    };
    reader.readAsBinaryString(files[0]);
  }

  convertToJson(csv) {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }
    return result; //JSON
  }

  saveVehicles = async (vehicle, done) => {
    const { company, user, park } = this.state;

    let url = ENDPOINT + "/add_ticket_user_info";

    let data = {
      identifier: vehicle["Plate Number"],
      organization_id: company.value,
      reference_park_id: park.value,
      status: "active",
    };

    const options = {
      method: "POST",
      url,
      data,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((data) => {
        this.setState({
          isSubmitting: false,
        });

        this.props.getData();
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
        });

        toastMessage("error", error);
      });
  };

  render() {
    return (
      <div className="upload-container">
        <div className="file-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <Select
                  label="Company:"
                  className="form-control-lg "
                  value={this.state.company}
                  onChange={(e) => this.onChangeText("company", e)}
                  error={this.state.error.company}
                  required
                  isLoading={this.state.isFetchingCompany}
                  options={this.state.companies}
                />
              </div>
              <div className="col-md-12">
                <Select
                  options={this.state.parks}
                  label="Bus Park:"
                  className="form-control-lg "
                  value={this.state.park}
                  onChange={(e) => this.onChangeText("park", e)}
                  error={this.state.error.park}
                  required
                  isLoading={this.state.isFetchingPark}
                />
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <Input
                    label="Upload file (.csv, .xls, .xlsx)"
                    type="file"
                    required
                    className="form-control"
                    onChange={this.onImportVehicles.bind(this)}
                  />
                </div>
              </div>
              {this.state.isSubmitting && (
                <span>
                  <i className="bx bx-loader-circle bx-spin bx-rotate-90"></i>{" "}
                  Uploading...
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="result-upload"></div>
        <hr />
        <div className="btn-upload">
          <div style={{ flex: 1 }} />
          <Button
            className="bordered"
            text="Close"
            onPress={this.props.handleCloseModal}
          />
        </div>
      </div>
    );
  }
}

export default UploadTenant;

import React from "react";
import Table from "../../../app/components/Table/Table";
import "./styles.css";

class RentInfo extends React.Component {
  render() {
    return (
      <div className="card-body">
        <Table
          data={[this.props.data]}
          no_bordered
          headers={[
            {
              title: "Identifier",
              key: "other.name",
            },
            {
              title: "Currency",
              key: "other.currency",
            },
            {
              title: "Price",
              key: "price",
            },
          ]}
        />
      </div>
    );
  }
}

export default RentInfo;

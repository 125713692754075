import React from "react";
import Map from "../components/Map/Map";
import { Home } from "./common";
import { getStorage } from "../../app/utils/storage";
import { ENDPOINT } from "../../app/constants/api";
import axios from "axios";
import { connect } from "react-redux";
import filtersData from "../../app/utils/filtersData";
import Geocode from "react-geocode";
import { REACT_APP_MAPS_API_KEY } from "../../app/constants/strings";

Geocode.setApiKey(REACT_APP_MAPS_API_KEY);

class FleetMapScreen extends React.Component {
  state = {
    user: {},
    data: [],
    vehicleLocations: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  returnFilters() {
    const { filters } = this.props;

    let params = {
      target: "last",
      filters,
      vehicleField: "number_plate",
    };

    let request_body = filtersData(params);

    return request_body;
  }

  getData(isLoading) {
    const { user } = this.state;

    this.setState({ isLoading });

    let request_body = this.returnFilters();

    delete request_body.end_createdAt;
    delete request_body.start_createdAt;

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_location_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then(async (res) => {
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <div>
        <Map
          isLoading={this.state.isLoading}
          data={this.state.data}
          zoom={11}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(FleetMapScreen);

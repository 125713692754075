import React from "react";
import "./styles.css";
import { Checkbox, Input } from "../../../app/components/Input";
import { onFilter } from "../../../app/action/Filters";
import { connect } from "react-redux";

let copyLevel = [];

class WaterLevel extends React.Component {
  state = {
    levels: [
      {
        color: "#7A7D7A",
        icon: "bx-trash-alt",
        name: "Low Water Level",
        level_min: 0,
        level_max: 40,
        just_collected: false,
      },
      {
        color: "rgb(121, 2, 121)",
        icon: "bx-trash",
        name: "Mid Low Level",
        level_min: 40,
        level_max: 60,
        just_collected: false,
      },
      {
        color: "#000000",
        icon: "bx-trash",
        name: "Mid High Level",
        level_min: 60,
        level_max: 80,
        just_collected: false,
      },
      {
        color: "#FD0C0C",
        icon: "bxs-trash-alt",
        name: "Almost Full Level",
        level_min: 80,
        level_max: 100,
        just_collected: false,
      },
      {
        color: "#0530C6",
        icon: "bxs-trash",
        name: "Just Collected",
        level_min: 0,
        level_max: 100,
        just_collected: true,
      },
    ],
    selected_level: [],
  };

  componentDidMount() {
    copyLevel = this.state.levels.slice(0);

    const { filters } = this.props;

    if (filters.level_min) {
      this.setState({ level_min: filters.level_min });
    }

    if (filters.level_max) {
      this.setState({ level_max: filters.level_max });
    }
  }

  onChangeText(name, e) {
    this.setState(
      {
        [name]: e.target.value,
      },
      () => {
        this.props.dispatch(
          onFilter({
            [name]: e.target.value !== "" ? parseInt(e.target.value) : "",
          })
        );

        let { level_max, level_min, levels, selected_level } = this.state;

        for (let i = 0; i < levels.length; i++) {
          if (
            level_min < 40 &&
            level_max < 40 &&
            levels[i].name === "Low Water Level"
          ) {
            selected_level = [levels[i].name];
          }

          if (
            level_min >= 40 &&
            level_max < 60 &&
            levels[i].name === "Mid Low Level"
          ) {
            selected_level = [levels[i].name];
          }

          if (
            level_min >= 60 &&
            level_max < 80 &&
            levels[i].name === "Mid High Level"
          ) {
            selected_level = [levels[i].name];
          }

          if (
            level_min >= 80 &&
            level_max <= 100 &&
            levels[i].name === "Almost Full Level"
          ) {
            selected_level = [levels[i].name];
          }

          this.setState({ selected_level });
        }
      }
    );
  }

  handleSearch(e) {
    const search_text = e.target.value;
    let array = [];

    this.setState({ search_text });

    for (let i = 0; i < copyLevel.length; i++) {
      if (
        copyLevel[i].name.toUpperCase().indexOf(search_text.toUpperCase()) !==
        -1
      ) {
        array.push(copyLevel[i]);
      }
    }

    this.setState({ levels: array });
  }

  handleCheck(level) {
    this.setState(
      {
        selected_level: level,
      },
      () => {
        for (let i = 0; i < copyLevel.length; i++) {
          if (level === copyLevel[i].name) {
            this.props.dispatch(
              onFilter({
                level_min: copyLevel[i].level_min,
                level_max: copyLevel[i].level_max,
                just_collected: copyLevel[i].just_collected,
                just_collected: copyLevel[i].just_collected,
              })
            );

            this.setState({
              level_min: copyLevel[i].level_min,
              level_max: copyLevel[i].level_max,
            });
          }
        }
      }
    );
  }

  render() {
    return (
      <form>
        <div className="card cdropdown-container">
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <Input
                  label="Min(%)"
                  placeholder="0"
                  value={this.state.level_min}
                  onChange={(e) => this.onChangeText("level_min", e)}
                />
              </div>
              <div className="col-6">
                <Input
                  label="Max(%)"
                  placeholder="100"
                  value={this.state.level_max}
                  onChange={(e) => this.onChangeText("level_max", e)}
                />
              </div>
            </div>
            <hr />
            <Input
              placeholder="Search trash level..."
              className="form-control-sm"
              value={this.state.search_text}
              onChange={this.handleSearch.bind(this)}
            />
            <h1 className="separator-title">Levels</h1>
            {this.state.levels.map((item, i) => {
              return (
                <div className="cdropdown-item" key={i}>
                  <Checkbox
                    name={item.name}
                    handleCheck={this.handleCheck.bind(this, item.name)}
                    checked={this.state.selected_level === item.name}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(WaterLevel);

import { Router, Switch, Route, useRouteMatch } from "react-router-dom";
import DashboardLayout from "../layout/Dashboard";
//systems
import Stock from "../../stock/App";
import ZoneWatch from "../../zoneWatch/App";
import VisionAnalytics from "../../visionAnalytics/App";
import Mobility from "../../mobility/App";
import FleetManagement from "../../fleetManagement/App";
import Logistics from "../../logistics/App";

//All dashboard applications
const Dashboard = (props) => {
  let { path, url } = useRouteMatch();
  return (
    <DashboardLayout {...props}>
      <Switch>
        <Route path={`${path}/stock_sales/*`} component={Stock} />
        <Route
          exact
          path={`${path}/fleet_management/*`}
          component={FleetManagement}
        />
        <Route
          exact
          path={`${path}/mobility_insights/*`}
          component={Mobility}
        />
        <Route path={`${path}/zone_watch/*`} component={ZoneWatch} />
        <Route
          path={`${path}/levels_monitoring/*`}
          component={VisionAnalytics}
        />
        <Route path={`${path}/logistics_management/*`} component={Logistics} />
      </Switch>
    </DashboardLayout>
  );
};

export default Dashboard;

import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { ENDPOINT } from "../../../app/constants/api";
import { getStorage } from "../../../app/utils/storage";
import toastMessage from "../../../app/utils/toastMessage";
import { LoadingSpinner } from "../LoadingSpinner";
import "./styles.css";

class SensorStatus extends React.Component {
  state = {
    up: "...",
    down: "...",
    user: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getData(isLoading) {
    const { user } = this.state;
    const { filters } = this.props;

    this.setState({
      isLoading,
    });

    let request_body = {
      tag: "count",
      target: "last",
      type: this.props.type,
    };




    
    const options = {
      method: "POST",
      url: ENDPOINT + "/get_current_device_status_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        console.log("====================================");
        console.log({ get_current_device_status_info: res.data });
        console.log("====================================");
        this.setState({
          down: res.data.count.off_count,
          up: res.data.count.on_count,
          isLoading: false,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }
  render() {
    return (
      <div className="card">
        <div className="card-header">
          <h3>{this.props.type + " Camera's Status"}</h3>
        </div>
        <div className="card-body">
          {this.state.isLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="row">
              <div className="col">
                <center className="sensor-status-container up">
                  <div>
                    <h3>Up</h3>
                    <span>{this.state.up}</span>
                  </div>
                </center>
              </div>
              <center className="col">
                <div className="sensor-status-container down">
                  <div>
                    <h3>Down</h3>
                    <span>{this.state.down}</span>
                  </div>
                </div>
              </center>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(SensorStatus);

export const APP_NAME = "huzalytics";
export const REACT_APP_MAPS_API_KEY = "AIzaSyBQGGf1Y_JkJ3sT2oHwEC59Apt0NQVD-Z0";

export const account_roles = {
  admin_account: [
    {
      value: "admin",
      label: "Admin",
    },
  ],
  user_account: [
    {
      value: "admin",
      label: "Admin",
    },
  ],
};

export const statuses = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
];

export const availables = [
  {
    label: "true",
    value: "true",
  },
  {
    label: "false",
    value: "false",
  },
];

export const bin_types = [
  {
    label: "Biodegradable",
    value: "Biodegradable",
  },
  {
    label: "Non-Biodegradable",
    value: "Non-Biodegradable",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const bin_status = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Non Active",
    value: "non_active",
  },
];

export const colors = [
  "#05C605",
  "rgb(185,233,183,0.4)",
  "#1FF4AD",
  "#1F9AF4",
  "#C71FF4",
  "#F41FAA",
  "#B9770E",
];

export const currencies = [
  {
    label: "RWF",
    value: "RWF",
  },
];

export const countries = [
  {
    label: "Rwanda",
    value: "Rwanda",
  },
];

export const categories = [
  {
    label: "Transport",
    value: "Transport",
  },
];

export const default_currency = "RWF";

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const payment_methods = [
  {
    label: "Cash",
    value: "cash",
  },
  {
    label: "Credit",
    value: "credit",
  },
];

export const days = {
  1: "Sunday",
  2: "Monday",
  3: "Tuesday",
  4: "Wednesday",
  5: "Thursday",
  6: "Friday",
  7: "Saturday",
};

export const months = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const thing_types = [
  {
    label: "Traffic",
    value: "Traffic",
  },
  {
    label: "Tank",
    value: "Tank",
  },
  {
    label: "Station",
    value: "Station",
  },
];

export const thing_status = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Non Active",
    value: "non_active",
  },
];

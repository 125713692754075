import React from "react";
import { connect } from "react-redux";

const Notification = (props) => {
  const { group, name } = props;
  return (
    <>
      {group === "Alerts" &&
        props.counts?.alert_bin_unresolved > 0 &&
        name == "Park Levels" && (
          <div className="sidebar-notification-count">
            <span>
              {this.props.counts.alert_ticket_unresolved > 9
                ? "+9"
                : props.counts.alert_ticket_unresolved}
            </span>
          </div>
        )}
      {group === "Alerts" &&
        name == "Device State" &&
        props.counts?.count_alertdevice_unresolved > 0 && (
          <div className="sidebar-notification-count">
            <span>
              {this.props.counts.count_alertdevice_unresolved > 9
                ? "+9"
                : props.counts.count_alertdevice_unresolved}
            </span>
          </div>
        )}
      {group === "Tickets" &&
        name == "Vehicle Check In" &&
        props.counts?.count_check_in_tickets > 0 && (
          <div className="sidebar-notification-count">
            <span>
              {this.props.counts.count_check_in_tickets > 9
                ? "+9"
                : props.counts.count_check_in_tickets}
            </span>
          </div>
        )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { notifications, counts } = state.Notification;

  return { notifications, counts };
};

export default connect(mapStateToProps)(Notification);

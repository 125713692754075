import axios from "axios";
import React from "react";
import { ENDPOINT } from "../../../app/constants/api";
import toastMessage from "../../../app/utils/toastMessage";
import Table from "../../../app/components/Table/Table";
import "./styles.css";
import { getStorage } from "../../../app/utils/storage";
import { connect } from "react-redux";
import exportPDF from "../../../app/utils/exportPDF";
import { CSVLink } from "react-csv";
import { Modal } from "../../../app/components/Modal";
import SmsInfo from "./SmsInfo";
import { StockInfo } from "../Stocks";

let copySmsLogs = [];

class SmsLogs extends React.Component {
  state = {
    data: [],
    isLoading: true,
    user: {},
    page: 1,
    limit: 10,
    error: {},
    csvData: [],
    selected_sms: {},
    delete_password: "",
    selected_bin: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.getSmsLogs(true);
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getSmsLogs(isLoading) {
    const { user, page, limit } = this.state;

    this.setState({
      isLoading,
    });

    let body = {
      page,
      limit,
    };

    let url = ENDPOINT + "/get_sms_email_logs_info";

    const options = {
      method: "POST",
      url,
      data: {
        ...body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let { data, count } = res.data;

        if (!data) {
          data = res.data;
        }
        this.setState({
          data,
          isLoading: false,
          totalPageCount: count,
        });

        if (data.length !== 0) {
          copySmsLogs = data.slice(0);
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getSmsLogs(true);
      }
    );
  }

  handleSearch(e) {
    const search_text = e.target.value;
    let array = [];

    this.setState({
      search_text,
    });

    for (let i = 0; i < copySmsLogs.length; i++) {
      if (
        JSON.stringify(copySmsLogs[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copySmsLogs[i]);
      }
    }

    this.setState({
      data: array,
    });
  }

  downloadExcel = () => {
    const { user } = this.state;

    this.setState({
      isLoading: true,
    });

    let url = ENDPOINT + "/get_sms_email_logs_info";

    const options = {
      method: "POST",
      url,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };
    axios(options)
      .then((res) => {
        let { data } = res.data;

        if (!data) {
          data = res.data;
        }
        this.setState(
          {
            isLoading: false,
            csvData: data,
          },
          () => {
            this.refs.csvDownload?.link.click();
          }
        );
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  };

  downloadPDF = () => {
    const headers = this.returnTableHeaders();

    const { user } = this.state;
    const { filters } = this.props;

    this.setState({
      isLoading: true,
    });

    let url = ENDPOINT + "/get_sms_email_logs_info";

    const options = {
      method: "POST",
      url,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let { data } = res.data;

        if (!data) {
          data = res.data;
        }
        this.setState({
          isLoading: false,
        });

        exportPDF("Sms Logs", headers, data);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  };

  handleShowModal(modal, modalTitle, selected_item = {}) {
    let { selected_bin, selected_sms } = this.state;
    if (modalTitle === "Sms Info") {
      selected_sms = selected_item;
    }

    if (modalTitle === "Bin Info") {
      selected_bin = selected_item.bin_id;
    }

    this.setState({
      [modal]: true,
      modalTitle,
      selected_sms,
      selected_bin,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: "Bin Id",
        key: "bin_id.bin_id",
        type: "link",
        onPress: this.handleShowModal.bind(this, "showBinModal", "Bin Info"),
      },
      {
        title: "Message",
        key: "payload.subject",
      },
      {
        title: "Type",
        key: "type",
      },
      {
        title: "Date",
        key: "date",
        isMoment: true,
        formatTime: "lll",
      },
    ];

    return headers;
  }

  render() {
    return (
      <div>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={this.handleSearch.bind(this)}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          rowPress={this.handleShowModal.bind(this, "showModal", "Sms Info")}
          filters={[
            {
              type: "export",
              title: "Export",
              button_type: "dropdown",
              icon: "bx bxs-download",
              options: [
                {
                  name: "PDF",
                  onPress: this.downloadPDF.bind(this),
                },
                {
                  name: "CSV",
                  onPress: this.downloadExcel.bind(this),
                },
              ],
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title="Sms Log"
        >
          <SmsInfo
            {...this.state.selected_sms}
            {...this.state.selected_sms.payload}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showBinModal")}
          show={this.state.showBinModal}
          title={this.state.modalTitle}
          size="lg"
        >
          <StockInfo
            {...this.state.selected_bin}
            handleCloseModal={this.handleCloseModal.bind(this, "showBinModal")}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Sms Logs" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(SmsLogs);

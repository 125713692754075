import React from "react";

const SmsInfo = (props) => {
  return (
    <div className="card">
      <div className="card-body">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>Subject</td>
              <td>{props.subject}</td>
            </tr>
            <tr>
              <td>Message Body</td>
              <p>{props.main_message}</p>
            </tr>
            <tr>
              <td>Type</td>
              <td>{props.type}</td>
            </tr>
            <tr>
              <td>To</td>
              <td>
                <div className="row">
                  {props.bcc &&
                    props.bcc.map((item, i) => {
                      return (
                        <>
                          <div className="col-md-4" key={i}>
                            <span>{item}</span>
                          </div>
                          ,{" "}
                        </>
                      );
                    })}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SmsInfo;

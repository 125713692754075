import React from "react";
import { Input, Select } from "../../Input";

class Search extends React.Component {
  state = {
    show: false,
    error: {},
    isSubmitting: false,
    search_text: "",
  };

  onChangeText(field, value) {
    let { error } = this.state;

    delete error[field];
    this.setState({
      [field]: value,
      error,
    });
  }

  validateForm() {
    let { error, distance } = this.state;

    if (!distance || !distance?.value) {
      error.search = "ID is mandatory";
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const { error, search_text } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });
    }
  };

  render() {
    return (
      <>
        <div className="search-sidebar-cdr ">
          <Input
            value={this.state.search_text}
            onChange={(e) => this.onChangeText("search_text", e)}
            error={this.state.error.search}
            placeholder="Search person ID"
          />
          <div className="search-btn">
            <i className="bx bx-search" />
          </div>
        </div>
      </>
    );
  }
}

export default Search;

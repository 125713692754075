import React from "react";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import { getStorage } from "../../../app/utils/storage";
import toastMessage from "../../../app/utils/toastMessage";
import ApplicationList from "./ApplicationItem";
import "./styles.css";
import { LoadingSpinner } from "../LoadingSpinner";
import Logo from "../../assets/logo.png";
import { onSetDefaultApplication } from "../../action/Application";
import { connect } from "react-redux";

let copyData = [];

class Applications extends React.Component {
  state = {
    isLoading: true,
    data: [],
    page: 1,
    limit: 10,
    user: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    return await this.setState({
      user,
    });

    return;
  };

  getData = async (isLoading) => {
    const user = await getStorage();
    const { page, limit } = this.state;

    this.setState({
      isLoading,
    });

    let request_body = {
      page,
      limit,
    };

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_business_category_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res, count) => {
        const { data } = res.data;

        this.setState({
          data,
          isLoading: false,
          totalPageCount: count,
        });

        copyData = data.slice(0);
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  };

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({ search_text });

    let array = [];

    for (let i = 0; i < copyData.length; i++) {
      if (
        JSON.stringify(copyData[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copyData[i]);
      }
    }

    this.setState({ data: array });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  gotoApplication = async (application) => {
    await this.props.dispatch(onSetDefaultApplication(application));

    window.location.href = `/dashboard/${application.root}`;
  };

  returnTableHeaders() {
    let headers = [
      {
        title: "Application Name",
        key: "name",
        type: "link",
        onPress: (item) => this.gotoApplication(item),
      },
      {
        title: "Availability",
        key: "available",
      },
    ];

    return headers;
  }
  render() {
    return (
      <div className="applicationContainer">
        <div className="header">
          <center>
            <div className="logo">
              <img src={Logo} />
            </div>
            <h1>Welcome To HuzaLytics</h1>
            <p>Smart Realtime Monitoring at the Tips of your Fingers</p>
          </center>
        </div>
        <div className="applicationContent">
          {this.state.isLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="row align-items-center justify-content-center">
              <div className="col-md-8">
                <div className="card" style={{ padding: "2rem" }}>
                  <div className="card-body">
                    <div className="row">
                      {this.state.data.map((item, i) => {
                        return (
                          <ApplicationList
                            {...item}
                            onPress={() => this.gotoApplication(item)}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateProps = (state) => {
  return {};
};

export default connect(mapStateProps)(Applications);

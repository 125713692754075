import React from "react";
import "./styles.css";
import { Checkbox, Input, Select } from "../../../app/components/Input";
import axios from "axios";
import { ENDPOINT } from "../../../app/constants/api";
import toastMessage from "../../../app/utils/toastMessage";
import { getStorage } from "../../../app/utils/storage";
import { LoadingSpinner } from "../LoadingSpinner";
import { onFilter } from "../../../app/action/Filters";
import { connect } from "react-redux";
import formatSelectData from "../../../app/utils/formatSelectData";

let copyCompanies = [];

class Companies extends React.Component {
  state = {
    company: [],
    selected_company: [],
    isLoading: false,
    error: {},
    limit: 1000,
    page: 1,
    companies: [],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.getCompanies(true);
  };

  getCompanies(isLoading) {
    const { user, page, limit } = this.state;

    this.setState({ isLoading });

    let request_body = {
      page,
      limit,
    };

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_organization_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const companies = formatSelectData(res.data.data, "name", "_id");

        this.setState({ companies, isLoading: false });

        copyCompanies = companies.slice(0);
      })
      .catch((error) => {
        toastMessage("error", error);

        this.setState({ isLoadingCompanies: false });
      });
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  handleCheck(company) {
    this.setState(
      {
        selected_company: company,
      },
      () => {
        this.props.dispatch(onFilter({ companies: company }));
      }
    );
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({ search_text });

    let array = [];

    for (let i = 0; i < copyCompanies.length; i++) {
      if (
        copyCompanies[i].name
          .toUpperCase()
          .indexOf(search_text.toUpperCase()) !== -1
      ) {
        array.push(copyCompanies[i]);
      }
    }

    this.setState({
      companies: array,
    });
  }

  onResetCheck() {
    this.props.dispatch(onFilter({ companies: [] }));

    this.setState({
      selected_company: [],
      search_text: "",
      company: copyCompanies,
    });
  }

  render() {
    return (
      // <form>
      <div className="card cdropdown-container">
        <div className="card-body">
          <Input
            placeholder="Search company..."
            className="form-control-sm"
            value={this.state.search_text}
            onChange={this.handleSearch.bind(this)}
          />
          {/* <Link to="#">Use current location</Link> */}
          {this.state.isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                className="cdropdown-content"
              >
                <h1 className="separator-title">Companies</h1>
                {this.props.filters &&
                  this.props.filters.companies &&
                  this.props.filters.companies.value && (
                    <a
                      href="#"
                      className="text-danger"
                      onClick={this.onResetCheck.bind(this)}
                    >
                      <b>{`Clear `}</b>
                    </a>
                  )}
              </div>
              {this.state.companies.map((item, i) => {
                let checked = false;
                if (this.props.filters.companies?.value === item.value) {
                  checked = true;
                }
                return (
                  <div className="cdropdown-item" key={i}>
                    <Checkbox
                      name={item.name}
                      handleCheck={this.handleCheck.bind(this, item)}
                      checked={checked}
                    />
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return {
    filters,
  };
};

export default connect(mapStateToProps)(Companies);

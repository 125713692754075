import React from "react";
import Map from "../components/Map/Map";
import { Home } from "./common";
import { getStorage } from "../../app/utils/storage";
import socket from "../../app/utils/socketIO";
import { ENDPOINT } from "../../app/constants/api";
import axios from "axios";
import { connect } from "react-redux";
import filtersData from "../../app/utils/filtersData";

const position = [-1.9365986179180132, 30.155250107888524];

class CamerasMapScreen extends React.Component {
    state = {
        user: {},
        position,
        vehicles: [],
    };

    componentDidMount = async () => {
        await this.getUserLoggedInInfo();

       
        this.getVehicle(true);
        

        socket.on("vehicles", () => {
            this.getVehicle(false);
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.filters !== this.props.filters) {
           
            this.getVehicle(true);
            
        }
    }

    getUserLoggedInInfo = async () => {
        const user = await getStorage();
        this.setState({
            user,
        });
    };

   

    getVehicle(isLoading) {
        const { user } = this.state;
        const { filters } = this.props;

        this.setState({
            isLoading,
        });

        let request_body = filtersData({ filters });

        request_body.target = "last";

        const options = {
            method: "POST",
            url: ENDPOINT + "/get_vehicle_info",
            data: request_body,
            headers: {
              authorization: "Bearer " + user.token,
            },
          };
      
          axios(options)
            .then((res) => {
              let response_data = res.data,
                vehicles = [];
                    console.log(response_data[0].last.thing[0].address.lat)
                for (let i = 0; i < response_data.length; i++) {
                    response_data[i].last["address"] =
                        response_data[i].last.thing[0].address;
                        

                    vehicles.push({
                        ...response_data[i].last,
                        type: "traffic",
                        data:
                            response_data[i].last.thing &&
                                response_data[i].last.thing.length > 0
                                ? response_data[i].last.thing[0]
                                : {},
                        location: {
                            coordinates: [
                                response_data[i].last["address"].lat,
                                response_data[i].last["address"].long,
                            ],
                        },
                    
                        latitude: response_data[i].last["address"].lat,
                        longitude: response_data[i].last["address"].long
                        
                    });
                }
                
                this.setState({
                    isLoading: false,
                    vehicles,
                });
                 
            }
            )
            .catch((error) => {
                this.setState({
                    isLoading: false,
                });
            });
    }



    render() {
        const legend = {
            styles: {
                right: 0,
                top: 0,
                margin: 25,
            },
            items: [

                {
                    color: "#1EC639",
                    icon: "bxs-car",
                    name: "Low Traffic",
                },
                {
                    color: "#0530c6",
                    icon: "bxs-car",
                    name: "Mid Traffic",
                },
                {
                    color: "#fd0c0c",
                    icon: "bxs-car",
                    name: "High Traffic",
                },
                {
                    color: "transparent",
                    icon: "bxs-trash",
                    name: "N Grouped Thing",
                    className: "n_group",
                },
            ],
        };
        
        
        return (
            <div>
                <Home isCountCard location date />
                <div style={{ position: "relative", marginTop: "1rem" }}>
                    <Map
                        isLoading={this.state.isLoading}
                        legend={legend}
                        data={[
                            ...this.state.vehicles,
                        ]}
                        type="thing_level"
                    />
                </div>{" "}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { filters } = state.Filters;

    return {
        filters,
    };
};

export default connect(mapStateToProps)(CamerasMapScreen);

import React from "react";
import "./styles.css";
import { Bar } from "react-chartjs-2";
import { getStorage } from "../../../app/utils/storage";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { connect } from "react-redux";
import { LoadingSpinner } from "../LoadingSpinner";
import moment from "moment";

const options = {
  maintainAspectRatio: false,
  animation: false,
  offset: true,
};

class TotalRevenues extends React.Component {
  state = {
    data: [],
    user: {},
    selected_alert: {},
    user: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getRevenues(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getRevenues(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  returnFilters() {
    const { page, limit } = this.state;
    const { filters } = this.props;

    let request_body = {
      page,
      limit,
    };

    if (filters && filters.locations && filters.locations.length > 0) {
      let locations = [];

      for (let el of filters.locations) {
        locations.push(el.value);
      }
      request_body.park_id = locations;
    }

    if (filters && filters.companies && filters.companies.length > 0) {
      let companies = [];

      for (let el of filters.companies) {
        companies.push(el.value);
      }
      request_body.organization_id = companies;
    }

    if (filters && filters.start_date && filters && filters.end_date) {
      request_body.start_date = new Date(filters.start_date).getTime();
      request_body.end_date = new Date(filters.end_date).getTime();
    }
    return request_body;
  }

  getRevenues(isLoading) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    request_body.tag = "trend";
    request_body.order = "daily";
    request_body.status = "pending";

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let group_by_data = {};

        for (let el of res.data) {
          const date = el._id;

          if (!group_by_data[date]) {
            group_by_data[date] = {};
          }

          group_by_data[date] = el;
        }

        const labels = Object.keys(group_by_data);
        let chart_data = [];

        for (let el of Object.keys(group_by_data)) {
          chart_data.push(group_by_data[el].total_revenues);
        }

        this.setState({
          isLoading: false,
          data: {
            labels,
            datasets: [
              {
                label: "Daily - RWF",
                data: chart_data,
                borderRadius: 15,
                backgroundColor: "#05c605",
                borderColor: "rgba(0,0,0,1)",
                barPercentage: 0.5,
              },
            ],
          },
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    return (
      <div className="chart-container">
        <div className="card">
          <div className="card-header">
            <h3>Daily Total Revenues</h3>
          </div>
          <div className="card-body" style={{ height: 280 }}>
            {this.state.isLoading ? (
              <LoadingSpinner />
            ) : (
              <Bar data={this.state.data} options={options} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return { filters };
};

export default connect(mapStateToProps)(TotalRevenues);

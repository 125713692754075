import React from "react";
import { Applications } from "../components/Applications";

class ApplicationScreen extends React.Component {
  render() {
    return <Applications />;
  }
}

export default ApplicationScreen;

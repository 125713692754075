import axios from "axios";
import React from "react";
import { ENDPOINT } from "../../../app/constants/api";
import toastMessage from "../../../app/utils/toastMessage";
import Table from "../../../app/components/Table/Table";
import { DeleteModal, Modal } from "../../../app/components/Modal";
import "./styles.css";
import NewThing from "./NewThing";
import { getStorage } from "../../../app/utils/storage";
import { DeviceInfo } from "../Devices";
import exportPDF from "../../../app/utils/exportPDF";
import { CSVLink } from "react-csv";

let copyThing = [];
class SubAccounts extends React.Component {
  state = {
    data: [],
    isLoading: true,
    user: {},
    page: 1,
    limit: 10,
    selected_thing: {},
    error: {},
    csvData: [],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.getThing(true);
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getThing(isLoading) {
    const { user, page, limit } = this.state;

    this.setState({
      isLoading,
    });

    let body = {
      page,
      limit,
    };

    let url = ENDPOINT + "/get_thing_info";

    const options = {
      method: "POST",
      url,
      data: {
        ...body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let { data, count } = res.data;

        if (!data) {
          data = res.data;
        }
        this.setState({
          data,
          isLoading: false,
          totalPageCount: count,
        });

        if (data.length !== 0) {
          copyThing = data.slice(0);
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  }

  handleShowModal(modal, modalTitle, selected_thing = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_thing,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getThing(true);
      }
    );
  }

  onChangeText(name, e) {
    let { error } = this.state;

    delete error[name];

    this.setState({
      [name]: e.target.value,
      error,
    });
  }

  validateDelete() {
    let { error, delete_password } = this.state;

    if (delete_password === "") {
      error.delete_password = "Your password is mandatory to confirm delete";
    }

    this.setState({
      error,
    });
  }

  onDelete = async () => {
    let { data, selected_thing, user, error, delete_password } = this.state;

    await this.validateDelete();

    if (Object.keys(error).length === 0) {
      this.setState({
        data,
        isDeleting: true,
      });

      let url = ENDPOINT + "/delete_thing_info";

      const options = {
        method: "POST",
        url,
        data: { id: selected_thing._id, password: delete_password },
        headers: {
          authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((res) => {
          const index = data.indexOf(selected_thing);

          data.splice(index, 1);

          this.setState({
            isDeleting: false,
          });

          toastMessage("success", "Thing was deleted successful");
          this.handleCloseModal("showDeleteModal");
        })
        .catch((error) => {
          this.setState({
            isDeleting: false,
          });

          toastMessage("error", error);
        });
    }
  };

  handleSearch(e) {
    const search_text = e.target.value;
    let array = [];

    this.setState({
      search_text,
    });

    for (let i = 0; i < copyThing.length; i++) {
      if (
        JSON.stringify(copyThing[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copyThing[i]);
      }
    }

    this.setState({
      data: array,
    });
  }

  onViewDeviceDetails(device) {
    this.setState({
      showDeviceModal: true,
      modalTitle: device.device_id.device_id + "'s Info",
      selected_device: device.device_id,
    });
  }

  downloadExcel() {
    const { user } = this.state;

    this.setState({
      isLoading: true,
    });

    let body = {};

    let url = ENDPOINT + "/get_thing_info";

    const options = {
      method: "POST",
      url,
      data: {
        ...body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let { data } = res.data;

        if (!data) {
          data = res.data;
        }
        this.setState(
          {
            csvData: data,
            isLoading: false,
          },
          () => {
            this.refs.csvDownload?.link.click();
          }
        );
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  }

  downloadPDF() {
    const headers = this.returnTableHeaders();

    const { user } = this.state;

    this.setState({
      isLoading: true,
    });

    let body = {};

    let url = ENDPOINT + "/get_thing_info";

    const options = {
      method: "POST",
      url,
      data: {
        ...body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let { data } = res.data;

        if (!data) {
          data = res.data;
        }

        this.setState({ isLoading: false });
        exportPDF("Thing", headers, data, "landscape");
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: "Object ID",
        key: "thing_id",
      },
      {
        title: "Device ID",
        key: "device_id.device_id",
        type: "link",
        onPress: this.onViewDeviceDetails.bind(this),
      },
      {
        title: "Thing Type",
        key: "type",
      },
      {
        title: "Area",
        key: "area",
      },
      {
        title: "Height",
        key: "height",
      },
      {
        title: "Status",
        key: "status",
      },
      {
        title: "Location",
        key: "address.geolocation",
      },
      {
        title: "Province",
        key: "address.province",
      },
      {
        title: "District",
        key: "address.district",
      },
      {
        title: "Sector",
        key: "address.sector",
      },
      {
        title: "Cell",
        key: "address.cell",
      },
      {
        title: "Village",
        key: "address.village",
      },
    ];
    return headers;
  }

  render() {
    return (
      <div>
        <Table
          data={this.state.data}
          isSearch
          showAdd
          page={this.state.page}
          search_text={this.state.search_text}
          handleSearch={this.handleSearch.bind(this)}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          addButtonText="Add Object"
          rowPress={this.handleShowModal.bind(this, "showModal", "Thing Info")}
          headers={this.returnTableHeaders()}
          handleAddPressed={this.handleShowModal.bind(
            this,
            "showModal",
            "Create Thing"
          )}
          actions={[
            {
              name: "Edit",
              onPress: (item) =>
                this.handleShowModal("showModal", "Edit Thing", item),
            },
            {
              name: "Delete",
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                "Delete Thing"
              ),
            },
          ]}
          filters={[
            {
              type: "export",
              title: "Export",
              button_type: "dropdown",
              icon: "bx bxs-download",
              options: [
                {
                  name: "PDF",
                  onPress: this.downloadPDF.bind(this),
                },
                {
                  name: "CSV",
                  onPress: this.downloadExcel.bind(this),
                },
              ],
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <NewThing
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getThing={this.getThing.bind(this)}
            {...this.state.selected_thing}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            delete_password={this.state.delete_password}
            onDelete={this.onDelete.bind(this)}
            onChangePassword={this.onChangeText.bind(this)}
            isDeleting={this.state.isDeleting}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeviceModal")}
          show={this.state.showDeviceModal}
          title={this.state.modalTitle}
        >
          <DeviceInfo
            {...this.state.selected_device}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeviceModal"
            )}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Thing" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </div>
    );
  }
}

export default SubAccounts;

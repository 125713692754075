import { APP_NAME } from "../constants/strings";

export const setStorage = async (data, storageName = APP_NAME) =>
  await localStorage.setItem(storageName, JSON.stringify(data));

export const getStorage = async (storageName = APP_NAME) => {
  const user = await JSON.parse(localStorage.getItem(storageName));

  return user ? user : {};
};

export const clearStorage = async () => {
  const user = await localStorage.clear();

  return user ? user : {};
};

import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CardCount } from "../../../app/components/CardCount";
import { ENDPOINT } from "../../../app/constants/api";
import { getStorage } from "../../../app/utils/storage";
import toastMessage from "../../../app/utils/toastMessage";
import { LoadingSpinner } from "../LoadingSpinner";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { numberWithCommas } from "../../../app/utils/formatCurrency";

class AnalyticsHouseRent extends React.Component {
  state = {
    user: {},
    total_active_Parks: 0,
    total_collections: 0,
    total_number_vehicles: 0,
    route: "",
    total_park_collected: 0,
    chart: {
      rent_collections: [],
      park_tickets: [],
      park_revenues: [],
      monthly_rent: [],
      number_collections: [],
      average: [],
      weekly_trends: [],
      daily_park_level: [],
      park_id_type: [],
    },
    chart_collections: [],
    chart_number_collections: [],
    total_vehicles: 0,
    total_tickets: 0,
    total_devices: 0,
  };

  componentDidMount = async () => {
    const user = await getStorage();

    await this.setState({ user });

    await this.getTickets(true);
    await this.getRentRevenues(true);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      await this.getTickets(true);
      await this.getRentRevenues(true);
    }
  };

  returnFilters() {
    const { page, limit } = this.state;
    const { filters } = this.props;

    let request_body = {
      page,
      limit,
    };

    if (filters && filters.locations && filters.locations.length > 0) {
      let locations = [];

      for (let el of filters.locations) {
        locations.push(el.value);
      }
      request_body.park_ids = locations;
    }

    if (filters && filters.companies && filters.companies.length > 0) {
      let companies = [];

      for (let el of filters.companies) {
        companies.push(el.value);
      }
      request_body.organization_id = companies;
    }

    if (filters && filters.start_date && filters && filters.end_date) {
      request_body.start_date = new Date(filters.start_date).getTime();
      request_body.end_date = new Date(filters.end_date).getTime();
    }
    return request_body;
  }

  getTickets(isLoading) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    request_body.tag = "count";
    request_body.name = "HouseRent";

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_service_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoading: false,
          total_tickets: data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  getRentRevenues(isLoading) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    request_body.tag = "rank";
    request_body.order = "daily";
    request_body.name = "HouseRent";
    request_body.page = 1;
    request_body.limit = 100000000;

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_service_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        let chart_data = [],
          group_by_data = {},
          { chart_collections } = this.state;

        let total_revenues = 0;

        for (let el of data) {
          total_revenues += Number(el.price);

          const month_year =
            (el?.other.month &&
              el?.other?.year &&
              el.other.month + "/" + el.other.year) ||
            moment(el.createdAt).format("ll");

          if (!group_by_data[month_year]) {
            group_by_data[month_year] = {};
          }
          group_by_data[month_year] = el;
        }

        const labels = Object.keys(group_by_data);

        for (let el of Object.keys(group_by_data)) {
          chart_collections.push({
            label: el,
            value: group_by_data[el].price,
          });

          chart_data.push(Math.round(group_by_data[el].price));
        }

        let customLabels = labels.map((label, index) => `${label}} `);

        this.state.chart.rent_collections = {
          labels: customLabels,
          datasets: [
            {
              label: "House Rent",
              data: chart_data,
              borderRadius: 15,
              backgroundColor: "#05c605",
              borderColor: "rgba(0,0,0,1)",
              barPercentage: 0.2,
            },
          ],
        };

        this.setState({
          isLoadingRevenues: false,
          chart: this.state.chart,
          chart_collections,
          total_revenues: numberWithCommas(total_revenues),
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  handlePressCount(route) {
    this.setState({ route });
  }

  render() {
    if (this.state.route !== "") {
      return <Redirect to={this.state.route} />;
    }

    return (
      <div>
        <div className="row">
          <div className="col-6 col-md-3">
            <CardCount
              title="Total Number of Issued Tickets"
              total={
                this.state.isLoadingCollected ? "..." : this.state.total_tickets
              }
            />
          </div>
          <div className="col-6 col-md-3">
            <CardCount
              title="Total Revenues"
              total={this.state.isLoading ? "..." : this.state.total_revenues}
            />
          </div>
          {/* <div className="col-6 col-md-3">
            <CardCount
              title="Average Tickets Per Device"
              total={
                this.state.isLoading
                  ? "..."
                  : this.state.total_devices !== 0
                  ? `${Math.round(
                      this.state.total_tickets / this.state.total_devices
                    )}`
                  : "0"
              }
            />
          </div>
          <div className="col-6 col-md-3">
            <CardCount
              title="Average Tickets Per Vehicle"
              total={
                this.state.isLoading
                  ? "..."
                  : this.state.total_vehicles !== 0
                  ? `${Math.round(
                      this.state.total_tickets / this.state.total_vehicles
                    )}`
                  : "0"
              }
            />
          </div> */}
        </div>
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-md-12">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Montly House Rent</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingHouseRent ? (
                  <LoadingSpinner />
                ) : (
                  <Bar
                    data={this.state.chart.rent_collections}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(AnalyticsHouseRent);

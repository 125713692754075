import axios from "axios";
import React from "react";
import { ENDPOINT } from "../../../app/constants/api";
import toastMessage from "../../../app/utils/toastMessage";
import Table from "../Table/Table";
import "./styles.css";
import { getStorage } from "../../../app/utils/storage";
import { Home } from "../../screen/common";
import { connect } from "react-redux";
import exportPDF from "../../../app/utils/exportPDF";
import { CSVLink } from "react-csv";
import { Modal } from "../Modal";
import { ParkInfo } from "../Parks";
import { RentInfo } from "../Service";
import filtersData from "../../../app/utils/filtersData";

let copyData = [];

class UnPaidTenant extends React.Component {
  state = {
    payments: [],
    tenants: [],
    isLoading: true,
    user: {},
    page: 1,
    limit: 10,
    error: {},
    csvData: [],
    data: [],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getData = async (isLoading) => {
    await this.getTenants(isLoading);
    await this.getPayments(isLoading);
    await this.unpaidTenants(isLoading);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  returnTenantFilters() {
    const { filters } = this.props;

    let copyFilters = { ...filters };
    delete copyFilters.start_date;
    delete copyFilters.end_date;
    delete copyFilters.month;
    delete copyFilters.year;

    let request_body = {
      status: "tenant-active",
      filters: copyFilters,
      parkFilterType: "reference_park_id",
    };

    return filtersData(request_body);
  }

  getTenants = async (isLoading) => {
    const { user } = this.state;

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_user_info",
      data: {
        ...this.returnTenantFilters(),
        status: "tenant-active",
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return await axios(options)
      .then((res) => {
        console.log("unpaid", res.data);
        this.setState({
          tenants: res.data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  };

  returnFilters = () => {
    const { filters } = this.props;

    let request_body = {
      category: "rent",
      filters,
      parkFilterType: "reference_park_id",
    };

    return filtersData(request_body);
  };

  getPayments = async (isLoading) => {
    const { user } = this.state;

    this.setState({
      isLoading,
    });

    let url = ENDPOINT + "/get_service_payment_info";

    const options = {
      method: "POST",
      url,
      data: this.returnFilters(),
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return await axios(options)
      .then((res) => {
        this.setState({
          payments: res.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  };

  unpaidTenants() {
    const { payments, tenants } = this.state;

    let tenants_id = [],
      data = [];

    for (let tenant of tenants) {
      if (!tenants_id.includes(tenant.identifier)) {
        const tenantWithIdentifier =
          tenant?.organization_id?._id + "_" + tenant.identifier;

        tenants_id.push(tenantWithIdentifier);
      }
      console.log({ payments, tenant });

      if (payments.length > 0) {
        for (let payment of payments) {
          const paymentTenantWithIdentifier =
            tenant?.organization_id?._id + "_" + tenant?.identifier;

          if (
            tenant.identifier === payment.ticket_user_id.identifier &&
            payment.price < payment.service_id.price
          ) {
            tenant["unpaid_amount"] = payment.service_id.price - payment.price;
            tenant["target_price"] = payment.service_id.price;
            tenant["unpaid"] = true;
          }

          if (!tenants_id.includes(paymentTenantWithIdentifier)) {
            tenant["unpaid"] = true;
          }
        }
      } else {
        tenant["unpaid"] = true;
      }

      data.push(tenant);
    }

    this.setState({
      data,
    });

    copyData = data.slice(0);
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getPayments(true);
      }
    );
  }

  handleSearch(e) {
    const search_text = e.target.value;
    let array = [];

    this.setState({
      search_text,
    });

    for (let i = 0; i < copyData.length; i++) {
      if (
        JSON.stringify(copyData[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copyData[i]);
      }
    }

    this.setState({
      data: array,
    });
  }

  downloadExcel = () => {
    let csvData = [],
      { data } = this.state;

    for (let el of data) {
      delete el.__v;
      delete el._id;
      delete el.updatedAt;
      delete el.account;
      delete el.available;
      delete el.date;
      delete el.admin_account;
      delete el.other;
      delete el.status;
      delete el.unpaid;
      delete el.account;
      el.organization = el?.organization_id?.name;
      delete el.organization_id;

      el["Bus Park"] = el.reference_park_id.park_id;
      delete el.reference_park_id;

      csvData.push({
        ...el,
      });
    }
    setTimeout(() => {
      this.setState({
        csvData,
      });

      this.refs.csvDownload?.link.click();
    }, 2000);
  };

  downloadPDF = () => {
    const headers = this.returnTableHeaders();

    const { data } = this.state;

    exportPDF("Unpaid Tenants", headers, data);
  };

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onViewParkInfo(rent) {
    this.setState({
      showParkInfo: true,
      modalTitle: rent.reference_park_id?.park_id + "'s Info",
      selected_park: rent.reference_park_id,
    });
  }

  onViewServiceInfo(rent) {
    this.setState({
      showServiceInfo: true,
      modalTitle: "Service Info",
      selected_service: rent.service_id,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: "Company",
        key: "organization_id.name",
        type: "link",
        onPress: this.onViewParkInfo.bind(this),
      },
      {
        title: "Service",
        key: "identifier",
      },
      {
        title: "Target Price",
        key: "target_price",
        type: "amount",
      },
      {
        title: "Unpaid Amount",
        key: "unpaid_amount",
        type: "amount",
      },
      {
        title: "Bus park",
        key: "reference_park_id.park_id",
        type: "link",
        onPress: this.onViewParkInfo.bind(this),
      },
      {
        title: "Contact",
        key: "organization_id.other.phone_number",
      },
    ];

    return headers;
  }

  render() {
    return (
      <div>
        <Home month year location />
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={this.handleSearch.bind(this)}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          filters={[
            {
              type: "refresh",
              title: "Refresh",
              icon: "bx bx-refresh",
              onPress: () => this.getData(true),
            },
            {
              type: "export",
              title: "Export",
              button_type: "dropdown",
              icon: "bx bxs-download",
              options: [
                {
                  name: "PDF",
                  onPress: this.downloadPDF.bind(this),
                },
                {
                  name: "CSV",
                  onPress: this.downloadExcel.bind(this),
                },
              ],
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showParkInfo")}
          show={this.state.showParkInfo}
          title={this.state.modalTitle}
          size="lg"
        >
          <ParkInfo
            {...this.state.selected_park}
            handleCloseModal={this.handleCloseModal.bind(this, "showParkInfo")}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showServiceInfo")}
          show={this.state.showServiceInfo}
          title={this.state.modalTitle}
          size="lg"
        >
          <RentInfo
            data={this.state.selected_service}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showServiceInfo"
            )}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"House Rents" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(UnPaidTenant);

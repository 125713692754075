import moment from "moment";
import React from "react";
import {
  DairlyTotalParkingRevenues,
  DairlyTotalRentRevenues,
  DairlyTotalServiceFeeRevenues,
} from "../components/Chart";
import TopCompanies from "../components/TopCompanies/TopCompanies";
import TopParkUserRevenues from "../components/TopRevenues/TopParkUserRevenues";
import ServiceFeePerUser from "../components/TopServiceFee/PerUser";
import ServiceFeePerPark from "../components/TopServiceFee/PerPark";
import RentPerPark from "../components/TopRent/PerPark";
import RentPerUser from "../components/TopRent/PerUser";

import { Home } from "./common";

class ParkActivitiesScreen extends React.Component {
  state = {
    fromDate: moment().add(30, "d").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
  };
  render() {
    return (
      <div>
        <Home isCountCard date entity client month year />

        <div style={{ position: "relative", marginTop: "1rem" }}>
          <div className="row mb-4">
            <div className="col-md-12">
              <DairlyTotalParkingRevenues />
            </div>
            <div className="col-md-12" style={{ marginTop: 10 }}>
              <DairlyTotalRentRevenues />
            </div>
            {/* <div className="col-md-6" style={{ marginTop: 10 }}>
              <DairlyTotalServiceFeeRevenues />
            </div> */}
          </div>
          {/* <div className="row mb-4">
            <div className="col-md-6">
              <TopCompanies />
            </div>
            <div className="col-md-6">
              <TopParkUserRevenues />
            </div>
          </div> */}
          {/* <div className="row mb-4">
            <div className="col-md-6">
              <ServiceFeePerPark />
            </div>
            <div className="col-md-6">
              <ServiceFeePerUser />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-6">
              <RentPerPark />
            </div>
            <div className="col-md-6">
              <RentPerUser />
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default ParkActivitiesScreen;

import React from "react";
import { Home } from "./common";
import { Tabs } from "../components/Tabs";


class PendingScheduleScreen extends React.Component {
 
  render() {
    return (
      <div>
        <Home date location type level />
        <div>
          <Tabs
            options={[
              {
                title: "All",
                data: this.renderAll(),
              },
              {
                title: "On Time",
                data: this.renderOnTime(),
              },
              {
                title: "Over Due",
                data: this.renderOverDue(),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default PendingScheduleScreen;

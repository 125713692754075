import React from "react";
import { Home } from "./common";
import { Tickets } from "../components/CheckedVehicles";
import { Tabs } from "../components/Tabs";

class TicketsScreen extends React.Component {
  renderAll() {
    return <Tickets status="pending" />;
  }

  renderValid() {
    return <Tickets valid status="pending" />;
  }
  renderInvalid() {
    return <Tickets invalid status="pending" />;
  }
  render() {
    return (
      <div>
        <Home
          date
          location
          type
          level
          other={{
            thingType: "Traffic",
          }}
        />
        <div>
          <Tabs
            options={[
              {
                title: "Zebra Crossline",
                data: this.renderValid(),
              },
              {
                title: "Speed",
                data: this.renderInvalid(),
              },
              {
                title: "All",
                data: this.renderAll(),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default TicketsScreen;

import React from "react";
import { Link } from "react-router-dom";
import Notification from "./Notification";

class SubMenusContainer extends React.Component {
  render() {
    const { item, pathname, index, menuParent } = this.props;

    let split_path = pathname.split("/");

    let last_route = split_path[split_path.length - 1];

    const routeName = item.name
      .replaceAll("&", "")
      .replaceAll(" ", "_")
      .toLowerCase();

    return (
      <Link
        key={index}
        to={`/dashboard/${item.route}`}
        title={item.name}
        className={last_route === routeName && "active"}
      >
        <div className="sidebar-item-icon">
          <i className={item.icon}></i>
        </div>
        <span>{item.name}</span>
        <Notification name={item.name} group={menuParent.group} />
      </Link>
    );
  }
}

export default SubMenusContainer;

import React from "react";
import { Clients, Employees } from "../components/Client";
import { Tabs } from "../components/Tabs";

class ClientScreen extends React.Component {
  renderClients() {
    return <Clients />;
  }

  renderUser() {
    return <Employees />;
  }

  render() {
    return (
      <div>
        <div>
          <Tabs
            options={[
              {
                title: "Clients",
                data: this.renderClients(),
              },
              {
                title: "User",
                data: this.renderUser(),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default ClientScreen;

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

//screens
import LoginScreen from "./app/screen/LoginScreen";
import SplashScreen from "./app/screen/SplashScreen";
import DashboardScreen from "./app/screen/DashboardScreen";
import ApplicationScreen from "./app/screen/ApplicationScreen";
import LandingPageScreen from "./app/screen/LandingPageScreen";

const createBrowserHistory = require("history").createBrowserHistory;

function App() {
  return (
    <div className="App">
      <Router history={createBrowserHistory({ queryKey: false })}>
        <Switch>
          <Route exact path="/" component={LandingPageScreen} />
          <Route exact path="/login" component={LoginScreen} />
          <Route
            exact
            path="/dashboard/home/applications"
            component={ApplicationScreen}
          />
          <Route path="/dashboard" component={DashboardScreen} />
        </Switch>
      </Router>
      <ToastContainer
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
      />
    </div>
  );
}

export default App;

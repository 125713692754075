import React from "react";
import { ParkingRevenues } from "../components/ParkingRevenues";

class CompanyTicketScreen extends React.Component {
  render() {
    return (
      <div>
        <ParkingRevenues all />
      </div>
    );
  }
}

export default CompanyTicketScreen;

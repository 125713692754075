import React from 'react';
import './styles.css';

const ImageView = props => {
  console.log('====================================');
  console.log(props);
  console.log('====================================');
  return (
    <div
      className="imageview-container"
      style={{
        backgroundColor: '#000',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <img
        src={props.filepath}
        style={{
          aspectRatio: 3 / 2,
          width: '80%',
          objectFit: 'contain',
        }}
      />
    </div>
  );
};

export default ImageView;

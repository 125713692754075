import React from "react";
import "./styles.css";
import { Button } from "../../../app/components/Button";
import { Location, DateRange } from ".";
import UserAccounts from "./UserAccounts";
import Companies from "./Companies";
import Vehicles from "./Vehicles";
import Month from "./Month";
import Year from "./Year";
import PaymentMethod from "./PaymentMethod";

const Header = (props) => {
  const { filters } = props;
  if (!filters) {
    return <></>;
  }
  return (
    <div className="main-filters d-grid  d-md-flex align-items-center">
      <div className={`row main-filters`}>
        {filters.map((item, i) => {
          return (
            <div
              key={i}
              className={`${item.isDropdown && "dropdown"}  col-auto`}
            >
              <Button
                className={`btn-default bordered btn-sm ${
                  item.isDropdown && " dropdown-toggle dropdown-toggle-split"
                }`}
                text={item.name}
                selected={
                  item.selected ? item.selected : item.isDropdown ? ["All"] : ""
                }
                autoCloseType={item.autoCloseType}
                id={item.clickBehaviorId}
                withOpacity
              />
              {item.isDropdown &&
                (item.name === "location" || item.name == "Park Station") && (
                  <ul
                    className="dropdown-menu col-xs-12"
                    aria-labelledby="dropdownMenuClickableInside"
                  >
                    <Location />
                  </ul>
                )}
              {item.isDropdown && item.name === "company" && (
                <ul
                  className="dropdown-menu col-xs-12"
                  aria-labelledby="dropdownMenuClickableInside"
                >
                  <Companies />
                </ul>
              )}

              {item.isDropdown && item.name === "paymentmethod" && (
                <ul
                  className="dropdown-menu col-xs-12"
                  aria-labelledby="dropdownMenuClickableInside"
                >
                  <PaymentMethod />
                </ul>
              )}
              {item.isDropdown && item.name === "vehicle" && (
                <ul
                  className="dropdown-menu col-xs-12"
                  aria-labelledby="dropdownMenuClickableInside"
                >
                  <Vehicles />
                </ul>
              )}
              {item.isDropdown && item.name === "user_account" && (
                <ul
                  className="dropdown-menu col-xs-12"
                  aria-labelledby="dropdownMenuClickableInside"
                >
                  <UserAccounts />
                </ul>
              )}
              {item.isDropdown && item.name === "date" && (
                <ul
                  className="dropdown-menu col-xs-12"
                  aria-labelledby="dropdownMenuClickableInside"
                >
                  <DateRange />
                </ul>
              )}
              {item.isDropdown && item.name === "month" && (
                <ul
                  className="dropdown-menu col-xs-12"
                  aria-labelledby="dropdownMenuClickableInside"
                >
                  <Month />
                </ul>
              )}
              {item.isDropdown && item.name === "year" && (
                <ul
                  className="dropdown-menu col-xs-12"
                  aria-labelledby="dropdownMenuClickableInside"
                >
                  <Year />
                </ul>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Header;

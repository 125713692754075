import axios from "axios";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CardCount } from "../../../app/components/CardCount";
import { Header } from "../../components/Header";
import { ENDPOINT } from "../../../app/constants/api";
import { getStorage } from "../../../app/utils/storage";
import toastMessage from "../../../app/utils/toastMessage";
import filtersData from "../../../app/utils/filtersData";
import { numberWithCommas } from "../../utils/formatnNumber";

class Home extends React.Component {
  state = {
    user: {},
    route: "",
    users: [],
    total_water_available: 0,
    total_water_used: 0,
    total_vehicles: 0,
    total_electricity: "0%",
    total_air_quality: "99%",
    total_number_tanks: 0,
    total_water: 0,
  };

  componentDidMount = async () => {
    const user = await getStorage();

    await this.setState({ user });

    this.getTotalWater(true);
    this.getVehicleCheckedIn(true);
    this.getElectricityStatus(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getTotalWater(true);
      this.getVehicleCheckedIn(true);
      this.getElectricityStatus(true);
    }
  }

  returnFilters() {
    const { filters } = this.props;

    let request_body = filtersData({ filters });

    return request_body;
  }

  getTotalWater(isLoadingWater) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    request_body.target = "all";

    delete request_body.target;
    request_body.tag = "last";

    this.setState({
      isLoadingWater,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_water_level_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res.data;

        let total = 0;

        for (let el of data) {
          total += Math.round(Number(el.last.level));
        }

        this.setState({
          isLoadingWater: false,
          total_water: total,
          total_number_tanks: data.length,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingWater: false });
      });
  }

  getVehicleCheckedIn(isLoadingVehicleIn) {
    const { user } = this.state;

    this.setState({
      isLoadingVehicleIn,
    });

    let request_body = this.returnFilters();

    request_body.tag = "count";

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_vehicle_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoadingVehicleIn: false,
          total_vehicles: data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingVehicleIn: false });
      });
  }

  getElectricityStatus(isLoadingElectricity) {
    const { user } = this.state;
    const { filters } = this.props;

    this.setState({
      isLoadingElectricity,
    });

    let request_body = filtersData({ filters });

    request_body.target = "last";

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_electricity_status_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res.data;

        const total =
          data.reduce((acc, cur) => acc + cur.last.level, 0) / data.length;

        this.setState({
          isLoadingElectricity: false,
          total_electricity: total + "%",
        });
      })
      .catch((error) => {
        this.setState({
          isLoadingElectricity: false,
        });
      });
  }

  handlePressCount(route) {
    this.setState({ route });
  }

  render() {
    if (this.state.route !== "") {
      return <Redirect to={this.state.route} />;
    }

    let list_filters = [];

    if (this.props.location) {
      list_filters.push({
        name: "location",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.locations &&
          this.props.filters.locations.length > 0
            ? this.props.filters.locations
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.user_account) {
      list_filters.push({
        name: "user_account",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.users &&
          this.props.filters.users.length > 0
            ? this.props.filters.users
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.thing) {
      list_filters.push({
        name: "thing",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.thing &&
          this.props.filters.thing.length > 0
            ? this.props.filters.thing
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.date) {
      list_filters.push({
        name: "date",
        position: "right",
        isDropdown: true,
        selected: [
          moment(this.props.filters.start_date).format("DD-MM-YYYY"),
          moment(this.props.filters.end_date).format("DD-MM-YYYY"),
        ],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    return (
      <div>
        <Header filters={list_filters} other={this.props.other} />
        <hr />
        {this.props.isCountCard && (
          <div className="row">
            <div className="col-6 col-md-3">
              <CardCount
                title="Total Water Available"
                icon="bxs-droplet"
                total={
                  this.state.isLoadingWater
                    ? "..."
                    : numberWithCommas(
                        parseInt(this.state.total_water / this.state.total_number_tanks)
                      ) + " L"
                }
              />
            </div>
            <div className="col-6 col-md-3">
              <CardCount
                title="Number of Checked Vehicles"
                total={
                  this.state.isLoadingVehicleIn
                    ? "..."
                    : numberWithCommas(this.state.total_vehicles)
                }
                icon="bxs-car"
              />
            </div>
            <div className="col-6 col-md-3">
              <CardCount
                title="Average Air Quality"
                total={this.state.total_air_quality}
                icon="bxs-grid-alt"
              />
            </div>
            <div className="col-6 col-md-3">
              <CardCount
                title="Monitored Electricity Status"
                total={
                  this.state.isLoadingElectricity
                    ? "..."
                    : this.state.total_electricity
                }
                icon="bxs-bolt"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(Home);

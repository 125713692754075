import React from "react";
import { Button } from "../../../app/components/Button";
import { Input, Select } from "../../../app/components/Input";

import "./styles.css";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { getStorage } from "../../../app/utils/storage";
import { availables, statuses } from "../../../app/constants/strings";
import formatSelectData from "../../../app/utils/formatSelectData";
import vehicleTags from "../../constants/vehicleTags";
class NewPark extends React.Component {
  state = {
    email: "",
    password: "",
    error: {},
    isSubmitting: false,
    user: {},
    _id: "",
    devices: [],
    available: availables[0],
    status: statuses[0],
    number_plate: "",
    number_plate: "",
    tag: "",
    drivers: [],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.getDevices(true);
    this.getDrivers(true);

    if (this.props._id && this.props._id !== "") {
      let driver = this.props.driver.map((el) => {
        return {
          value: el._id,
          label: el.name,
        };
      });

      let tag = {
        value: this.props.tag,
        label: this.props.tag,
      };

      this.setState({
        ...this.props,
        device: {
          label: this.props?.device_id?.device_id,
          value: this.props?.device_id?._id,
        },
        status: {
          label: this.props.status,
          value: this.props.status,
        },
        available: {
          label: this.props.available + "",
          value: this.props.available + "",
        },
        driver,
        tag,
      });
    }
  };

  populateSelect(data, label, value, resultIsArray) {
    let current_item = resultIsArray ? [] : {};

    for (let i = 0; i < data.length; i++) {
      if (resultIsArray && value.includes(data[i].value)) {
        current_item.push({
          label: data[i][label],
          value: data[i].value,
        });
      } else if (data[i].value === value) {
        current_item = data[i];
      }
    }

    return current_item;
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getDevices(isFetchingDevices, search_device) {
    this.setState({
      isFetchingDevices,
    });

    const { user } = this.state;

    let body = {
      page: 1,
      limit: 10,
    };

    if (search_device && search_device !== "") {
      body.search = search_device;
    }

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_device_info",
      data: {
        ...body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options).then((res) => {
      const devices = formatSelectData(res.data.data, "device_id", "_id");

      this.setState({
        devices,
        isFetchingDevices: false,
      });

      return devices;
    });
  }

  getDrivers(isLoadingDrivers, search) {
    const { user } = this.state;

    this.setState({
      isLoadingDrivers,
    });

    let url = ENDPOINT + "/get_driver_info";

    let body = {
      page: 1,
      limit: 10,
    };

    if (search && search !== "") {
      body.search = search;
    }

    const options = {
      method: "POST",
      url,
      data: {
        ...body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let { data } = res.data;

        if (!data) {
          data = res.data;
        }

        const drivers = formatSelectData(res.data.data, "name", "_id");

        this.setState({
          drivers,
          isLoadingDrivers: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoadingDrivers: false,
        });

        toastMessage("error", error);
      });
  }

  onChangeText = async (name, e) => {
    let error = this.state.error;
    let value = e.target ? e.target.value : e;

    delete error[name];

    await this.setState({
      error,
      [name]: value,
    });
  };

  validateForm() {
    let { number_plate, device, tag, error } = this.state;

    if (number_plate === "") {
      error.number_plate = "Number plate is required";
    }

    if (!device) {
      error.device = "Please select device";
    }

    if (tag === "") {
      error.device = "Vehicle type is required";
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const {
      number_plate,
      device,
      tag,
      user,
      _id,
      error,
      status,
      available,
      driver,
    } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let _driver = driver ? driver?.map((el) => el.value) : [];
      let _tag = tag ? tag.value : "";

      let url = ENDPOINT + "/add_motor_vehicle_info";

      let data = {
        number_plate,
        tag: _tag,
        device_id: device.value,
        available: available.value,
        driver: _driver,
        status: status.value,
      };

      if (user.ref_account) {
        data.ref_account = user.ref_account;
      }

      if (user.ref_subaccount) {
        data.ref_subaccount = user.ref_subaccount;
      }

      if (_id !== "") {
        url = ENDPOINT + "/update_motor_vehicle_info";
        data.id = _id;
      }

      const options = {
        method: "POST",
        url,
        data,
        headers: {
          authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
            number_plate: "",
            device_id: undefined,
            tag: undefined,
          });

          toastMessage(
            "success",
            `Data ${this.state._id !== "" ? "updated" : "created"} successfully`
          );

          this.props.handleCloseModal();
          this.props.getData(true);
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("error", error);
        });
    }
  };

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-">
              <Input
                label="Number Plate:"
                required
                className="form-control-lg"
                value={this.state.number_plate}
                onChange={(e) => this.onChangeText("number_plate", e)}
                error={this.state.error.number_plate}
              />
            </div>
            <div className="col-md-12">
              <Select
                defaultOptions={this.state.devices}
                label="Device:"
                className="form-control-lg "
                value={this.state.device}
                onChange={(e) => this.onChangeText("device", e)}
                error={this.state.error.device}
                required
                async
                isLoading={this.state.isFetchingDevices}
                onInputChange={(e) => this.onChangeText("search_device", e)}
                loadOptions={(inputValue) => this.getDevices(true, inputValue)}
              />
            </div>

            <div className="col-md-12">
              <Select
                options={vehicleTags}
                label="Vehicle Type"
                className="form-control-lg "
                value={this.state.tag}
                onChange={(e) => this.onChangeText("tag", e)}
                error={this.state.error.tag}
                required
              />
            </div>
            <div className="col-md-12">
              <Select
                defaultOptions={this.state.drivers}
                label="Driver:"
                className="form-control-lg "
                value={this.state.driver}
                onChange={(e) => this.onChangeText("driver", e)}
                error={this.state.error.driver}
                async
                isMulti
                isLoading={this.state.isLoadingDrivers}
                onInputChange={(e) => this.onChangeText("search_driver", e)}
                loadOptions={(inputValue) => this.getDrivers(true, inputValue)}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={availables}
                label="Available:"
                className="form-control-lg "
                value={this.state.available}
                onChange={(e) => this.onChangeText("available", e)}
                error={this.state.error.available}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={statuses}
                label="Status"
                className="form-control-lg "
                value={this.state.status}
                onChange={(e) => this.onChangeText("status", e)}
                error={this.state.error.status}
                isDisabled={!this.state.status}
                required
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="card-footer d-flex align-content-center justify-content-end gap-3">
          <Button
            text="Close"
            className="btn-default btn-lg border"
            onPress={this.props.handleCloseModal}
          />
          <Button
            isSubmitting={this.state.isSubmitting}
            text="Submit"
            className="btn-primary btn-lg"
            onPress={this.onSubmit.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default NewPark;

import React from "react";
import Table from "../../../app/components/Table/Table";
import "./styles.css";
import axios from "axios";
import { connect } from "react-redux";
import { ENDPOINT } from "../../../app/constants/api";
import { getStorage } from "../../../app/utils/storage";
import toastMessage from "../../../app/utils/toastMessage";
import { StockInfo } from "../Stocks";
import { Modal } from "../../../app/components/Modal";
import "./styles.css";

let copyData = [];
class DeviceActivities extends React.Component {
  state = {
    data: [],
    limit: 10,
    page: 1,
    isLoading: true,
    selected_park: {},
    user: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getData(isLoading) {
    const { user, page, limit } = this.state;
    const { filters } = this.props;

    this.setState({
      isLoading,
    });

    let request_body = {
      target: "last",
      page,
      limit,
    };

    if (filters && filters.locations && filters.locations.length > 0) {
      request_body.sectors = filters.locations;
    }

    if (filters && filters.start_date && filters && filters.end_date) {
      request_body.start_date = new Date(filters.start_date).getTime();
      request_body.end_date = new Date(filters.end_date).getTime();
    }

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_current_device_status_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data, count } = res.data;
        let result = [];

        for (let i = 0; i < data.length; i++) {
          result.push({
            ...data[i].last,
            device_status: data[i].last.device_status.toLowerCase(),
            bin: data[i].bin[0],
          });
        }

        this.setState({
          data: result,
          isLoading: false,
          totalPageCount: count,
        });

        copyData = result.slice(0);
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({ search_text });

    let array = [];

    for (let i = 0; i < copyData.length; i++) {
      if (
        JSON.stringify(copyData[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copyData[i]);
      }
    }

    this.setState({ data: array });
  }

  handleOpenModal(modal, selected_park) {
    this.setState({
      [modal]: true,
      selected_park: selected_park.bin ? selected_park.bin : {},
      modalTitle: selected_park.bin.bin_id
        ? "Bus station #" + selected_park.bin.bin_id
        : "",
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    return (
      <div className="card">
        <div className="card-header">
          <h3>Devices Activities</h3>
        </div>
        <div className="card-body">
          <Table
            data={this.state.data}
            no_bordered
            isLoading={this.state.isLoading}
            style={{ marginBottom: 0 }}
            headers={[
              {
                title: "Status",
                key: "device_status",
                type: "Device_status",
              },
              {
                title: "Timestamp",
                key: "createdAt",
                isMoment: true,
                formatTime: "lll",
              },
              {
                title: "Last seen",
                key: "updatedAt",
                isMoment: true,
                formatTime: "lll",
              },
              {
                title: "Bus Station",
                key: "park_id.park_id",
                type: "link",
                onPress: this.handleOpenModal.bind(this, "showModal"),
              },
              {
                title: "Device Id",
                key: "serial_number",
              },
            ]}
            actions={[
              {
                name: "Report",
                onPress: null,
              },
            ]}
          />
        </div>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          size="lg"
        >
          <StockInfo
            {...this.state.selected_bin}
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(DeviceActivities);

import React from 'react';
import {Home} from './common';
import {CheckedVehicles} from '../components/CheckedVehicles';
import {Tabs} from '../components/Tabs';

class CheckedVehicleScreen extends React.Component {
  renderAll() {
    return <CheckedVehicles />;
  }

  renderValid() {
    return <CheckedVehicles valid />;
  }

  renderInvalid() {
    return <CheckedVehicles invalid />;
  }
  render() {
    return (
      <div>
        <Home
          date
          location
          type
          level
          other={{
            thingType: 'Traffic',
          }}
        />
        <div>
          <Tabs
            options={[
              {
                title: 'Validated Plates',
                data: this.renderValid(),
              },
              {
                title: 'Non-recognized Plates',
                data: this.renderInvalid(),
              },
              {
                title: 'All',
                data: this.renderAll(),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default CheckedVehicleScreen;

import React from "react";
import { Link } from "react-router-dom";
import Table from "../Table/Table";
import "./styles.css";
import { getStorage } from "../../../app/utils/storage";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { connect } from "react-redux";
import { Modal } from "../Modal";
import filtersData from "../../../app/utils/filtersData";
import { VehicleInfo } from "../Vehicle";

let copyData = [];
class HighAverageSpeed extends React.Component {
  state = {
    data: [],
    user: {},
    selected_data: {},
    limit: 5,
    page: 1,
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  returnFilters() {
    const { page, limit } = this.state;
    const { filters } = this.props;

    let request_body = {
      target: "rank",
      limit,
      page,
      filters,
    };

    return filtersData(request_body);
  }

  getData(isLoading) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    if (this.props.all) {
      request_body.limit = 10;
    }

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_location_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res?.data?.data || res.data;

        const arrData = data.map((el) => {
          return {
            ...el,
            vehicle: el.vehicle[0],
          };
        });

        this.setState({
          isLoading: false,
          data: arrData,
        });

        copyData = arrData.slice(0);
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  handleOpenModal(modal, selected_data) {
    this.setState({
      [modal]: true,
      modalTitle: "#" + selected_data.vehicle.number_plate,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleSearch(e) {
    const search_text = e.target.value;
    let arrDay = [];

    this.setState({ search_text });

    for (let el of copyData) {
      if (
        JSON.stringify(el).toLowerCase().indexOf(search_text.toLowerCase()) !==
        -1
      ) {
        arrDay.push(el);
      }
    }

    this.setState({
      data: arrDay,
    });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  render() {
    return (
      <div className="card" style={this.props.styles}>
        {!this.props.all && (
          <div className="card-header">
            <h3>Top 5 High Average Speed</h3>
          </div>
        )}
        <div className="card-body" style={{ paddingBottom: 0 }}>
          <Table
            data={this.state.data}
            no_bordered
            style={{ marginBottom: 0 }}
            isLoading={this.state.isLoading}
            isSearch={this.props.all}
            handleSearch={this.handleSearch.bind(this)}
            search_text={this.state.search_text}
            totalPageCount={this.state.totalPageCount}
            handlePagination={this.handlePagination.bind(this)}
            headers={[
              {
                title: "Number Plate",
                key: "_id",
                type: "link",
                onPress: (item) =>
                  this.handleOpenModal("showVehicleModal", item),
              },
              {
                title: "Average Speed",
                key: "average_speed",
              },
            ]}
          />
        </div>
        {this.state.data.length > 0 && !this.props.all && (
          <center className="card-footer border-top">
            <Link
              to="/dashboard/fleet_management/more_high_average_data"
              className="text-primary"
            >
              <b>View More</b>
            </Link>
          </center>
        )}
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showVehicleModal")}
          show={this.state.showVehicleModal}
          title={this.state.modalTitle}
          size="lg"
        >
          <VehicleInfo
            {...this.state.selected_data.vehicle}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showVehicleModal"
            )}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return { filters };
};

export default connect(mapStateToProps)(HighAverageSpeed);

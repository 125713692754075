import React from "react";
import { LoadingSpinner } from "../LoadingSpinner";
import { TimeAgo } from "../TimeAgo";
import moment from "moment";
import handleFilterDates from "../../utils/handleFilterDates";
import { ENDPOINT } from "../../../app/constants/api";
import { connect } from "react-redux";
import axios from "axios";
import { getStorage } from "../../../app/utils/storage";
import { Input } from "../../../app/components/Input";
import { Button } from "../../../app/components/Button";
import toastMessage from "../../../app/utils/toastMessage";
class GeneralInfo extends React.Component {
  state = {
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    data: [],
    isLoading: true,
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    //set parent dates on load
    this.setState({
      ...this.props.dates,
    });

    await this.getData(true);
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  onChangeText(name, e) {
    this.setState({
      [name]: e.target.value,
    });
  }

  returnFilters() {
    const { selected_data } = this.props;
    const { vehicle_identity } = selected_data;
    const { start_date, end_date } = this.state;

    let request_body = {
      plate: vehicle_identity.plate,
      similar: true,
      ...handleFilterDates({ start_date, end_date }),
    };

    return request_body;
  }

  getData(isLoading) {
    const { user } = this.state;

    this.setState({
      isLoading,
    });

    let request_body = this.returnFilters();

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_vehicle_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res.data.data;
        console.log(data);
        const { selected_data } = this.props;

        let dataSorted = [];

        for (let el of data) {
          if (
            selected_data.vehicle_identity.plate !== el.vehicle_identity.plate
          ) {
            dataSorted.push(el);
          } else {
            dataSorted.unshift(el);
          }
        }

        this.setState({
          data: dataSorted,
          isLoading: false,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  renderFilters() {
    return (
      <div style={{ marginBottom: 15 }}>
        <div className="row">
          <div className="col-md-5">
            <Input
              label="Start Date"
              onChange={(e) => this.onChangeText("start_date", e)}
              value={this.state.start_date}
              type="date"
            />
          </div>
          <div className="col-md-5">
            <Input
              label="End Date"
              onChange={(e) => this.onChangeText("end_date", e)}
              value={this.state.end_date}
              type="date"
            />
          </div>
          <div className="col-md-1" style={{ marginTop: 5 }}>
            <label></label>
            <Button
              icon="bx-search"
              className="btn-primary"
              onPress={() => this.getData(true)}
            />
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderFooter() {
    return (
      <div
        style={{
          display: "flex",
          gap: "1.5rem",
          paddingTop: "1rem",
        }}
      >
        <div style={{ flex: 1 }} />
        <div className="d-flex gap-1">
          <Button
            className="btn-default"
            text="Reject Ticket"
            onPress={this.handleCancelTicket.bind(this)}
            isSubmitting={this.state.rejecting}
          />
          <Button
            className="btn-primary"
            text="Approve Ticket"
            onPress={this.handleValidateTicket.bind(this)}
            isSubmitting={this.state.approving}
          />
        </div>
      </div>
    );
  }

  updateTicket(status) {
    const { user } = this.state;
    const { selected_data } = this.props;
    const { _id } = selected_data;

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_vehicle_info",
      data: {
        id: _id,
        status,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    let loader = status === "rejected" ? "rejecting" : "approving";

    this.setState({ [loader]: true });

    axios(options)
      .then((data) => {
        this.setState({ [loader]: false });
        toastMessage("success", "Data updated successfully");
        this.props.handleCloseModal();
      })
      .catch((err) => {
        this.setState({ [loader]: false });
        toastMessage("error", "Error updating vehicle info");
      });
  }

  handleValidateTicket() {
    if (window.confirm("Are you sure you want to approve this ticket?")) {
      this.updateTicket("validated");
    }
  }

  handleCancelTicket() {
    if (window.confirm("Are you sure you want to reject this ticket?")) {
      this.updateTicket("rejected");
    }
  }

  render() {
    const props = this.props;
    return (
      <div>
        {this.renderFilters()}
        {this.state.isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="vehicleCardInfoImages">
              <div className="row">
                {this.state.data.map((item, i) => {
                  return (
                    <div className="col-md-6 vehicleCardInfoImage" key={i}>
                      <div className="card">
                        <img src={item.vehicle_identity.full_image} />
                        <div className="card-body">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <h3
                              style={{
                                flex: 1,
                                color:
                                  props.selected_data.vehicle_identity.plate !==
                                    item.vehicle_identity.plate && "red",
                              }}
                            >
                              {item.vehicle_identity.plate}
                            </h3>
                            <img
                              src={item.vehicle_identity.plate_image}
                              style={{
                                width: 100,
                                height: 40,
                                borderRadius: 8,
                                border: "2px solid #777",
                                aspectRatio: 3 / 2,
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                          <div className="vehicleCardInfoImageInfo">
                            <p>
                              <b>
                                {moment(item.createdAt).format(
                                  "DD-MM-YYYY HH:mm:ss"
                                )}
                              </b>
                            </p>
                            <p>
                              <small>{item.createdAt}</small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {this.renderFooter()}
          </>
        )}
      </div>
    );
  }
}

const mapStateProps = (state) => {
  const { filters } = state.Filters;
  return {
    filters,
  };
};

export default connect(mapStateProps)(GeneralInfo);

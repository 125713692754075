import React from "react";
import "./styles.css";
import { Checkbox, Input, Select } from "../../../app/components/Input";
import { onFilter } from "../../../app/action/Filters";
import { connect } from "react-redux";

let copyMonths = [];

const months = [
  {
    label: "January",
    value: "1",
  },
  {
    label: "February",
    value: "2",
  },
  {
    label: "March",
    value: "3",
  },
  {
    label: "April",
    value: "4",
  },
  {
    label: "May",
    value: "5",
  },
  {
    label: "June",
    value: "6",
  },
  {
    label: "July",
    value: "7",
  },
  {
    label: "August",
    value: "8",
  },
  {
    label: "September",
    value: "9",
  },
  {
    label: "October",
    value: "10",
  },
  {
    label: "November",
    value: "11",
  },
  {
    label: "December",
    value: "12",
  },
];

class MonthList extends React.Component {
  state = {
    months,
    selected_month: [],
    isLoading: false,
    error: {},
    filters: {},
  };

  componentDidMount = async () => {
    copyMonths = months.slice(0);

    this.setState({ filters: this.props.filters });
  };

  handleCheck(item) {
    let selected_month = this.state.selected_month;

    let index = selected_month.indexOf(item);

    if (index !== -1) {
      selected_month.splice(index, 1);
    } else {
      selected_month.push(item);
    }

    this.setState(
      {
        selected_month,
      },
      () => {
        this.props.dispatch(onFilter({ month: selected_month }));
      }
    );
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({ search_text });

    let array = [];

    for (let i = 0; i < copyMonths.length; i++) {
      if (
        copyMonths[i].toUpperCase().indexOf(search_text.toUpperCase()) !== -1
      ) {
        array.push(copyMonths[i]);
      }
    }

    this.setState({
      months: array,
    });
  }

  onResetCheck() {
    this.props.dispatch(onFilter({ month: [] }));

    this.setState({
      selected_month: [],
      search_text: "",
      months: copyMonths,
    });
  }

  render() {
    return (
      <div className="card cdropdown-container">
        <div className="card-body">
          <Input
            placeholder="Search month..."
            className="form-control-sm"
            value={this.state.search_text}
            onChange={this.handleSearch.bind(this)}
          />
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              className="cdropdown-content"
            >
              <h1 className="separator-title">Months</h1>
              {this.props.filters &&
                this.props.filters.month &&
                this.props.filters.month.length > 0 && (
                  <a
                    href="#"
                    className="text-danger"
                    onClick={this.onResetCheck.bind(this)}
                  >
                    <b>{`Clear (${this.props?.filters?.month.length})`}</b>
                  </a>
                )}
            </div>
            {this.state.months.map((item, i) => {
              let checked = false;

              if (
                this.props.filters.month &&
                this.props.filters.month.filter(
                  (el) => Number(el.value) === Number(item.value)
                ).length > 0
              ) {
                checked = true;
              }

              return (
                <div className="cdropdown-item" key={i}>
                  <Checkbox
                    name={item.label}
                    handleCheck={this.handleCheck.bind(this, item)}
                    checked={checked}
                  />
                </div>
              );
            })}
          </>
        </div>
      </div>
      // </form>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return {
    filters,
  };
};

export default connect(mapStateToProps)(MonthList);

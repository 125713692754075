import React from "react";
import Map from "../components/Map/Map";
import { getStorage } from "../../app/utils/storage";
import { ENDPOINT } from "../../app/constants/api";
import axios from "axios";
import { connect } from "react-redux";
import filtersData from "../../app/utils/filtersData";
import { Home } from "./common";
import getAddressName from "../../app/utils/getAddressName";
import Geocode from "react-geocode";

import { REACT_APP_MAPS_API_KEY } from "../../app/constants/strings";

Geocode.setApiKey(REACT_APP_MAPS_API_KEY);
class TripLogScreen extends React.Component {
  state = {
    user: {},
    data: [],
    vehicleLocations: {},
    isLoading: true,
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.getInitData(true);

    const { vehicles } = this.props.filters;

    if (vehicles && vehicles.length > 0) {
      this.getTrips(true);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.getInitData(true);
      this.getTrips(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  returnFilters() {
    const { filters } = this.props;

    let params = {
      target: "last",
      filters,
      vehicleField: "number_plate",
    };

    let request_body = filtersData(params);

    return request_body;
  }

  getInitData(isLoading) {
    const { user } = this.state;

    this.setState({ isLoading });

    let request_body = this.returnFilters();

    delete request_body.end_createdAt;
    delete request_body.start_createdAt;

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_location_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then(async (res) => {
        let response_data = res.data,
          data = [];

        for (let i = 0; i < response_data.length; i++) {
          const geoCodedAddress = await Geocode.fromLatLng(
            response_data[i].last.latitude,
            response_data[i].last.longitude
          );
          data.push({
            ...response_data[i].last,
            geoCodedAddress: getAddressName(geoCodedAddress),
          });
        }

        this.setState({
          isLoading: false,
          data,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  getTrips(isLoading) {
    const { user } = this.state;

    this.setState({ isLoading });
    let filter_body = this.returnFilters();

    delete filter_body.target;

    if (filter_body.number_plates && filter_body.number_plates.length === 0) {
      this.setState({
        vehicleLocations: [],
      });

      return;
    }

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_location_info",
      data: filter_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then(async (res) => {
        let vehicleLocations = res.data.map((el) => {
          return [el.latitude, el.longitude];
        });

        this.setState({
          vehicleLocations: {
            positions: vehicleLocations,
            color: "rgba(247, 2, 2, 0.5)",
          },
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <div>
        <Home isCountCard location date company mapinsight vehicle />
        <div
          style={{
            position: "relative",
            marginTop: "1rem",
          }}
        >
          <Map
            isLoading={this.state.isLoading}
            vehicleLocations={this.state.vehicleLocations}
            data={this.state.data}
            zoom={14}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(TripLogScreen);

import React from "react";
import { Alerts } from "../components/Alerts";

class IncidentWasteBinLevelScreen extends React.Component {
  render() {
    return (
      <div>
        <Alerts status="resolved" type="bin_level" />
      </div>
    );
  }
}

export default IncidentWasteBinLevelScreen;

import React from "react";
import Alerts from "../components/Alerts/Alerts";

class AlertWasteBinLevelScreen extends React.Component {
  render() {
    return (
      <div>
        <Alerts
          status="unresolved"
          type="stock_level"
          bin_id={
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.bin_id
              ? this.props.match.params.bin_id
              : undefined
          }
        />
      </div>
    );
  }
}

export default AlertWasteBinLevelScreen;

import React from "react";
import "./styles.css";
import { Line } from "react-chartjs-2";
import { getStorage } from "../../../app/utils/storage";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { connect } from "react-redux";
import { LoadingSpinner } from "../LoadingSpinner";
import filtersData from "../../../app/utils/filtersData";

const options = {
  maintainAspectRatio: false,
  animation: false,
  offset: true,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          min: 0,
        },
      },
    ],
  },
};

class TotalParkingRevenues extends React.Component {
  state = {
    data: [],
    user: {},
    selected_alert: {},
    user: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getRevenues(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getRevenues(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  returnFilters() {
    const { page, limit } = this.state;
    const { filters } = this.props;

    let request_body = {
      status: this.props.status,
      ticket_status: this.props.ticket_status,
      filters,
      page,
      limit,
      parkFilterType: "park_id",
      vehicleFilterType: "ticket_user_ids",
    };

    delete request_body.month;
    delete request_body.year;

    return filtersData(request_body);
  }

  getRevenues(isLoading) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    request_body.tag = "trend";
    request_body.order = "daily";

    delete request_body.month;
    delete request_body.year;

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let group_by_data = {};

        for (let el of res.data) {
          const date = el._id;

          if (!group_by_data[date]) {
            group_by_data[date] = {};
          }

          group_by_data[date] = el;
        }

        const labels = Object.keys(group_by_data);
        let chart_data = [];

        for (let el of Object.keys(group_by_data)) {
          chart_data.push(group_by_data[el].expected_revenues);
        }

        this.setState({
          isLoading: false,
          data: {
            labels,
            datasets: [
              {
                label: "Daily - RWF",
                data: chart_data,
                borderRadius: 15,
                backgroundColor: "rgba(5, 198, 5,0.2)",
                borderColor: "#05c605",
                barPercentage: 0.5,
              },
            ],
          },
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    return (
      <div className="chart-container">
        <div className="card">
          <div className="card-header">
            <h3>Total Parking Revenues</h3>
          </div>
          <div className="card-body" style={{ height: 280 }}>
            {this.state.isLoading ? (
              <LoadingSpinner />
            ) : (
              <Line data={this.state.data} options={options} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return { filters };
};

export default connect(mapStateToProps)(TotalParkingRevenues);

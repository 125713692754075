import React from "react";
import { Home } from "./common";
import { Tabs } from "../components/Tabs";
import { Tickets } from "../components/Tickets";

class CheckInTicketScreen extends React.Component {
  renderAll() {
    return <Tickets />;
  }

  renderOnTime() {
    return <Tickets status="pending" ticket_status="ontime" />;
  }

  renderOverDue() {
    return <Tickets status="pending" ticket_status="late" />;
  }
  render() {
    return (
      <div>
        <Home date location company vehicle paymentmethod />
        <div>
          <Tabs
            options={[
              {
                title: "Tickets",
                data: this.renderAll(),
              },
              // {
              //   title: "On Time",
              //   data: this.renderOnTime(),
              // },
              // {
              //   title: "Over Due",
              //   data: this.renderOverDue(),
              // },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default CheckInTicketScreen;

import React from "react";
import MultiApps from "../../../assets/multiApps.png";
import Slide from "react-reveal/Slide";

const MultiApplication = () => {
  return (
    <div className="section-container common-wrapper wrapper-bg-gray">
      <div className="container  d-lg-flex align-items-center justify-content-center ">
        <div className="col-md-6 flex-fill ml-5">
          <div className="description-section">
            <div>
              <Slide bottom>
                <h1>
                  Multi-applications <br />
                  <span className="primaryText"> Level & Status</span>{" "}
                  Monitoring.
                </h1>
                <p>
                  Our Multi-applications Level & Status Monitoring system offers
                  <br />
                  comprehensive oversight for various critical aspects. From
                  waste
                  <br />
                  management to ensure clean cities, to monitoring liquid
                  reserves like
                  <br /> emergency water, and tracking electricity status for
                  electricity
                  <br /> stations, we provide real-time monitoring and
                  actionable insights.
                </p>
              </Slide>
            </div>
          </div>
        </div>
        <div className="col-md-6 flex-fill">
          <div className="common-section common-img">
            <img src={MultiApps} style={{ width: "900px" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiApplication;

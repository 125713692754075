import React from "react";
import { Modal } from "../../Modal";
import { Input } from "../../Input";
import { Button } from "../../Button";

class Location extends React.Component {
  state = {
    show: false,
    error: {},
    destination: "",
    start_location: "",
    isSubmitting: false,
  };

  handleOpenModal() {
    this.setState({
      show: true,
    });
  }

  handleCloseModal() {
    this.setState({
      show: false,
    });
  }

  onChangeText(field, value) {
    let { error } = this.state;

    delete error[field];
    this.setState({
      [field]: value.target.value,
      error,
    });
  }

  validateForm() {
    let { error, start_location } = this.state;

    if (start_location === "") {
      error.start_location = "Starting location is mandatory";
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const { error, start_location, destination } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });
    }
  };

  render() {
    return (
      <>
        <div className="location-container">
          {this.props.steps.map((item, i) => {
            return (
              <div className="location-item" key={i}>
                <div className={`dot ${item.isActive && "dotActive"}`}></div>
                <span className="title">{item.name}</span>
                {item?.action?.includes("add") && (
                  <div
                    className="add"
                    onClick={this.handleOpenModal.bind(this)}
                  >
                    <i className="bx bx-plus" />
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <Modal
          handleClose={this.handleCloseModal.bind(this)}
          show={this.state.show}
          title="Location"
        >
          <div className="card">
            <div className="card-body">
              <Input
                label="Starting Location"
                onChange={(e) => this.onChangeText("start_location", e)}
                value={this.state.start_location}
                error={this.state.error.start_location}
              />
              <Input
                label="Destination(optional)"
                onChange={(e) => this.onChangeText("destination", e)}
                value={this.state.destination}
                error={this.state.error.destination}
              />
            </div>
            <div className="modal-footer">
              <Button
                text="Close"
                className="btn-bordered"
                onPress={this.handleCloseModal.bind(this)}
              />
              <div style={{ marginRight: 20 }} />
              <Button
                text="Submit"
                className="btn-primary"
                isSubmitting={this.state.isSubmitting}
                onPress={this.onSubmit.bind(this)}
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default Location;

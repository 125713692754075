import moment from 'moment';
import React from 'react';
import {WaterLevelChart, WaterLevelPerType} from '../components/Chart';
import {HighestLevels} from '../components/HighestLevels';
import {RecentCollections} from '../components/RecentCollections';
import {Home} from './common';

class WaterActivitiesScreen extends React.Component {
  state = {
    fromDate: moment().add(30, 'd').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  };
  render() {
    return (
      <div>
        <Home
          isCountCard
          date
          location
          type
          level
          other={{
            thingType: 'Tank',
          }}
        />
        <div style={{position: 'relative', marginTop: '1rem'}}>
          <div className="row mb-4">
            <div className="col-md-6">
              <WaterLevelChart />
            </div>
            <div className="col-md-6">
              <WaterLevelPerType />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-6">
              <HighestLevels />
            </div>
            <div className="col-md-6">
              <RecentCollections />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WaterActivitiesScreen;

import React from 'react';
import {WaterCollections, Analytics} from '../components/WaterLevels';
import {Home} from './common';
import {Tabs} from '../components/Tabs';

class WaterLevelScreen extends React.Component {
  renderLevels() {
    return <Analytics />;
  }

  render() {
    return (
      <div>
        <Home
          date
          location
          type
          level
          other={{
            thingType: 'Tank',
          }}
        />
        <div>{this.renderLevels()}</div>
      </div>
    );
  }
}

export default WaterLevelScreen;

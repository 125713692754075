import { getStorage } from "../../../utils/storage";

export default async (that) => {
  let group_menus = {},
    menus = that.state.sidebar_menus,
    { access_role = {} } = await getStorage,
    { denied_menu = [] } = access_role;

  for (let i = 0; i < menus.length; i++) {
    if (!group_menus[menus[i].group]) {
      group_menus[menus[i].group] = [];
    }

    if (menus[i].items) {
      group_menus[menus[i].group].push(...menus[i].items);
    } else {
      group_menus[menus[i].group] = menus[i];
    }
  }

  for (let i = 0; i < denied_menu.length; i++) {
    let g = denied_menu[i].value.split("/")[0];

    let name = denied_menu[i].value.split("/")[1];

    let items = group_menus[g];

    for (let sub = 0; sub < items.length; sub++) {
      if (items[sub].name === name) {
        items.splice(sub, 1);
      }
    }

    if (items.length === 0) {
      delete group_menus[g];
    } else {
      group_menus[g].items = items;
    }
  }

  let allowed_menus = [],
    sidebar_menus = [];

  let keys = Object.keys(group_menus);

  for (let i = 0; i < keys.length; i++) {
    allowed_menus.push(keys[i]);
  }

  for (let i = 0; i < menus.length; i++) {
    if (allowed_menus.includes(menus[i].group)) {
      if (menus[i].items) {
        menus[i].items = group_menus[menus[i].group];
      }
      sidebar_menus.push(menus[i]);
    }
  }

  that.setState({
    sidebar_menus,
  });
};

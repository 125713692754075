import React from "react";
import Table from "../Table/Table";
import "./styles.css";
import { getStorage } from "../../../app/utils/storage";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { DeleteModal, Modal } from "../Modal";
import { CSVLink } from "react-csv";
import exportPDF from "../../../app/utils/exportPDF";
import { NewVehicle, UploadVehicle } from ".";
import { CompanyInfo } from "../Company";
import { Home } from "../../screen/common";
import { connect } from "react-redux";
import { ParkInfo } from "../Parks";
import filtersData from "../../../app/utils/filtersData";
import { Input } from "../Input";
import { Button } from "../../../app/components/Button";

let copyData = [];

class PendingVehicles extends React.Component {
  state = {
    data: [],
    page: 1,
    limit: 10,
    csvData: [],
    user: {},
    selected_vehicle: {},
    delete_password: "",
    isDeleting: false,
    error: {},
    selected_company: {},
    delete_reason: "",
    selected_park: {},
    chassis_number: "",
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  returnFilters() {
    const { page, limit } = this.state;
    const { filters } = this.props;

    let copyFilters = { ...filters };
    delete copyFilters.month;
    delete copyFilters.year;

    delete copyFilters.start_date;
    delete copyFilters.end_date;

    let request_body = {
      status: "vehicle-pending",
      filters: copyFilters,
      page,
      limit,
      parkFilterType: "reference_park_id",
    };

    return filtersData(request_body);
  }

  getData(isLoading, search_text) {
    const { user } = this.state;

    this.setState({
      isLoading,
    });

    let request_body = this.returnFilters();

    if (search_text) {
      request_body.identifier = search_text;
    }

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_user_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data, count } = res.data;

        this.setState({
          data,
          isLoading: false,
          totalPageCount: count,
        });

        if (data.length > 0) {
          copyData = data.slice(0);
        }
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({ search_text });

    let array = [];

    for (let i = 0; i < copyData.length; i++) {
      if (
        JSON.stringify(copyData[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copyData[i]);
      }
    }

    if (search_text === "" || array.length === 0) {
      this.getData(true, search_text);
    }

    this.setState({ data: array });
  }

  downloadExcel() {
    const { user } = this.state;

    this.setState({
      isLoading: true,
    });

    let request_body = this.returnFilters();

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_user_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res.data.data;

        let csvData = [];

        for (let el of data) {
          delete el.__v;
          delete el._id;
          delete el.updatedAt;

          let org = {
            organization: el.organization_id?.name,
          };

          delete el.organization_id;

          csvData.push({
            ...el,
            ...org,
          });
        }

        this.setState(
          {
            csvData: data,
            isLoading: false,
          },
          () => {
            this.refs.csvDownload?.link.click();
          }
        );
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  downloadPDF() {
    const headers = this.returnTableHeaders();

    delete headers[headers.length - 1];

    const { user } = this.state;

    let request_body = this.returnFilters();

    this.setState({
      isLoading: true,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_user_info",
      data: {
        ...request_body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res.data.data;

        this.setState({ isLoading: false });

        exportPDF("Vehicles", headers, data);
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  handleShowModal(modal, modalTitle, selected_vehicle = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_vehicle,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onChangeText(name, e) {
    let { error } = this.state;

    delete error[name];

    this.setState({
      [name]: e.target.value,
      error,
    });
  }

  onUpdateVehicle = async (status, vehicle) => {
    let { user, chassis_number, error } = this.state;

    if (status === "vehicle-active" && chassis_number === "") {
      error.chassis_number = "Chassis number is required";
      return this.setState({ error });
    }

    this.setState({
      isLoading: true,
    });

    let url = ENDPOINT + "/update_ticket_user_info";

    const options = {
      method: "POST",
      url,
      data: {
        id: vehicle._id,
        status,
        available: status === "vehicle-active",
        chassis_number:
          status === "vehicle-active" ? chassis_number : undefined,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        toastMessage("success", "Data updated successful");

        this.getData(true);

        this.handleCloseModal("showApproveModal");
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  };

  onViewCompanyInfo(vehicle) {
    this.setState({
      showCompanyInfo: true,
      modalTitle: vehicle?.organization_id.name + "'s Info",
      selected_company: vehicle.organization_id,
    });
  }

  onViewParkInfo(vehicle) {
    this.setState({
      showParkInfo: true,
      modalTitle: vehicle.reference_park_id?.park_id + "'s Info",
      selected_park: vehicle.reference_park_id,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: "Company",
        key: "organization_id.name",
        type: "link",
        onPress: this.onViewCompanyInfo.bind(this),
      },
      {
        title: "Plate Number",
        key: "identifier",
      },
      {
        title: "Chassis Number",
        key: "chassis_number",
      },
      {
        title: "Operation Bus Park",
        key: "reference_park_id.park_id",
        type: "link",
        onPress: this.onViewParkInfo.bind(this),
      },
      // {
      //   title: "Created Bus Park",
      //   key: "park_id.park_id",
      //   type: "link",
      //   onPress: this.onViewParkInfo.bind(this),
      // },
      {
        title: "Added By",
        key: "account.email",
      },
      {
        title: "Action",
        key: "action",
      },
    ];
    return headers;
  }

  render() {
    return (
      <>
        <div style={{ marginTop: 20 }}>
          <Home company />
        </div>

        <div className="card">
          <div className="card-body">
            <Table
              data={this.state.data}
              no_bordered
              isSearch
              style={{ marginBottom: 0 }}
              totalPageCount={this.state.totalPageCount}
              page={this.state.page}
              limit={this.state.limit}
              search_text={this.state.search_text}
              handleSearch={this.handleSearch.bind(this)}
              isLoading={this.state.isLoading}
              handlePagination={this.handlePagination.bind(this)}
              headers={this.returnTableHeaders()}
              rowPress={(item) =>
                this.handleShowModal("showApproveModal", item.identifier, item)
              }
              actions={[
                {
                  name: "Approve",
                  onPress: (item) =>
                    this.handleShowModal(
                      "showApproveModal",
                      item.identifier,
                      item
                    ),
                },
                {
                  name: "Reject",
                  onPress: (item) => this.onUpdateVehicle("rejected", item),
                },
              ]}
              filters={[
                {
                  type: "refresh",
                  title: "Refresh",
                  icon: "bx bx-refresh",
                  onPress: () => this.getData(true),
                },
                {
                  type: "export",
                  title: "Export",
                  button_type: "dropdown",
                  icon: "bx bxs-download",
                  options: [
                    {
                      name: "PDF",
                      onPress: this.downloadPDF.bind(this),
                    },
                    {
                      name: "CSV",
                      onPress: this.downloadExcel.bind(this),
                    },
                  ],
                },
              ]}
            />
          </div>
          <Modal
            handleClose={this.handleCloseModal.bind(this, "showCompanyInfo")}
            show={this.state.showCompanyInfo}
            title={this.state.modalTitle}
            showHeaderBottomBorder={false}
          >
            <CompanyInfo {...this.state.selected_company} />
          </Modal>
          <Modal
            handleClose={this.handleCloseModal.bind(this, "showParkInfo")}
            show={this.state.showParkInfo}
            title={this.state.modalTitle}
            size="lg"
          >
            <ParkInfo
              {...this.state.selected_park}
              handleCloseModal={this.handleCloseModal.bind(
                this,
                "showParkInfo"
              )}
            />
          </Modal>
          <Modal
            handleClose={this.handleCloseModal.bind(this, "showApproveModal")}
            show={this.state.showApproveModal}
            title={this.state.modalTitle}
          >
            <div className="container" style={{ paddingTop: 15 }}>
              <Input
                label="Chassis Number"
                onChange={(e) => this.onChangeText("chassis_number", e)}
                value={this.state.chassis_number}
                error={this.state.error.chassis_number}
                required
              />
              <hr />
              <div style={{ display: "flex", paddingBottom: 15 }}>
                <div style={{ flex: 1 }}></div>
                <Button
                  text="Approve"
                  className="btn-primary"
                  onPress={() =>
                    this.onUpdateVehicle(
                      "vehicle-active",
                      this.state.selected_vehicle
                    )
                  }
                  isSubmitting={this.state.isLoading}
                />
              </div>
            </div>
          </Modal>
          <CSVLink
            ref="csvDownload"
            filename={this.props.type + new Date().getTime()}
            data={this.state.csvData}
          ></CSVLink>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(PendingVehicles);

import React from "react";
import { WasteCollections, Analytics } from "../components/WasteLevels";
import { Home } from "./common";
import { Tabs } from "../components/Tabs";
import AnalyticsHouseRent from "../components/WasteLevels/AnalyticsHouseRent";

class ParkLevelScreen extends React.Component {
  renderLevels() {
    return <Analytics />;
  }

  renderHouseRent() {
    return <AnalyticsHouseRent />;
  }
  render() {
    return (
      <div>
        <Home date entity client />
        <div>
          <Analytics />
        </div>
      </div>
    );
  }
}

export default ParkLevelScreen;

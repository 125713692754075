import ReactTimeAgo from 'react-time-ago';

const TimeAgo = props => {
  return (
    <div>
      <ReactTimeAgo date={props.date} locale="en-US" />
    </div>
  );
};
export default TimeAgo;

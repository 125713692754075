import React from "react";
import { PerPark } from "../components/TopRent";

class RentRevenuesPerParkScreen extends React.Component {
  render() {
    return (
      <div>
        <PerPark all />
      </div>
    );
  }
}

export default RentRevenuesPerParkScreen;

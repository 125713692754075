import axios from "axios";
import React from "react";
import { ENDPOINT } from "../../../app/constants/api";
import toastMessage from "../../../app/utils/toastMessage";
import Table from "../Table/Table";
import "./styles.css";
import { getStorage } from "../../../app/utils/storage";
import { Home } from "../../screen/common";
import { connect } from "react-redux";
import exportPDF from "../../../app/utils/exportPDF";
import { CSVLink } from "react-csv";
import { Modal } from "../Modal";
import { CompanyInfo } from "../Company";
import RentInfo from "../Service/RentInfo";
import { ParkInfo } from "../Parks";
import filtersData from "../../../app/utils/filtersData";

let copyData = [];

class UnpaidVehicles extends React.Component {
  state = {
    data: [],
    isLoading: true,
    user: {},
    page: 1,
    limit: 10,
    error: {},
    csvData: [],
    selected_item: {},
    selected_service: {},
    selected_park: {},
    payments: [],
    vehicles: [],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  getData = async (isLoading) => {
    await this.getPayments(true);
    await this.getVehicles(true);
    await this.unpaidVehicles(isLoading);
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      await this.getData(true);
    }
  };

  returnFilters() {
    const { page, limit } = this.state;
    const { filters } = this.props;

    let request_body = {
      category: "servicefee",
      page,
      limit,
      filters,
      parkFilterType: "reference_park_id",
    };

    return filtersData(request_body);
  }

  getPayments(isLoading) {
    const { user } = this.state;

    this.setState({
      isLoading,
    });

    let body = this.returnFilters();

    let url = ENDPOINT + "/get_service_payment_info";

    const options = {
      method: "POST",
      url,
      data: {
        ...body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options)
      .then((res) => {
        let { data, count } = res.data;

        if (!data) {
          data = res.data;
        }
        this.setState({
          payments: data,
          isLoading: false,
          totalPageCount: count,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  }

  returnVehicleFilters() {
    const { filters } = this.props;

    let request_body = {
      status: "vehicle-active",
      filters,
      parkFilterType: "reference_park_id",
    };

    return filtersData(request_body);
  }

  getVehicles(isLoading, search_text) {
    const { user } = this.state;

    this.setState({
      isLoading,
    });

    let request_body = this.returnVehicleFilters();

    if (search_text) {
      request_body.identifier = search_text;
    }

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_user_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options)
      .then((res) => {
        let vehicles = [];

        //return vehicle that pay service fee
        for (let vehicle of res.data) {
          if (vehicle?.other?.servicefee) {
            vehicles.push(vehicle);
          }
        }

        this.setState({
          vehicles,
          isLoading: false,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  unpaidVehicles() {
    const { payments, vehicles } = this.state;

    let plate_numbers = [],
      data = [];

    for (let vehicle of vehicles) {
      if (!plate_numbers.includes(vehicle.identifier)) {
        plate_numbers.push(vehicle.identifier);
      }

      if (payments.length > 0) {
        for (let payment of payments) {
          if (vehicle.identifier === payment.identifier && payment.price > 0) {
            vehicle["unpaid_amount"] = payment.service_id.price - payment.price;
            vehicle["target_price"] = payment.service_id.price;
            vehicle["unpaid"] = true;
          }

          if (!plate_numbers.includes(payment.identifier)) {
            vehicle["unpaid"] = true;
          }
        }
      } else {
        vehicle["unpaid"] = true;
      }

      if (vehicle.other.servicefee && vehicle.unpaid) {
        data.push(vehicle);
      }
    }

    this.setState({
      data,
      csvData: data,
    });

    copyData = data.slice(0);
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleSearch(e) {
    const search_text = e.target.value;
    let array = [];

    this.setState({
      search_text,
    });

    for (let i = 0; i < copyData.length; i++) {
      if (
        JSON.stringify(copyData[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copyData[i]);
      }
    }

    this.setState({
      data: array,
    });
  }

  downloadExcel = () => {
    let csvData = [],
      { data } = this.state;

    for (let el of data) {
      delete el.__v;
      delete el._id;
      delete el.updatedAt;

      csvData.push({
        ...el,
      });
    }
    this.setState(
      {
        csvData: data,
      },
      () => {}
    );

    this.refs.csvDownload?.link.click();
  };

  downloadPDF = () => {
    const headers = this.returnTableHeaders();

    const { data } = this.state;

    exportPDF("Unpaid Vehicles", headers, data);
  };

  onViewCompanyInfo(vehicle) {
    this.setState({
      showCompanyInfo: true,
      modalTitle: vehicle?.organization_id.name + "'s Info",
      selected_item: vehicle.organization_id,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onViewServiceInfo(rent) {
    this.setState({
      showServiceInfo: true,
      modalTitle: "Service Info",
      selected_service: rent.service_id,
    });
  }

  onViewParkInfo(rent) {
    this.setState({
      showParkInfo: true,
      modalTitle: rent.park_id?.park_id + "'s Info",
      selected_park: rent.park_id,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: "Company",
        key: "organization_id.name",
        type: "link",
        onPress: this.onViewCompanyInfo.bind(this),
      },
      {
        title: "Plate Number",
        key: "identifier",
      },
      {
        title: "Bus park",
        key: "reference_park_id.park_id",
        type: "link",
        onPress: this.onViewParkInfo.bind(this),
      },
      {
        title: "Target Price",
        key: "target_price",
        type: "amount",
      },
      {
        title: "Unpaid Amount",
        key: "unpaid_amount",
        type: "amount",
      },
    ];

    return headers;
  }

  render() {
    return (
      <div>
        <div style={{ marginTop: 20 }}>
          <Home company month vehicle location year />
        </div>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={this.handleSearch.bind(this)}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          filters={[
            {
              type: "refresh",
              title: "Refresh",
              icon: "bx bx-refresh",
              onPress: () => this.getData(true),
            },
            {
              type: "export",
              title: "Export",
              button_type: "dropdown",
              icon: "bx bxs-download",
              options: [
                {
                  name: "PDF",
                  onPress: this.downloadPDF.bind(this),
                },
                {
                  name: "CSV",
                  onPress: this.downloadExcel.bind(this),
                },
              ],
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showCompanyInfo")}
          show={this.state.showCompanyInfo}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <CompanyInfo {...this.state.selected_item} />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showServiceInfo")}
          show={this.state.showServiceInfo}
          title={this.state.modalTitle}
          size="lg"
        >
          <RentInfo
            data={this.state.selected_service}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showServiceInfo"
            )}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showParkInfo")}
          show={this.state.showParkInfo}
          title={this.state.modalTitle}
          size="lg"
        >
          <ParkInfo
            {...this.state.selected_park}
            handleCloseModal={this.handleCloseModal.bind(this, "showParkInfo")}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Service Fees" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(UnpaidVehicles);

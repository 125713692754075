import React from "react";
import "./styles.css";

const ApplicationList = (props) => {
  return (
    <div className="col-md-6">
      <div className="applicationList" onClick={props.onPress}>
        <div className="left">
          <div className="logoPlaceholder">
            <span>{props.name.charAt(0)}</span>
          </div>
        </div>
        <div className="middle">
          <span>{props.name}</span>
        </div>
        <div className="icon-box">
          <i className="bx bx-chevron-right-square"></i>
        </div>
      </div>
    </div>
  );
};

export default ApplicationList;

import React from "react";
import { Link } from "react-router-dom";
import Table from "../../../app/components/Table/Table";
import "./styles.css";
import { getStorage } from "../../../app/utils/storage";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { connect } from "react-redux";
import filtersData from "../../../app/utils/filtersData";

class PerUser extends React.Component {
  state = {
    data: [],
    user: {},
    selected_level: {},
    limit: 5,
    page: 1,
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getRevenues(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getRevenues(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  returnFilters() {
    const { page, limit } = this.state;
    const { filters } = this.props;
    let copyFilters = { ...filters };
    delete copyFilters.month;
    delete copyFilters.year;

    let request_body = {
      page,
      limit,
      tag: "user",
      category: "rent",
      filters: copyFilters,
    };

    delete request_body.month;
    delete request_body.year;

    return filtersData(request_body);
  }

  getRevenues(isLoading) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    if (this.props.all) {
      delete request_body.page;
      delete request_body.limit;
    }

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_service_payment_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let results = [];

        this.setState({
          isLoading: false,
          data: res?.data?.data || res.data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  handleOpenModal(modal, selected_item) {
    let selected_bin = {},
      selected_level = {},
      modalTitle = "";

    if (modal === "showModal") {
      selected_level = selected_item;
      modalTitle = "Alerts";
    }

    if (modal === "showBinModal") {
      selected_bin = selected_item.bin_info;
      modalTitle = "#" + selected_item.bin_info.bin_id;
    }

    this.setState({
      [modal]: true,
      modalTitle,
      selected_level,
      selected_bin,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    return (
      <div className="card" style={this.props.styles}>
        {!this.props.all && (
          <div className="card-header">
            <h3>Rent Revenues - Top 5 Users</h3>
          </div>
        )}
        <div className="card-body" style={{ paddingBottom: 0 }}>
          <Table
            data={this.state.data}
            no_bordered
            style={{ marginBottom: 0 }}
            isLoading={this.state.isLoading}
            headers={[
              {
                title: "Account",
                key: "account.email",
              },
              {
                title: "No Tickets",
                key: "count",
              },
              {
                title: "Total Revenues",
                key: "total_revenues",
                type: "amount",
              },
            ]}
          />
        </div>
        {this.state.data.length > 0 && !this.props.all && (
          <center className="card-footer border-top">
            <Link
              to="/dashboard/rent_revenues_per_user"
              className="text-primary"
            >
              <b>View More</b>
            </Link>
          </center>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return { filters };
};

export default connect(mapStateToProps)(PerUser);

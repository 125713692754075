import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CardCount } from "../../../app/components/CardCount";
import { ENDPOINT } from "../../../app/constants/api";
import { getStorage } from "../../../app/utils/storage";
import toastMessage from "../../../app/utils/toastMessage";
import { LoadingSpinner } from "../LoadingSpinner";
import { Doughnut, Line } from "react-chartjs-2";
import { colors, days, months } from "../../../app/constants/strings";
import filtersData from "../../../app/utils/filtersData";

const options = {
  legend: {
    position: "bottom",
    display: false,
  },
  maintainAspectRatio: false,
  responsive: true,
};

class Analytics extends React.Component {
  state = {
    user: {},
    route: "",
    chart: {
      max_speed: [],
      low_average_speed: [],
      high_average_speed: [],
      hourly_trends: [],
      monthly_trends: [],
      daily_trends: [],
      week_days_trends: [],
    },
  };

  componentDidMount = async () => {
    const user = await getStorage();

    await this.setState({ user });

    this.getData(true);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  };

  getData = async (isLoading) => {
    this.getMaxSpeed(isLoading);
    this.getAverageHighSpeed(isLoading);
    this.getAverageLowSpeed(isLoading);
    this.getHourlyActive(isLoading);
    this.getWeeklyActive(isLoading);
    this.getHourlyActive(isLoading);
    this.getDailyActive(isLoading);
    this.getMonthlyActive(isLoading);
  };

  getVehicles(isLoadingSpeed) {
    const { user } = this.state;

    const { filters } = this.props;

    let params = {
      target: "rank",
      filters,
    };

    let request_body = filtersData(params);

    // request_body.tag = "count";

    this.setState({
      isLoadingSpeed,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_user_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoadingSpeed: false,
          total_vehicles: data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingSpeed: false });
      });
  }

  getTickets(isLoadingTickets) {
    const { user } = this.state;
    const { filters } = this.props;

    let params = {
      filters,
      parkFilterType: "park_id",
      vehicleFilterType: "ticket_user_ids",
    };

    let request_body = filtersData(params);

    request_body.tag = "count";

    this.setState({
      isLoadingTickets,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoadingTickets: false,
          total_tickets: data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingTickets: false });
      });
  }

  getDevices(isLoadingDevice) {
    const { user } = this.state;
    const { filters } = this.props;

    let request_body = {
      filters,
      parkFilterType: "park_id",
    };

    request_body.tag = "count";

    this.setState({
      isLoadingDevice,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_device_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoadingDevice: false,
          total_devices: data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingDevice: false });
      });
  }

  handlePressCount(route) {
    this.setState({ route });
  }

  //NEW
  getMaxSpeed(isLoadingMaxSpeed) {
    const { user } = this.state;
    const { filters } = this.props;

    let params = {
      filters,
      target: "rank",
      tag: "max_speed",
      limit: 5,
    };

    let request_body = filtersData(params);

    this.setState({
      isLoadingMaxSpeed,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_location_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options)
      .then((res) => {
        this.setState({
          isLoadingMaxSpeed: false,
        });

        this.formatChart(res.data, "max_speed");
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingMaxSpeed: false });
      });
  }

  getAverageHighSpeed(isLoadingHighSpeed) {
    const { user } = this.state;
    const { filters } = this.props;

    let params = {
      filters,
      target: "rank",
      limit: 5,
    };

    let request_body = filtersData(params);

    this.setState({
      isLoadingHighSpeed,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_location_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options)
      .then((res) => {
        this.setState({
          isLoadingHighSpeed: false,
        });

        this.formatChart(res.data, "high_average_speed");
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingHighSpeed: false });
      });
  }

  getAverageLowSpeed(isLoadingLowSpeed) {
    const { user } = this.state;
    const { filters } = this.props;

    let params = {
      filters,
      target: "rank",
      tag: "average_low_speed",
      limit: 5,
    };

    let request_body = filtersData(params);

    this.setState({
      isLoadingLowSpeed,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_location_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options)
      .then((res) => {
        this.setState({
          isLoadingLowSpeed: false,
        });

        this.formatChart(res.data, "low_average_speed");
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingLowSpeed: false });
      });
  }

  getHourlyActive(isLoadingHourly) {
    const { user } = this.state;
    const { filters } = this.props;

    let params = {
      filters,
      target: "trend",
      tag: "speed",
      order: "hourly",
    };

    let request_body = filtersData(params);

    this.setState({
      isLoadingHourly,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_location_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options)
      .then((res) => {
        this.setState({
          isLoadingHourly: false,
        });

        const results = res.data.map((el) => {
          return {
            ...el,
            _id: el._id + "h",
          };
        });

        this.formatChartPerDate(results, "hourly_trends");
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingHourly: false });
      });
  }

  getMonthlyActive(isLoadingMonthly) {
    const { user } = this.state;
    const { filters } = this.props;

    let params = {
      filters,
      target: "trend",
      tag: "speed",
      order: "monthly",
    };

    let request_body = filtersData(params);

    this.setState({
      isLoadingMonthly,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_location_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options)
      .then((res) => {
        this.setState({
          isLoadingMonthly: false,
        });

        const results = res.data.map((el) => {
          return {
            ...el,
            _id: months[el._id],
          };
        });

        this.formatChartPerDate(results, "monthly_trends");
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingMonthly: false });
      });
  }

  getDailyActive(isLoadingDaily) {
    const { user } = this.state;
    const { filters } = this.props;

    let params = {
      filters,
      target: "trend",
      tag: "speed",
      order: "daily",
    };

    let request_body = filtersData(params);

    this.setState({
      isLoadingDaily,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_location_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options)
      .then((res) => {
        this.setState({
          isLoadingDaily: false,
        });

        this.formatChartPerDate(res.data, "daily_trends");
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingDaily: false });
      });
  }

  getWeeklyActive(isLoadingWeekly) {
    const { user } = this.state;
    const { filters } = this.props;

    let params = {
      filters,
      target: "trend",
      tag: "speed",
      order: "day_of_week",
    };

    let request_body = filtersData(params);

    this.setState({
      isLoadingWeekly,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_location_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options)
      .then((res) => {
        this.setState({
          isLoadingWeekly: false,
        });

        const results = res.data.map((el) => {
          return {
            ...el,
            _id: days[el._id],
          };
        });

        this.formatChartPerDate(results, "week_days_trends");
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingWeekly: false });
      });
  }

  formatChart(data, key_field) {
    let group_by_data = {};

    for (let el of data) {
      const key = el?._id;

      if (!group_by_data[key]) {
        group_by_data[key] = {};
      }

      group_by_data[key] = el;
    }

    const labels = Object.keys(group_by_data);
    let chart_data = [];

    for (let el of Object.keys(group_by_data)) {
      chart_data.push(
        group_by_data[el].max_speed || group_by_data[el].average_speed
      );
    }

    let customLabels = labels.map(
      (label, index) =>
        `${label}:  ${parseFloat(
          group_by_data[label].max_speed || group_by_data[label].average_speed
        ).toFixed(2)} `
    );

    this.state.chart[key_field] = {
      labels: customLabels,
      datasets: [
        {
          data: chart_data,
          backgroundColor: colors,
        },
      ],
    };

    this.setState({
      chart: this.state.chart,
    });
  }

  formatChartPerDate(data, key_field) {
    let group_by_data = {};

    for (let el of data) {
      const id = el?._id;

      if (!group_by_data[id]) {
        group_by_data[id] = {};
      }

      group_by_data[id] = el;
    }

    const labels = Object.keys(group_by_data);
    let chart_data = [];

    for (let el of Object.keys(group_by_data)) {
      chart_data.push(
        group_by_data[el].count || group_by_data[el].average_speed
      );
    }

    this.state.chart[key_field] = {
      labels,
      datasets: [
        {
          label: `${
            key_field.includes("hourly")
              ? "Hourly"
              : key_field.includes("week_days")
              ? "Day of Week"
              : key_field.includes("daily")
              ? "Daily"
              : "Monthly"
          } `,
          data: chart_data,
          backgroundColor: "rgba(5, 198, 5,0.2)",
          borderColor: "#05c605",
          barPercentage: 0.5,
        },
      ],
    };

    this.setState({
      chart: this.state.chart,
    });
  }

  //END

  returnChartList(data) {
    return (
      <ul className="chart-list">
        {data &&
          data.map((item, i) => {
            let title = item.split(":");
            return (
              <li key={i}>
                <span className="title">{title[0]}</span>
                <span className="number">{title[1]}</span>
              </li>
            );
          })}
      </ul>
    );
  }

  render() {
    if (this.state.route !== "") {
      return <Redirect to={this.state.route} />;
    }

    return (
      <div>
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-md-4">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Top 5 Max Speed</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingMaxSpeed ? (
                  <LoadingSpinner />
                ) : (
                  <div>
                    <div style={{ height: 180 }}>
                      <Doughnut
                        data={this.state.chart.max_speed}
                        options={options}
                      />
                    </div>
                    {this.returnChartList(
                      this.state.chart?.max_speed?.labels
                        ? this.state.chart?.max_speed?.labels
                        : []
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Top 5 Average High Speed</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingHighSpeed ? (
                  <LoadingSpinner />
                ) : (
                  <div>
                    <div style={{ height: 180 }}>
                      <Doughnut
                        data={this.state.chart.high_average_speed}
                        options={options}
                      />
                    </div>
                    {this.returnChartList(
                      this.state.chart?.high_average_speed?.labels
                        ? this.state.chart?.high_average_speed?.labels
                        : []
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Top 5 Average Low Speed </h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingLowSpeed ? (
                  <LoadingSpinner />
                ) : (
                  <div>
                    <div style={{ height: 180 }}>
                      <Doughnut
                        data={this.state.chart.low_average_speed}
                        options={options}
                      />
                    </div>
                    {this.returnChartList(
                      this.state.chart?.low_average_speed?.labels
                        ? this.state?.chart?.low_average_speed?.labels
                        : []
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-md-6">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Hourly Active Vehicles</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingHourly ? (
                  <LoadingSpinner />
                ) : (
                  <Line
                    data={this.state.chart.hourly_trends}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Daily Active Vehicles</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingDaily ? (
                  <LoadingSpinner />
                ) : (
                  <Line
                    data={this.state.chart.daily_trends}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-md-6">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>WeekDays Active Vehicles</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingWeekly ? (
                  <LoadingSpinner />
                ) : (
                  <Line
                    data={this.state.chart.week_days_trends}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      bezierCurve: true,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Monthly Active Vehicles</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingMonthly ? (
                  <LoadingSpinner />
                ) : (
                  <Line
                    data={this.state.chart.monthly_trends}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      bezierCurve: true,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(Analytics);

import React from "react";
// import { TopParkUserRevenues } from "../components/TopRevenues";

class RevenuesPerUserScreen extends React.Component {
  render() {
    return <div>{/* <TopParkUserRevenues all /> */}</div>;
  }
}

export default RevenuesPerUserScreen;

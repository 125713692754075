import React from "react";
import { Notification } from "../components/Notification";
import { TopParkUserRevenues } from "../components/TopRevenues";

class HighestRevenueScreen extends React.Component {
  render() {
    return (
      <div>
        <TopParkUserRevenues all />
      </div>
    );
  }
}

export default HighestRevenueScreen;

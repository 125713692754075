import React from "react";
import { Modal } from "../../Modal";
import { Select } from "../../Input";
import { Button } from "../../Button";
import { Provinces } from "rwanda";
import { LineSeparator } from "../../LineSeparator";

class Province extends React.Component {
  state = {
    show: false,
    error: {},
    destination: "",
    start_location: "",
    isSubmitting: false,
    provinces: [],
  };

  componentDidMount() {
    let listProvinces = Provinces().map((el) => {
      return {
        label: el,
        value: el,
      };
    });

    this.setState({
      provinces: listProvinces,
    });
  }

  handleOpenModal() {
    this.setState({
      show: true,
    });
  }

  handleCloseModal() {
    this.setState({
      show: false,
    });
  }

  onChangeText(field, value) {
    let { error } = this.state;

    delete error[field];
    this.setState({
      [field]: value,
      error,
    });
  }

  validateForm() {
    let { error, start_location } = this.state;

    if (start_location === "") {
      error.start_location = "Starting location is mandatory";
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const { error, start_location, destination } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });
    }
  };

  render() {
    return (
      <>
        <div
          className="location-container"
          style={{ cursor: "pointer", marginBottom: "1rem" }}
          onClick={this.handleOpenModal.bind(this)}
        >
          {this.props.description && <p>{this.props.description}</p>}
          {this.state.province &&
            this.state.province.map((el, i) => {
              return (
                <div key={i} className="list-selection badge badge-primary">
                  <span>{el.label}</span>
                </div>
              );
            })}
        </div>
        <Modal
          handleClose={this.handleCloseModal.bind(this)}
          show={this.state.show}
          title="Location"
        >
          <div className="card">
            <div className="card-body">
              <Select
                options={this.state.provinces}
                label="Province"
                onChange={(e) => this.onChangeText("province", e)}
                value={this.state.province}
                error={this.state.error.province}
                isMulti
              />
            </div>
            <div className="modal-footer">
              <Button
                text="Close"
                className="btn-bordered"
                onPress={this.handleCloseModal.bind(this)}
              />
              <div style={{ marginRight: 20 }} />
              <Button
                text="Submit"
                className="btn-primary"
                isSubmitting={this.state.isSubmitting}
                onPress={this.onSubmit.bind(this)}
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default Province;

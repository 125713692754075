import React from "react";
import { Button } from "../../../app/components/Button";

const StockInfo = (props) => {
  return (
    <div className="card">
      <div className="card-body table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{props.name}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="card-footer d-flex align-content-center justify-content-end gap-3">
        <Button
          text="Close"
          className="btn-default btn-lg border"
          onPress={props.handleCloseModal}
        />
      </div>
    </div>
  );
};

export default StockInfo;

import React from "react";
import Table from "../../../app/components/Table/Table";
import "./styles.css";
import { getStorage } from "../../../app/utils/storage";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { Modal } from "../../../app/components/Modal";
import { ParkInfo } from "../Parks";

class TicketInfo extends React.Component {
  state = {
    data: [],
    selected_park: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getData(isLoading) {
    const { user } = this.state;

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_park_info",
      data: {
        id: this.props.id,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res.data;

        this.setState({
          data,
          isLoading: false,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  handleOpenModal(modal, selected_park) {
    this.setState({
      [modal]: true,
      selected_park: selected_park.park_id ? selected_park.park_id : {},
      modalTitle: selected_park.park_id
        ? "Bus Station #" + selected_park.park_id.park_id
        : "",
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <Table
            data={this.state.data}
            no_bordered
            isLoading={this.state.isLoading}
            headers={[
              {
                title: "Level",
                key: "level",
                type: "level",
              },
              {
                title: "Created At",
                key: "createdAt",
                isMoment: true,
                formatTime: "lll",
              },
              {
                title: "Ticket Date",
                key: "Ticket_date",
                isMoment: true,
                formatTime: "lll",
              },
              {
                title: "Location",
                key: "park_id.address.geolocation",
              },
              {
                title: "Bus Stop",
                key: "park_id.park_id",
                type: "link",
                onPress: this.handleOpenModal.bind(this, "showModal"),
              },
              {
                title: "Ticket Status",
                key: "Ticket_status",
              },
            ]}
          />
        </div>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          size="lg"
        >
          <ParkInfo
            {...this.state.selected_park}
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
          />
        </Modal>
      </div>
    );
  }
}

export default TicketInfo;

import React from "react";
import { Vehicles } from "../components/reports";

class VehicleReportScreen extends React.Component {
  render() {
    return (
      <div className="card">
        <Vehicles />
      </div>
    );
  }
}

export default VehicleReportScreen;

import moment from "moment";

export default ({
  page,
  limit,
  filters,
  category,
  status,
  parkFilterType,
  startDateType = "start_createdAt",
  endDateType = "end_createdAt",
  target,
  order,
  tag,
  vehicleField = "number_plate",
}) => {
  let request_body = {};

  if (page) {
    request_body.page = page;
  }

  if (limit) {
    request_body.limit = limit;
  }

  if (category) {
    request_body.category = category;
  }

  if (status) {
    request_body.status = status;
  }

  if (tag) {
    request_body.tag = tag;
  }

  if (order) {
    request_body.order = order;
  }

  if (target) {
    request_body.target = target;
  }

  if (filters?.companies?.value) {
    request_body.organization_id = filters.companies.value;
  } else {
    delete request_body.organization_id;
  }

  if (filters?.locations?.value) {
    request_body[parkFilterType] = filters.locations?.value;
  } else {
    delete request_body[parkFilterType];
  }

  if (filters?.start_date && filters.end_date) {
    if (filters?.start_date === filters.end_date) {
      var midnight = moment().startOf("day").toString();
      var now = moment().toString();

      request_body[startDateType] = moment(midnight).unix() * 1000;
      request_body[endDateType] = moment(now).endOf("day").unix() * 1000;
    } else {
      request_body[startDateType] = moment(filters.start_date).unix() * 1000;
      request_body[endDateType] =
        moment(filters.end_date).endOf("day").unix() * 1000;
    }
  }

  if (filters?.vehicles) {
    for (let el of filters.vehicles) {
      if (!request_body["number_plates"]) {
        request_body["number_plates"] = [];
      }
      request_body["number_plates"].push(el);
    }
  }

  return request_body;
};

import React from "react";
import { Tabs } from "../components/Tabs";
import { Stocks } from "../components/Stocks";
import { Products } from "../components/Product";

class ProductScreen extends React.Component {
  renderProduct() {
    return <Products />;
  }

  renderStock() {
    return <Stocks />;
  }

  render() {
    return (
      <div>
        <div>
          <Tabs
            options={[
              {
                title: "Products & Services",
                data: this.renderProduct(),
              },
              {
                title: "Stock",
                data: this.renderStock(),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default ProductScreen;

import React from "react";
import { PerPark } from "../components/TopServiceFee";

class RevenuesPerParkScreen extends React.Component {
  render() {
    return (
      <div>
        <PerPark all />
      </div>
    );
  }
}

export default RevenuesPerParkScreen;

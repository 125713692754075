import React from "react";
import { Link } from "react-router-dom";
import Table from "../../../app/components/Table/Table";
import "./styles.css";
import { getStorage } from "../../../app/utils/storage";
import { ENDPOINT } from "../../../app/constants/api";
import axios from "axios";
import toastMessage from "../../../app/utils/toastMessage";
import { connect } from "react-redux";
import { Modal } from "../../../app/components/Modal";
import { AlertInfo } from "../Alerts";
import { StockInfo } from "../Stocks";

class TopCompanies extends React.Component {
  state = {
    data: [],
    user: {},
    selected_level: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getRevenues(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getRevenues(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getRevenues(isLoading) {
    const { user } = this.state;

    let request_body = {
      page: 1,
      limit: 5,
      tag: "park",
    };

    if (this.props.all) {
      delete request_body.page;
      delete request_body.limit;
    }

    delete request_body.month;
    delete request_body.year;

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let results = [];

        for (let el of res?.data?.data || res.data) {
          results.push({
            ...el,
            park_id: el.park_id[0],
          });
        }

        this.setState({
          isLoading: false,
          data: results,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  handleOpenModal(modal, selected_item) {
    let selected_bin = {},
      selected_level = {},
      modalTitle = "";

    if (modal === "showModal") {
      selected_level = selected_item;
      modalTitle = "Alerts";
    }

    if (modal === "showBinModal") {
      selected_bin = selected_item.bin_info;
      modalTitle = "#" + selected_item.bin_info.bin_id;
    }

    this.setState({
      [modal]: true,
      modalTitle,
      selected_level,
      selected_bin,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    return (
      <div className="card">
        {!this.props.all && (
          <div className="card-header">
            <h3>Product Revenues - Top 5</h3>
          </div>
        )}
        <div className="card-body" style={{ paddingBottom: 0 }}>
          <Table
            data={this.state.data}
            no_bordered
            style={{ marginBottom: 0 }}
            isLoading={this.state.isLoading}
            headers={[
              {
                title: "Bus Park",
                key: "park_id.park_id",
              },
              {
                title: "No Tickets",
                key: "count",
              },
              {
                title: "Total Revenues",
                key: "total_revenues",
                type: "amount",
              },
            ]}
          />
        </div>
        {this.state.data.length > 0 && !this.props.all && (
          <center className="card-footer border-top">
            <Link to="/dashboard/company_ticket" className="text-primary">
              <b>View More</b>
            </Link>
          </center>
        )}
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          size="lg"
        >
          <AlertInfo
            id={
              this.state.selected_level.bin_info
                ? this.state.selected_level.bin_info._id
                : ""
            }
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showBinModal")}
          show={this.state.showBinModal}
          title={this.state.modalTitle}
          size="lg"
        >
          <StockInfo
            {...this.state.selected_bin}
            handleCloseModal={this.handleCloseModal.bind(this, "showBinModal")}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return { filters };
};

export default connect(mapStateToProps)(TopCompanies);

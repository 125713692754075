import React from "react";
import Logo from "../../../assets/logo.png";
import toggleSidebar from "../utils/toggleSidebar";
import { connect } from "react-redux";

class Header extends React.Component {
  render() {
    return (
      <div className="header">
        <div
          className="logo"
          onClick={() =>
            (window.location.href = "/dashboard/home/applications")
          }
          style={{ cursor: "pointer" }}
        >
          <img src={Logo} />
        </div>
        <span
          onClick={() =>
            (window.location.href = "/dashboard/home/applications")
          }
          className="name"
          style={{ position: "relative", cursor: "pointer" }}
        >
          Huza
          <b>Lytics</b>
        </span>
        <div className="mobile-close" onClick={() => toggleSidebar(this)}>
          <i className="bx bxs-arrow-from-right"></i>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps)(Header);

import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import { getStorage } from "../../../app/utils/storage";
import { connect } from "react-redux";
import { Select } from "../Input";
import getMenus from "./utils/getMenus";
import handleAccessMenu from "./utils/handleAccessMenu";
import toggleMenu from "./utils/toggleMenu";
import returnApplications from "./utils/returnApplications";
import handleClickOutside from "./utils/handleClickOutside";
import onChangeApplication from "./utils/onChangeApplication";
import Header from "./UI/Header";
import MenuDropdown from "./UI/MenuDropdown";
import DefaultMenuItem from "./UI/DefaultMenuItem";
import SubMenusContainer from "./UI/SubMenusContainer";

class Sidebar extends React.Component {
  state = {
    sidebar_menus: [],
    user: {},
    applications: [],
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });

    await returnApplications(this);

    await getMenus(this);

    await handleAccessMenu(this);

    document.addEventListener(
      "click",
      (e) => handleClickOutside(this, e),
      true
    );
  };

  render() {
    const { location, show_sidebar } = this.props;

    let pathname = location && location.pathname ? location.pathname : "";

    return (
      <div
        id="sidebar dashboard-sidebar"
        className={`sidebar ${show_sidebar ? "show" : "hide"}`}
        ref="sidebar"
      >
        {/* header */}

        <Header />

        {/* End Header */}

        <div className="middle">
          {this.props.defaultApplication.sidebar && (
            <div className="applications">
              <Select
                options={this.state.applications}
                value={this.state.application}
                onChange={(e) => onChangeApplication(this, e)}
              />
            </div>
          )}
          <ul>
            {this.state.sidebar_menus &&
              this.state.sidebar_menus.map((menu, m) => {
                return (
                  <li key={m}>
                    {menu.title !== "" && menu.items ? (
                      <MenuDropdown
                        parentRef={this}
                        menu={menu}
                        index={m}
                        pathname={pathname}
                      />
                    ) : (
                      <DefaultMenuItem
                        menu={menu}
                        index={m}
                        pathname={pathname}
                      />
                    )}

                    <div className={`sub-menus ${menu.show ? "show" : ""}`}>
                      {menu.items &&
                        menu.items.map((item, i) => {
                          return (
                            <SubMenusContainer
                              item={item}
                              menuParent={menu}
                              index={i}
                              pathname={pathname}
                            />
                          );
                        })}
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { notifications, counts } = state.Notification;
  const { show_sidebar } = state.ToggleSidebar;
  const { defaultApplication } = state.Application;

  return { notifications, counts, show_sidebar, defaultApplication };
};

export default connect(mapStateToProps)(Sidebar);

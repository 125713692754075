import React from "react";
import { Home } from "./common";
import { TrafficStatus } from "../components/CheckedVehicles";
import { Tabs } from "../components/Tabs";

class TrafficStatusScreen extends React.Component {
  renderAll() {
    return <TrafficStatus />;
  }

  renderValid() {
    return <TrafficStatus valid />;
  }
  renderInvalid() {
    return <TrafficStatus invalid />;
  }
  render() {
    return (
      <div>
        <Home
          date
          location
          type
          level
          other={{
            thingType: "Traffic",
          }}
        />
        <div>
          <Tabs
            options={[
              {
                title: "Status",
                data: this.renderValid(),
              },
              // {
              //     title: 'Speed',
              //     data: this.renderInvalid(),
              // },
              // {
              //     title: 'All',
              //     data: this.renderAll(),
              // },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default TrafficStatusScreen;

import React from "react";
import VehicleRImage from "../../../assets/vehicleR.png";
import Slide from "react-reveal/Slide";

const VehicleRecognition = () => {
  return (
    <div className="section-container common-wrapper wrapper-bg-gray">
      <div className="container  d-lg-flex align-items-center justify-content-center ">
        <div className="col-md-6 flex-fill ml-5">
          <div className="description-section">
            <div>
              <Slide bottom>
                <h1>
                  Traffic <br />
                  Monitoring & <br />
                  <span className="primaryText">Car-Recognition.</span>
                </h1>
                <p>
                  Our Traffic Monitoring & Car-Recognition system offers
                  <br />
                  comprehensive insights into traffic patterns and enables
                  route-
                  <br />
                  specific analysis. With advanced car and number plate
                  recognition
                  <br /> capabilities, we facilitate zone monitoring and ensure
                  enhanced
                  <br /> security.
                </p>
              </Slide>
            </div>
          </div>
        </div>
        <div className="col-md-6 flex-fill">
          <div className="common-section common-img">
            <img src={VehicleRImage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleRecognition;
